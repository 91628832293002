import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from '../../../../../../src/Images/logo.png';
import moment from "moment";
import { baseURL } from "../../../../../global/api";

export const requestAddEmployee = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE",
    };
};
export const successAddEmployee = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE",
        payload: data,
    };
};
export const errorAddEmployee = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE",
    };
};

export const addEmployee = (data, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", get(data, 'uploadImage'));
    formData.append("uploadDocument", get(data, 'uploadDocument'));
    // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));

    formData.append("appointment_type", `${data.appointment_type}`);
    formData.append("name", `${data.name}`);
    formData.append("father_name", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joining_date", `${data.joining_date}`);
    formData.append("emergency_contact", `${data.emergency_contact}`);
    formData.append("marital_status", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    // formData.append("joining_date", `${data.joining_date}`);
    formData.append("religion", `${data.religion}`);
    formData.append("report_to", `${data.report_to}`);

    formData.append("address", `${data.address}`);
    formData.append("allocation", `${data.allocation}`);

    formData.append("employee_type", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("social_security", `${data.social_security}`);
    formData.append("blood_group", `${data.blood_group}`);

    formData.append("department_id", `${data.department_id}`);
    formData.append("designation_id", `${data.designation_id}`);

    formData.append("probation_period", `${data.probation_period}`);
    formData.append("probation_period_end_date", `${data.probation_period_end_date}`);




    forEach(data.uploadCnicImage, (x, index) => {
        formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    })
    // forEach(data.uploadDocument, (x, index) => {
    //     formData.append("uploadDocument", data.uploadDocument[index]);
    // })

    return (dispatch) => {
        dispatch(requestAddEmployee());
        API.post(`/employee/add_employee_data`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddEmployee(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddEmployee());
            console.log(error.response.data, "error.response.data")
            console.log(error.response.message, "error.response.message")
            console.log(error.message, "error.message")

            handleDangerVisible(error.response.data);
        })
    };
};

export const setCasualLeaves = (data) => {
    return {
        type: "SET_CASUAL_LEAVE",
        payload: data
    };
};

export const setSickLeaves = (data) => {
    return {
        type: "SET_SICK_LEAVE",
        payload: data
    };
};

export const setOtherLeaves = (data) => {
    return {
        type: "SET_OTHER_LEAVE",
        payload: data
    };
};
export const setAnnualLeaves = (data) => {
    return {
        type: "SET_ANNUAL_LEAVE",
        payload: data
    };
};
export const setPaternityLeaves = (data) => {
    return {
        type: "SET_PATERNITY_LEAVE",
        payload: data
    };
};
export const setShortLeaves = (data) => {
    return {
        type: "SET_SHORT_LEAVE",
        payload: data
    };
};
export const setSpecialLeaves = (data) => {
    return {
        type: "SET_SPECIAL_LEAVE",
        payload: data
    };
};
export const setMaternityLeaves = (data) => {
    return {
        type: "SET_MATERNITY_LEAVE",
        payload: data
    };
};

//Employee-Get
export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
};

export const getEmployeeData = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee/get_all_data`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};


//Employee-Get
export const requestGetActiveEmployee = () => {
    return {
        type: "REQUEST_GET_ACTIVE_EMPLOYEE",
    };
};
export const successGetActiveEmployee = (data) => {
    return {
        type: "SUCCESS_GET_ACTIVE_EMPLOYEE",
        payload: data,
    };
};
export const errorGetActiveEmployee = () => {
    return {
        type: "ERROR_GET_ACTIVE_EMPLOYEE",
    };
};

export const getEmployeeDataWithStatus = (status) => {
    return (dispatch) => {
        dispatch(requestGetActiveEmployee());
        API.get(`/employee/get_active_employee/${status}`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetActiveEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetActiveEmployee());
        })
    };
};

//EmployeeType-Add
export const requestAddEmployeeType = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE_TYPE",
    };
};
export const successAddEmployeeType = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorAddEmployeeType = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE_TYPE",
    };
};
export const addHREmployeeType = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddEmployeeType());
        API.post(`/employee/add_employee_type`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getHREmployeeType());
            dispatch(successAddEmployeeType(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddEmployeeType());
        })
    };
};


//EmployeeType-Get
export const requestGetEmployeeType = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE_TYPE",
    };
};
export const successGetEmployeeType = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorGetEmployeeType = () => {
    return {
        type: "ERROR_GET_EMPLOYEE_TYPE",
    };
};
export const getHREmployeeType = () => {
    return (dispatch) => {
        dispatch(requestGetEmployeeType());
        API.get(`/employee/get_employee_type`).then((res) => {
            let getData = res.data;
            console.log('getData', getData)
            dispatch(successGetEmployeeType(getData));
        }).catch((error) => {
            dispatch(errorGetEmployeeType());
        })
    };
};


//EmployeeType-Update
export const requestUpdateEmployeeType = () => {
    return {
        type: "REQUEST_UPDATE_EMPLOYEE_TYPE",
    };
};
export const successUpdateEmployeeType = (data) => {
    return {
        type: "SUCCESS_UPDATE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorUpdateEmployeeType = () => {
    return {
        type: "ERROR_UPDATE_EMPLOYEE_TYPE",
    };
};
export const UpdateHREmployeeType = (newData, oldData) => {
    console.log('newData', newData);
    // let division_id=newData.division_id;
    return (dispatch) => {
        dispatch(requestUpdateEmployeeType());
        // API.get(`/division/add_division/:${division_id}`, newData).then((res) => {
        API.put(`/employee/update_employee_type/${oldData.employee_type_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateEmployeeType([newData]));
            dispatch(getHREmployeeType());
        }).catch((error) => {
            dispatch(errorUpdateEmployeeType());
        })
    };
};


//EmployeeType-Delete
export const requestDeleteEmployeeType = () => {
    return {
        type: "REQUEST_DELETE_EMPLOYEE_TYPE",
    };
};
export const successDeleteEmployeeType = (data) => {
    return {
        type: "SUCCESS_DELETE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorDeleteEmployeeType = () => {
    return {
        type: "ERROR_DELETE_EMPLOYEE_TYPE",
    };
};
export const deleteHREmployeeType = (employee_type_id) => {
    return (dispatch) => {
        dispatch(requestDeleteEmployeeType());
        API.delete(`/employee/delete_employee_type/${employee_type_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteEmployeeType());
            dispatch(getHREmployeeType());
        }).catch((error) => {
            dispatch(errorDeleteEmployeeType());

        })
    };
};

//Division-Add
export const requestAddDivision = () => {
    return {
        type: "REQUEST_ADD_DIVISION",
    };
};
export const successAddDivision = (data) => {
    return {
        type: "SUCCESS_ADD_DIVISION",
        payload: data,
    };
};
export const errorAddDivision = () => {
    return {
        type: "ERROR_ADD_DIVISION",
    };
};
export const addHRDivision = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDivision());
        API.post(`/division/add_division`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getHRDivision());
            dispatch(successAddDivision(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddDivision());
        })
    };
};


//Division-Get
export const requestGetDivision = () => {
    return {
        type: "REQUEST_GET_DIVISION",
    };
};
export const successGetDivision = (data) => {
    return {
        type: "SUCCESS_GET_DIVISION",
        payload: data,
    };
};
export const errorGetDivision = () => {
    return {
        type: "ERROR_GET_DIVISION",
    };
};
export const getHRDivision = () => {
    return (dispatch) => {
        dispatch(requestGetDivision());
        API.get(`/division/get_division`).then((res) => {
            let getData = res.data;
            dispatch(successGetDivision(getData));
        }).catch((error) => {
            dispatch(errorGetDivision());
        })
    };
};

//Division-Update
export const requestUpdateDivision = () => {
    return {
        type: "REQUEST_UPDATE_DIVISION",
    };
};
export const successUpdateDivision = (data) => {
    return {
        type: "SUCCESS_UPDATE_DIVISION",
        payload: data,
    };
};
export const errorUpdateDivision = () => {
    return {
        type: "ERROR_UPDATE_DIVISION",
    };
};

export const UpdateHRDivision = (newData, oldData) => {
    console.log('newData', newData);
    // let division_id=newData.division_id;
    return (dispatch) => {
        dispatch(requestUpdateDivision());
        // API.get(`/division/add_division/:${division_id}`, newData).then((res) => {
        API.put(`/division/update_division/${oldData.division_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateDivision([newData]));
            dispatch(getHRDivision());
        }).catch((error) => {
            dispatch(errorUpdateDivision());
        })
    };
};

//Division-Delete
export const requestDeleteDivision = () => {
    return {
        type: "REQUEST_DELETE_DIVISION",
    };
};
export const successDeleteDivision = (data) => {
    return {
        type: "SUCCESS_DELETE_DIVISION",
        payload: data,
    };
};
export const errorDeleteDivision = () => {
    return {
        type: "ERROR_DELETE_DIVISION",
    };
};
export const deleteHRDivision = (division_id) => {
    return (dispatch) => {
        dispatch(requestDeleteDivision());
        API.delete(`/division/delete_division/${division_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteDivision());
            dispatch(getHRDivision());
        }).catch((error) => {
            dispatch(errorDeleteDivision());

        })
    };
};

//Department-Add
export const requestAddDepartment = () => {
    return {
        type: "REQUEST_ADD_DEPARTMENT",
    };
};
export const successAddDepartment = (data) => {
    return {
        type: "SUCCESS_ADD_DEPARTMENT",
        payload: data,
    };
};
export const errorAddDepartment = () => {
    return {
        type: "ERROR_ADD_DEPARTMENT",
    };
};

export const addHRDepartment = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDepartment());
        API.post(`/department/department-add-data`, data).then((res) => {
            let getData = res.data;
            dispatch(GetHRDepartment());
            dispatch(successAddDepartment(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddDepartment());
            handleDangerVisible()
        })
    };
};


//Department-Update
export const requestUpdateDepartment = () => {
    return {
        type: "REQUEST_UPDATE_DEPARTMENT",
    };
};
export const successUpdateDepartment = (data) => {
    return {
        type: "SUCCESS_UPDATE_DEPARTMENT",
        payload: data,
    };
};
export const errorUpdateDepartment = () => {
    return {
        type: "ERROR_UPDATE_DEPARTMENT",
    };
};

export const updateHRDepartment = (newData, oldData) => {
    console.log('oldData.department_id', oldData.departmentId);

    return (dispatch) => {
        dispatch(requestUpdateDepartment());

        API.put(`/department/update_department/${oldData.departmentId}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateDepartment([newData]));
            dispatch(GetHRDepartment());
        }).catch((error) => {
            dispatch(errorUpdateDepartment());
        })
    };
};
//Department-delete
export const requestDeleteDepartment = () => {
    return {
        type: "REQUEST_DELETE_DEPARTMENT",
    };
};
export const successDeleteDepartment = (data) => {
    return {
        type: "SUCCESS_DELETE_DEPARTMENT",
        payload: data,
    };
};
export const errorDeleteDepartment = () => {
    return {
        type: "ERROR_DELETE_DEPARTMENT",
    };
};

export const deleteHRDepartment = (departmentId) => {
    // console.log('newData', newData);
    // let division_id=newData.division_id;
    return (dispatch) => {
        dispatch(requestDeleteDepartment());
        // API.get(`/division/add_division/:${division_id}`, newData).then((res) => {
        API.delete(`/department/delete_department/${departmentId}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteDepartment());
            dispatch(GetHRDepartment());
        }).catch((error) => {
            dispatch(errorDeleteDepartment());
        })
    };
};

//Department-Get
export const requestGetDepartment = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT",
    };
};
export const successGetDepartment = (data) => {
    return {
        type: "SUCCESS_GET_DEPARTMENT",
        payload: data,
    };
};
export const errorGetDepartment = () => {
    return {
        type: "ERROR_GET_DEPARTMENT",
    };
};

export const GetHRDepartment = () => {
    return (dispatch) => {
        dispatch(requestGetDepartment());
        API.get(`/department/get-department-data`).then((res) => {
            let getData = res.data;
            dispatch(successGetDepartment(getData));
        }).catch((error) => {
            dispatch(errorGetDepartment());
        })
    };
};
//Department-Get
export const requestGetDepartmentByDivisionId = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT_BY_DIVISIONID",
    };
};
export const successGetDepartmentByDivisionId = (data) => {
    return {
        type: "SUCCESS_GET_DEPARTMENT_BY_DIVISIONID",
        payload: data,
    };
};
export const errorGetDepartmentByDivisionId = () => {
    return {
        type: "ERROR_GET_DEPARTMENT_BY_DIVISIONID",
    };
};

export const GetHRDepartmentByDivisionId = (divisionId) => {
    return (dispatch) => {
        dispatch(requestGetDepartmentByDivisionId());
        API.get(`/department/get_department_by_divisionId/${divisionId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetDepartmentByDivisionId(getData));
        }).catch((error) => {
            dispatch(errorGetDepartmentByDivisionId());
        })
    };
};
//Designation
export const requestAddDesignation = () => {
    return {
        type: "REQUEST_ADD_DESIGNATION",
    };
};
export const successAddDesignation = (data) => {
    return {
        type: "SUCCESS_ADD_DESIGNATION",
        payload: data,
    };
};
export const errorAddDesignation = () => {
    return {
        type: "ERROR_ADD_DESIGNATION",
    };
};

export const addHRDesignation = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDesignation());
        API.post(`/designation/add_designation`, data).then((res) => {
            let getDesignationData = res.data;
            dispatch(GetHRDesignation());
            dispatch(successAddDesignation(getDesignationData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddDesignation());
            handleDangerVisible();
        })
    };
};

export const requestDeleteDesignation = () => {
    return {
        type: "REQUEST_DELETE_DESIGNATION",
    };
};
export const successDeleteDesignation = (designationId) => {
    return {
        type: "SUCCESS_DELETE_DESIGNATION",
        designation_id: designationId,
    };
};
export const errorDeleteDesignation = () => {
    return {
        type: "ERROR_DELETE_DESIGNATION",
    };
};

export const deleteDesignation = (designation_id) => {
    return dispatch => {
        dispatch(requestDeleteDesignation());
        API.delete(`/designation/delete_designation/${designation_id}`)
            .then(res => {
                dispatch(GetHRDesignation());
                dispatch(successDeleteDesignation(designation_id))
            }).catch((error) =>
                dispatch(errorDeleteDesignation()));
    }
};
//Get-Image

export const requestGetImageForAdress = () => {
    return {
        type: "REQUEST_GET_IMAGE_HR",
    };
};
export const successGetImageForAdress = (data) => {
    return {
        type: "SUCCESS_GET_IMAGE_HR",
        payload: data,
    };
};

export const errorGetImageForAdress = () => {
    return {
        type: "ERROR_GET_IMAGE_HR",
    };
};

export const GetImageForAdress = (address) => {
    return (dispatch) => {
        dispatch(requestGetImageForAdress());
        API.get(`/employee/get_image_data_by_path/${address}`).then((res) => {
            let getLeaveData = res.data;
            console.log('getLeaveData', getLeaveData)
            dispatch(successGetImageForAdress(getLeaveData));
        }).catch((error) => {
            isNull(address) ? dispatch(successGetImageForAdress("")) : dispatch(errorGetImageForAdress());
        })
    };

}
export const UpdateRequestHR = () => {
    return {
        type: "UPDATE_REQUEST_HR",
    };
};
export const UpdateSuccessHR = (data) => {
    return {
        type: "UPDATE_SUCCESS_HR",
        payload: data,
    };
};

export const UpdateErrorHR = () => {
    return {
        type: "UPDATE_ERROR_HR",
    };
};

export const HRUpdateEmployee = (data, oldData, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", get(data, 'uploadImage'));
    formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
    formData.append("uploadDocument", get(data, 'uploadDocument'));

    formData.append("appointment_type", `${data.appointment_type}`);
    formData.append("name", `${data.name}`);
    formData.append("father_name", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joining_date", `${data.joining_date}`);
    formData.append("emergency_contact", `${data.emergency_contact}`);
    formData.append("marital_status", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    formData.append("uploadImage", `${data.uploadImage}`);
    formData.append("address", `${data.address}`);

    formData.append("religion", `${data.religion}`);
    // formData.append("machine_id", `${data.machine_id}`);
    formData.append("report_to", `${data.report_to}`);

    formData.append("allocation", `${data.allocation}`);

    formData.append("active_status", `${data.active_status}`);
    formData.append("employee_type", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("social_security", `${data.social_security}`);
    formData.append("blood_group", `${data.blood_group}`);

    formData.append("department_id", `${data.department_id}`);
    formData.append("designation_id", `${data.designation_id}`);
    formData.append("probation_period", `${data.probation_period}`);
    formData.append("probation_period_end_date", `${data.probation_period_end_date}`);


    // forEach(data.uploadCnicImage, (x, index) => {
    //     formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    //   })
    //   forEach(data.uploadDocument, (x, index) => {
    //     formData.append("uploadDocument", data.uploadDocument[index]);
    //   })

    // formData.append("department", `${data.department}`);
    // formData.append("designation", `${data.designation}`);
    // formData.append("probation_period", `${data.probation_period}`);
    // formData.append("probation_period_end_date", `${data.probation_period_end_date}`);


    return (dispatch) => {
        dispatch(UpdateRequestHR());
        API.put(`/employee/up_employee/${oldData.employeeId}`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(getEmployeeData())
            dispatch(UpdateSuccessHR(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(UpdateSuccessHR());
            handleDangerVisible();
        })
    };
};

// export const HRUpdateEmployee= (data, oldData) => {
//     console.log(oldData, "olddddd");
//     const formData = new FormData();
//     formData.append("uploadImage", get(data, 'uploadImage'));
//     formData.append("Name", `${data.Name}`);
//     formData.append("FatherName", `${data.FatherName}`);
//     formData.append("Dob", `${data.Dob}`);
//     formData.append("Gender", `${data.Gender}`);
//     formData.append("Phone", `${data.Phone}`);
//     formData.append("Address", `${data.Address}`);
//     formData.append("cnic", `${data.cnic}`);
//     formData.append("Salary", `${data.Salary}`);
//     formData.append("joiningDate", `${data.joiningDate}`);
//     formData.append("bankAccount", `${data.bankAccount}`);
//     formData.append("basicSalary", `${data.basicSalary}`);
//     formData.append("medicalAllowance", `${data.medicalAllowance}`);
//     formData.append("specialAllowance", `${data.specialAllowance}`);
//     forEach(data.uploadCnicImage, (x, index) => {
//         formData.append("uploadCnicImage", data.uploadCnicImage[index]);
//       })
//     // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
//     formData.append("uploadDocument", get(data, 'uploadDocument'));
//     formData.append("arrivalTime", `${data.arrivalTime}`);
//     formData.append("breakIn", `${data.breakIn}`);
//     formData.append("breakOut", `${data.breakOut}`);
//     formData.append("holiday", `${data.holiday}`);
//     formData.append("department_id", `${data.department_id}`);
//     return (dispatch) => {
//         dispatch(UpdateRequestHR());
//         API.put(`/employee/update_employee/${oldData.employeeId}`, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         }).then((res) => {
//             let getData = res.data;
//             dispatch(getEmployeeData())
//             dispatch(UpdateSuccessHR(getData));
//         }).catch((error) => {
//             dispatch(UpdateSuccessHR());
//         })
//     };
// };


export const requestDeleteHR = () => {
    return {
        type: "REQUEST_DELETE_HR",
    };
};
export const successDeleteHR = (employeeId) => {
    return {
        type: "SUCCESS_DELETE_HR",
        employeeId: employeeId,
    };
};
export const errorDeleteHR = () => {
    return {
        type: "ERROR_DELETE_HR",
    };
};

export const deleteHR = (employeeId) => {
    return dispatch => {
        dispatch(requestDeleteHR());
        API.delete(`/employee/delete_data/${employeeId}`)
            .then(res => {
                dispatch(getEmployeeData());
                dispatch(successDeleteHR(employeeId))
            }).catch((error) =>
                dispatch(errorDeleteHR()));
    }
};

//HR Policy
export const requestGetHRPolicy = () => {
    return {
        type: "REQUEST_GET_HR_POLICY",
    };
};
export const successGetHRPolicy = (data) => {
    return {
        type: "SUCCESS_GET_HR_POLICY",
        payload: data,
    };
};
export const errorGetHRPolicy = () => {
    return {
        type: "ERROR_GET_HR_POLICY",
    };
};

export const GetHRPolicy = () => {
    return (dispatch) => {
        dispatch(requestGetHRPolicy());
        API.get(`/policy/get_policy`).then((res) => {
            let getLeaveData = res.data;
            console.log('getLeaveData', getLeaveData)
            dispatch(successGetHRPolicy(getLeaveData));
        }).catch((error) => {
            dispatch(errorGetHRPolicy());
        })
    };
};

export const requestAddLeave = () => {
    return {
        type: "REQUEST_ADD_LEAVE",
    };
};
export const successAddLeave = (data) => {
    return {
        type: "SUCCESS_ADD_LEAVE",
        payload: data,
    };
};
export const errorAddLeave = () => {
    return {
        type: "ERROR_ADD_LEAVE",
    };
};

export const addHRLeave = (data) => {
    return (dispatch) => {
        dispatch(requestAddLeave());
        API.put(`/policy/update_policy`, data).then((res) => {
            let getLeaveData = res.data;
            dispatch(GetHRPolicy());
            dispatch(successAddLeave(getLeaveData));
        }).catch((error) => {
            dispatch(errorAddLeave());
        })
    };
};



export const requestAddSalary = () => {
    return {
        type: "REQUEST_ADD_SALARY",
    };
};
export const successAddSalary = (data) => {
    return {
        type: "SUCCESS_ADD_SALARY",
        payload: data,
    };
};
export const errorAddSalary = () => {
    return {
        type: "ERROR_ADD_SALARY",
    };
};

export const addSalary = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSalary());
        console.log(data, "data")
        API.post(`/salary/add_salary`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddSalary(data));
                handleVisible();
            })
            .catch((error) => {
                console.clear();
                console.log(error, "Salary Error")
                console.dir(error, "Salary Error")
                dispatch(errorAddSalary());
                handleDangerVisible();
            });
    };
};


export const requestGetSalaryById = () => {
    return {
        type: "REQUEST_GET_SALARY_BY_ID",
    };
};
export const successGetSalaryById = (data) => {
    console.log('SUCCESS_GET_SALARY_BY_ID', data)
    return {
        type: "SUCCESS_GET_SALARY_BY_ID",
        payload: data,
    };
};
export const errorGetSalaryById = () => {
    return {
        type: "ERROR_GET_SALARY_BY_ID",
    };
};

export const getSalaryById = (employeeId) => {
    return (dispatch) => {
        dispatch(requestGetSalaryById());
        API.get(`/salary/get_salary/${employeeId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetSalaryById(getData));
        }).catch((error) => {
            dispatch(errorGetSalaryById());
        })
    };
}


export const requestUpdateSalary = () => {
    return {
        type: "REQUEST_UPDATE_SALARY",
    };
};
export const successUpdateSalary = (data) => {
    return {
        type: "SUCCESS_UPDATE_SALARY",
        payload: data,
    };
};
export const errorUpdateSalary = () => {
    return {
        type: "ERROR_UPDATE_SALARY",
    };
};

export const updateSalary = (data, id, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestUpdateSalary());
        API.put(`/salary/update_salary/${id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successUpdateSalary(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorUpdateSalary());
                handleDangerVisible();
            });
    };
};
export const setSalaryFields = (data = { key: "", value: "" }) => {
    return {
        type: "SET_SALARY_FIELDS",
        payload: data
    };
}
export const setEditEmployeeFields = (data) => {
    console.log('data', data)
    return {
        type: "SET_EDIT_EMPLOYEE_FIELDS",
        payload: data
    };
}
export const requestGetDesignation = () => {
    return {
        type: "REQUEST_GET_DESIGNATION",
    };
};
export const successGetDesignation = (data) => {
    return {
        type: "SUCCESS_GET_DESIGNATION",
        payload: data,
    };
};
export const errorGetDesignation = () => {
    return {
        type: "ERROR_GET_DESIGNATION",
    };
};

export const GetHRDesignation = () => {
    return (dispatch) => {
        dispatch(requestGetDesignation());
        API.get(`/designation/get_designation`).then((res) => {
            let getData = res.data;
            dispatch(successGetDesignation(getData));
        }).catch((error) => {
            dispatch(errorGetDesignation());
        })
    };
};


export const requestBackupDatabase = () => {
    return {
        type: "REQUEST_BACKUP_DATABASE",
    };
};
export const succesBackupDatabase = (payload) => {
    return {
        type: "SUCCESS_BACKUP_DATABASE",
        data: payload
    };
};
export const errorBackupDatabase = () => {
    return {
        type: "ERROR_BACKUP_DATABASE",
    };
};

export const BackupDatabase = () => {
    return dispatch => {
        dispatch(requestBackupDatabase());
        API.get(`/backup/get_backup`)
            .then(res => {
                dispatch(succesBackupDatabase(res.data))
            }).catch((error) =>
                dispatch(errorBackupDatabase()));
    }
};


export const requestGetEmployeeById = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE_BY_ID",
    };
};
export const successGetEmployeeById = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE_BY_ID",
        payload: data,
    };
};
export const errorGetEmployeeById = () => {
    return {
        type: "ERROR_GET_EMPLOYEE_BY_ID",
    };
};

export const getEmployeeDataById = (id, setOldPfp) => {
    return (dispatch) => {
        dispatch(requestGetEmployeeById());
        API.get(`/employee/get_data_by_id/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployeeById(getData));
            setOldPfp();
            console.log(`${baseURL}${getData[0].uploadImage}`, "pfp URL");
        }).catch((error) => {
            dispatch(errorGetEmployeeById());
        })
    };
};



export const requestGenerateSalary = () => {
    return {
        type: "REQUEST_GENERATE_SALARY",
    };
};
export const successGenerateSalary = (data) => {
    return {
        type: "SUCCESS_GENERATE_SALARY",
        payload: data,
    };
};
export const errorGenerateSalary = () => {
    return {
        type: "ERROR_GENERATE_SALARY",
    };
};

export const generateSalary = (pData, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestGenerateSalary());
        API.post(`/salary/genrate_salary_with_employee_id`, pData).then((res) => {
            let getData = res.data.result;
            let getAttendanceData = res.data.attendance_logs
            console.log(getData, 'indsal')
            console.log(getAttendanceData, 'attdata')
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 50 - 30;
            const marginLeft1 = 150;
            const marginLeft2 = 190 - 40;

            const marginLeft3 = 340;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(10);

            const title = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyName = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const empCode = `Employee Code : ${getData.employee_id}`;
            const empName = `Employee Name : ${getData.employee_name}`;
            const fatherName = `Father Name : ${getData.father_name}`;
            const dept = `Department : `;
            const desig = `Designation : ${getData.designation}`;
            const pic = `P\nH\nO\nT\nO`;
            const daily_report = `Employee Salary`;
            const from = `From : ${pData.start_date}`;
            const to = `To : ${pData.end_date}`;
            const basicSalary = `Basic Salary : ${getData.basic_salary}`;
            const totalAllowance = `Total Allowances : ${getData.all_allowance}`;
            const medicalAllowance = `MA : ${getData.medicial_allowance}`;
            const accomodationAllowance = `AA : ${getData.accomodation_allowance}`;
            const petrolAllowance = `PA : ${getData.petrol_allowance}`;
            const otherAllowance = `OA : ${getData.other_allowance}`;
            const specialAllowance = `SA : ${getData.special_allowance}`;
            const lateTimeDeduction = `Late Deduction : ${getData.lateTime_amount}`
            const overTimeBonus = `Over Time : ${getData.overtime_amount}`;
            const grossSalary = `Gross Salary : ${getData.gross_salary}`;
            const netPayableSalary = `Payable Salary : ${getData.net_payable_salary}`;

            const columns = [['Date', 'In Time', 'Break Out', 'Break In', 'Out Time', 'Late Min', 'Over Time']];

            const data = getAttendanceData.map((x) => [moment(x.attendance_date).format('LL'), x.attendance_in_time, x.break_out_time, x.break_in_time, x.attendance_out_time]);
            console.log(data, 'getAttendanceData.map((x)=>[moment(x.attendance_date).format("LL")')

            let content = {
                startY: 325,
                startX: 5,
                margin: { left: 5, right: 5 },
                theme: 'grid',
                cellWidth: 'wrap',
                head: columns,
                body: data,
                headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
                bodyStyles: { valign: 'middle', halign: 'center', fontSize: 10, rowPageBreak: 'avoid' },
                didDrawCell: function (data) {
                    let rows = data.table.body[0].cells[0];
                    console.log(rows, 'row')
                    // rows.push({ 
                    //   colSpan: 2
                    // });
                    // if (data.row.index === rows.length - 1) { doc.setFillColor(239, 154, 154); }
                }
            };

            doc.addImage(logo, 'SVG', 40, 15, 100, 100);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(16.5);
            doc.setLineWidth(2);
            doc.line(400, 55, 150, 55);
            doc.text(title, marginLeft1, 50);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(10);
            doc.text(companyName, marginLeft1, 75);



            doc.setLineWidth(2);
            doc.rect(7, 125, 580, 190);
            doc.setFillColor(245, 245, 245);
            doc.rect(333, 143, 20, 80, 'F')
            doc.addImage(logo, 'SVG', 355, 140, 75, 75);

            doc.setLineWidth(1);
            doc.line(445, 165, 570, 165);
            doc.setFont(undefined, 'bold');
            doc.text(daily_report, 450, 160, 0, 20);


            doc.setFont(undefined, 'normal');
            doc.text(from, 450, 180, 0, 20);
            doc.text(to, 450, 195, 0, 20);
            doc.text(pic, marginLeft3, 160, 0, 20);
            doc.text(empCode, marginLeft, 150, 0, 20);
            doc.text(empName, marginLeft, 165, 0, 20);
            doc.text(fatherName, marginLeft, 180, 0, 20);
            doc.text(dept, marginLeft, 195, 0, 20);
            doc.text(desig, marginLeft, 210, 0, 20);

            doc.text(medicalAllowance, marginLeft, 225, 0, 20);
            doc.text(otherAllowance, marginLeft + 55, 225, 0, 20);
            doc.text(accomodationAllowance, marginLeft + 110, 225, 0, 20);
            doc.text(petrolAllowance, marginLeft + 165, 225, 0, 20);
            doc.text(specialAllowance, marginLeft + 220, 225, 0, 20);

            doc.text(totalAllowance, marginLeft, 240, 0, 20);
            doc.text(basicSalary, marginLeft + 120, 240, 0, 20);
            doc.text(grossSalary, marginLeft + 240, 240, 0, 20);
            doc.text(netPayableSalary, marginLeft + 360, 240, 0, 20);

            doc.text(lateTimeDeduction, marginLeft, 255, 0, 20);
            doc.text(overTimeBonus, marginLeft + 120, 255, 0, 20);


            doc.autoTable(content);
            doc.save(`Individual Salary (${getData.employee_name}).pdf`);


            handleVisible();
            dispatch(successGenerateSalary(getData));
        }).catch((error) => {
            dispatch(errorGenerateSalary());
            console.log({ error })
            handleDangerVisible();
        })
    };
};

//Shift Control
export const requestGetShiftControl = () => {
    return {
        type: "REQUEST_GET_SHIFT_CONTROL",
    };
};
export const successGetShiftControl = (data) => {
    return {
        type: "SUCCESS_GET_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorGetShiftControl = () => {
    return {
        type: "ERROR_GET_SHIFT_CONTROL",
    };
};

export const getShiftControl = () => {
    return (dispatch) => {
        dispatch(requestGetShiftControl());
        API.get(`/shift_control/get_shift_control`).then((res) => {
            let getData = res.data;
            dispatch(successGetShiftControl(getData));
        }).catch((error) => {
            dispatch(errorGetShiftControl());
        })
    };
};

export const requestAddShiftControl = () => {
    return {
        type: "REQUEST_ADD_SHIFT_CONTROL",
    };
};
export const successAddShiftControl = (data) => {
    return {
        type: "SUCCESS_ADD_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorAddShiftControl = () => {
    return {
        type: "ERROR_ADD_SHIFT_CONTROL",
    };
};

export const addShiftControl = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddShiftControl());
        API.post(`/shift_control/add_shift_control`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddShiftControl(data));
                handleVisible();
                dispatch(getShiftControl());
            })
            .catch((error) => {
                dispatch(errorAddShiftControl());
                handleDangerVisible();
            });
    };
};

export const requestDeleteShiftControl = () => {
    return {
        type: "REQUEST_DELETE_SHIFT_CONTROL",
    };
};
export const successDeleteShiftControl = (shiftId) => {
    return {
        type: "SUCCESS_DELETE_SHIFT_CONTROL",
        shift_control_id: shiftId,
    };
};
export const errorDeleteShiftControl = () => {
    return {
        type: "ERROR_DELETE_SHIFT CONTROL",
    };
};

export const deleteShiftControl = (shiftId) => {
    return dispatch => {
        dispatch(requestDeleteShiftControl());
        API.delete(`/shift_control/delete_shift_control/${shiftId}`)
            .then(res => {
                dispatch(getShiftControl());
                dispatch(successDeleteShiftControl(shiftId))
            }).catch((error) =>
                dispatch(errorDeleteShiftControl()));
    }
};

export const requestUpdateShiftControl = () => {
    return {
        type: "REQUEST_UPDATE_SHIFT_CONTROL",
    };
};
export const successUpdateShiftControl = (data) => {
    return {
        type: "SUCCESS_UPDATE_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorUpdateShiftControl = () => {
    return {
        type: "ERROR_UPDATE_SHIFT_CONTROL",
    };
};

export const updateShiftControl = (data, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateShiftControl());
        API.put(`/shift_control/update_shift_control/${oldData.shift_control_id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getShiftControl());
                dispatch(successUpdateShiftControl(data));
            })
            .catch((error) => {
                dispatch(errorUpdateShiftControl());
            });
    };
};

//Fine & Penalties
export const requestGetFine = () => {
    return {
        type: "REQUEST_GET_FINE",
    };
};
export const successGetFine = (data) => {
    return {
        type: "SUCCESS_GET_FINE",
        payload: data,
    };
};
export const errorGetFine = () => {
    return {
        type: "ERROR_GET_FINE",
    };
};


export const getFine = (limit, page, search) => {
    return (dispatch) => {
        dispatch(requestGetFine());

        API.get(`/fine_plenties/get_fine_and_plenties`).then((res) => {
            // API.get(`/fine_plenties/get_fine_and_plenties?size=${limit}&page=${page}&search=${search}`).then((res) => {
            let getData = res.data;
            dispatch(successGetFine(getData));
        }).catch((error) => {
            dispatch(errorGetFine());
        })
    };
};

export const requestAddFine = () => {
    return {
        type: "REQUEST_ADD_FINE",
    };
};
export const successAddFine = (data) => {
    return {
        type: "SUCCESS_ADD_FINE",
        payload: data,
    };
};
export const errorAddFine = () => {
    return {
        type: "ERROR_ADD_FINE",
    };
};

export const addFine = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddFine());
        API.post(`/fine_plenties/add_fine_and_plenties`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getFine());
                dispatch(successAddFine(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddFine());
                handleDangerVisible();
            });
    };
};

export const requestDeleteFine = () => {
    return {
        type: "REQUEST_DELETE_FINE",
    };
};
export const successDeleteFine = (fineId) => {
    return {
        type: "SUCCESS_DELETE_FINE",
        fine_and_plenties_id: fineId,
    };
};
export const errorDeleteFine = () => {
    return {
        type: "ERROR_DELETE_FINE",
    };
};

export const deleteFine = (fineId) => {
    return dispatch => {
        dispatch(requestDeleteFine());
        API.delete(`/fine_plenties/delete_fine_and_plenties/${fineId}`)
            .then(res => {
                dispatch(getFine());
                dispatch(successDeleteFine(fineId))
            }).catch((error) =>
                dispatch(errorDeleteFine()));
    }
};

export const requestUpdateFine = () => {
    return {
        type: "REQUEST_UPDATE_FINE",
    };
};
export const successUpdateFine = (data) => {
    return {
        type: "SUCCESS_UPDATE_FINE",
        payload: data,
    };
};
export const errorUpdateFine = () => {
    return {
        type: "ERROR_UPDATE_FINE",
    };
};

export const updateFine = (data, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateFine());
        API.put(`/fine_plenties/update_fine_and_plenties/${oldData.fine_and_plenties_id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getFine());
                dispatch(successUpdateFine(data));
            })
            .catch((error) => {
                dispatch(errorUpdateFine());
            });
    };
};
//Master Control
export const requestGetMasterAccounts = () => {
    return {
        type: "REQUEST_GET_MASTER_ACCOUNTS",
    };
};
export const successGetMasterAccounts = (data) => {
    return {
        type: "SUCCESS_GET_MASTER_ACCOUNTS",
        payload: data,
    };
};
export const errorGetMasterAccounts = () => {
    return {
        type: "ERROR_GET_MASTER_ACCOUNTS",
    };
};

export const getMasterAccounts = () => {
    return (dispatch) => {
        dispatch(requestGetMasterAccounts());
        API.get(`/levelFive/get-acc-ledger-user`).then((res) => {
            let getData = res.data;
            dispatch(successGetMasterAccounts(getData));
        }).catch((error) => {
            dispatch(errorGetMasterAccounts());
        })
    };
};


//Master Control
export const requestGetSelectedMasterAccounts = () => {
    return {
        type: "REQUEST_GET_MASTER_SELECTED_ACCOUNTS",
    };
};
export const successGetSelectedMasterAccounts = (data) => {
    return {
        type: "SUCCESS_GET_MASTER_SELECTED_ACCOUNTS",
        payload: data,
    };
};
export const errorGetSelectedMasterAccounts = () => {
    return {
        type: "ERROR_GET_MASTER_SELECTED_ACCOUNTS",
    };
};

export const getSelectedMasterAccounts = () => {
    return (dispatch) => {
        dispatch(requestGetSelectedMasterAccounts());
        API.get(`/master_control/get_master_control`).then((res) => {
            let getData = res.data;
            dispatch(successGetSelectedMasterAccounts(getData));
        }).catch((error) => {
            dispatch(errorGetSelectedMasterAccounts());
        })
    };
};

export const requestAddAccounts = () => {
    return {
        type: "REQUEST_ADD_MASTER_ACCOUNTS",
    };
};
export const successAddAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_MASTER_ACCOUNTS",
        payload: data,
    };
};
export const errorAddAccounts = () => {
    return {
        type: "ERROR_ADD_MASTER_ACCOUNTS",
    };
};

export const addAccounts = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddAccounts());
        API.put(`/master_control/update_master_control`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddAccounts(data));
                dispatch(resetMasterData());
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddAccounts());
                handleDangerVisible();
            });
    };
};
export const resetMasterData = () => {
    return {
        type: "RESET_MASTER_DATA",
    };
};

//LT Advance
export const requestGetLTAccount = () => {
    return {
        type: "REQUEST_GET_LT_ACCOUNT",
    };
};
export const successGetLTAccount = (data) => {
    return {
        type: "SUCCESS_GET_LT_ACCOUNT",
        payload: data,
    };
};
export const errorGetLTAccount = () => {
    return {
        type: "ERROR_GET_LT_ACCOUNT",
    };
};

export const getLTAccount = () => {
    return (dispatch) => {
        dispatch(requestGetLTAccount());
        API.get(`/master_control/get_master_control`).then((res) => {
            let getData = res.data;
            dispatch(successGetLTAccount(getData));
        }).catch((error) => {
            dispatch(errorGetLTAccount());
        })
    };
};
export const requestAddLTAccounts = () => {
    return {
        type: "REQUEST_ADD_LT_ACCOUNT",
    };
};
export const successAddLTAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_LT_ACCOUNT",
        payload: data,
    };
};
export const errorAddLTAccounts = () => {
    return {
        type: "ERROR_ADD_LT_ACCOUNT",
    };
};

export const addLTAccount = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLTAccounts());
        API.post(`/long_term_adv/add_long_term_advance`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddLTAccounts(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddLTAccounts());
                handleDangerVisible();
            });
    };
};
//ST ADVANCE
export const requestAddSTAccounts = () => {
    return {
        type: "REQUEST_ADD_ST_ACCOUNT",
    };
};
export const successAddSTAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_ST_ACCOUNT",
        payload: data,
    };
};
export const errorAddSTAccounts = () => {
    return {
        type: "ERROR_ADD_ST_ACCOUNT",
    };
};

export const addSTAccount = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSTAccounts());
        API.post(`/short_term_adv/add_short_term_advance`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddSTAccounts(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddSTAccounts());
                handleDangerVisible();
            });
    };
};
//Lt reciving
export const requestAddLTRecivings = () => {
    return {
        type: "REQUEST_ADD_LT_RECIVING",
    };
};
export const successAddLTRecivings = (data) => {
    return {
        type: "SUCCESS_ADD_LT_RECIVING",
        payload: data,
    };
};
export const errorAddLTRecivings = () => {
    return {
        type: "ERROR_ADD_LT_RECIVING",
    };
};

export const addLTReciving = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLTRecivings());
        API.post(`/long_term_adv/add_long_term_reciving`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddLTRecivings(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddLTRecivings());
                handleDangerVisible();
            });
    };
};
//st recivig
export const requestAddSTRecivings = () => {
    return {
        type: "REQUEST_ADD_ST_RECIVING",
    };
};
export const successAddSTRecivings = (data) => {
    return {
        type: "SUCCESS_ADD_ST_RECIVING",
        payload: data,
    };
};
export const errorAddSTRecivings = () => {
    return {
        type: "ERROR_ADD_ST_RECIVING",
    };
};

export const addSTReciving = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSTRecivings());
        API.post(`/short_term_adv/add_short_term_reciving`, data)
            .then((res) => {
                //let getData = res.data;term_reciving
                dispatch(successAddSTRecivings(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddSTRecivings());
                handleDangerVisible();
            });
    };
};
// Get LT Ledger
export const requestGetInfoLTLedger = () => {
    return {
        type: "REQUEST_GET_INFO_LT_LEDGER",
    };
};
export const successGetInfoLTLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_LT_LEDGER",
        payload: data,
    };
};
export const errorGetInfoLTLedger = () => {
    return {
        type: "ERROR_GET_INFO_LT_LEDGER",
    };
};

export const getInfoLTLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoLTLedger());

        API.post(`/long_term_adv/get_lt_logs`, data).then((res) => {

            console.group("LT Ledger get_lt_logs")
            console.clear();
            console.log(res);
            console.dir(res, "ltledger");
            console.groupEnd("LT Ledger get_lt_logs");

            let getData = res.data;

            dispatch(successGetInfoLTLedger(getData));
            console.log(getData, ' getData ltledger');
            console.log(getData, 'data11');

            // console.log(getData[1].voucher_date, "d1")
            // console.log(getData[1].voucher_manual_number, "d1")
            // console.log(getData[1].voucher_description, "d1")

            const unit = 'pt';
            const size = 'A4'; // Use A1, A2, A3 or A4
            const orientation = 'portrait'; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = 'LT Advance Ledger';
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
            const Dates =
                !isEmpty(data.startedDate) || !isEmpty(data.endDate)
                    ? 'Form: ' + ' ' + data.startedDate + '  to:  ' + data.endDate
                    : 'Form: -  To: - ';
            // const TotalCredit = 'Total Credit: ' + getData.credit_sum;
            // const TotalDebit = 'Total Debit: ' + getData.debit_sum;

            const headers = [
                [
                    'DATE',
                    'ACCOUNT',
                    'TYPE',
                    'NARRATION',
                    'DEBIT',
                    'CREDIT',
                    'BALANCE',
                ],
            ];
            const tabledata = getData.data.map((x) => [

                x.long_term_ledger_date,
                x.cross_account_name,
                x.acc_type,
                x.long_term_description,
                x.debit_value,
                x.credit_value,
                x.closing_balance,

                // x.acc_name,
                // x.accBalance,
                // x.long_term_deduction_value,
                // // ,x.accBalance,Number(x.accType) === 1 ? 'CR' : 'DR'
            ]);
            tabledata.unshift([
                null,
                null,
                null,
                `Opening Balance on ${data.startedDate}`,
                null,
                null,
                getData.data[0].closing_balance,
              ]);
              const arr = [];
              console.log(arr.length);
              tabledata.push([
                null,
                null,
                null,
                `Closing Balance on ${data.endDate}`,
                null,
                null,
                getData.data[getData.data.length - 1].closing_balance,
              ]);
              tabledata.push([
                null,
                null,
                null,
                null,
                sumBy(getData.data, 'debit_value') === 0
                  ? null
                  : sumBy(getData.data, 'debit_value'),
                sumBy(getData.data, 'credit_value') === 0
                  ? null
                  : sumBy(getData.data, 'credit_value'),
                getData.data[getData.data.length - 1].closing_balance,
              ]);
          
            console.log({tabledata});

            console.log(getData, 'abcd');
            let content = {
                startY: 240,
                startX: 10,
                // styles: { cellWidth: 73 },
                head: headers,
                body: tabledata,
                
            };

            // doc.text(companyName, 200, 60, 0, 20);
            doc.addImage(logo, 'PNG', 110, 1, 130, 130);
            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(companyName, 220, 60, 0, 20);
            doc.setFontSize(13);
            doc.setFont('TimesNewRoman', 'Normal');
            doc.text(companyAddress, 220, 80, 0, 20);
            doc.text(companyNtn, 220, 100, 0, 20);
            doc.setFontSize(14);

            doc.text(Dates, marginLeft, 160, 0, 0);
            //  doc.text(accountNo, marginLeft, 140, 0, 20);
            //  doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.text(title, marginLeft, 140, 0, 0);
            doc.text(`Employee: ${getData.data[0].employee_id}-${getData.data[0].acc_name}`, marginLeft, 180);
            doc.text(`Department: ${getData.data[0].department}`, marginLeft, 200);
            doc.text(`Designation: ${getData.data[0].designation}`, marginLeft, 220);

            doc.autoTable(content);

            // doc.setProperties({
            //     title: `LT Advance Ledger Report ${moment(new Date()).format(
            //         'YYYY-MM-DD'
            //     )}`,
            // });
            // ct doc.output('dataurlnewwindow');

            doc.save(
                `LT Advance Ledger Report ${moment(new Date()).format(
                    'YYYY-MM-DD'
                )}.pdf`
            );

        }).catch((error) => {
            dispatch(errorGetInfoLTLedger());
        })
    };
};
//Get ST Advance
export const requestGetInfoSTLedger = () => {
    return {
        type: "REQUEST_GET_INFO_ST_LEDGER",
    };
};
export const successGetInfoSTLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_ST_LEDGER",
        payload: data,
    };
};
export const errorGetInfoSTLedger = () => {
    return {
        type: "ERROR_GET_INFO_ST_LEDGER",
    };
};

export const getInfoSTLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoSTLedger());
        API.post(`/short_term_adv/get_st_logs`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetInfoLTLedger(getData));

            console.group("ST Ledger get_st_logs")
            console.clear();
            console.log(res);
            console.dir(res, "stledger");
            console.log(getData, ' getData stledger');
            console.log(getData, 'data11');
            console.groupEnd("ST Ledger get_st_logs");

            // console.log(getData[1].voucher_date, "d1")
            // console.log(getData[1].voucher_manual_number, "d1")
            // console.log(getData[1].voucher_description, "d1")

            const unit = 'pt';
            const size = 'A4'; // Use A1, A2, A3 or A4
            const orientation = 'portrait'; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = 'ST Advance Ledger';
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
            const Dates =
                !isEmpty(data.startedDate) || !isEmpty(data.endDate)
                    ? 'Form: ' + ' ' + data.startedDate + '  to:  ' + data.endDate
                    : 'Form: -  To: - ';
            // const TotalCredit = 'Total Credit: ' + getData.credit_sum;
            // const TotalDebit = 'Total Debit: ' + getData.debit_sum;

            const headers = [
                [
                    'DATE',
                    'ACCOUNT',
                    'TYPE',
                    'NARRATION',
                    'DEBIT',
                    'CREDIT',
                    'BALANCE',
                ],
            ];
            const tabledata = getData.data.map((x) => [

                x.short_term_ledger_date,
                x.cross_account_name,
                x.acc_type,
                x.short_term_description,
                x.debit_value,
                x.credit_value,
                x.closing_balance,

                // x.acc_name,
                // x.accBalance,
                // x.short_term_deduction_value,
                // // ,x.accBalance,Number(x.accType) === 1 ? 'CR' : 'DR'
            ]);
            tabledata.unshift([
                null,
                null,
                null,
                `Opening Balance on ${data.startedDate}`,
                null,
                null,
                getData.data[0].closing_balance,
              ]);
              const arr = [];
              console.log(arr.length);
              tabledata.push([
                null,
                null,
                null,
                `Closing Balance on ${data.endDate}`,
                null,
                null,
                getData.data[getData.data.length - 1].closing_balance,
              ]);
              tabledata.push([
                null,
                null,
                null,
                null,
                sumBy(getData.data, 'debit_value') === 0
                  ? null
                  : sumBy(getData.data, 'debit_value'),
                sumBy(getData.data, 'credit_value') === 0
                  ? null
                  : sumBy(getData.data, 'credit_value'),
                getData.data[getData.data.length - 1].closing_balance,
              ]);
          

            console.log(getData, 'abcd');
            let content = {
                startY: 240,
                startX: 10,
                // styles: { cellWidth: 73 },
                head: headers,
                body: tabledata,
            };

            // doc.text(companyName, 200, 60, 0, 20);
            doc.addImage(logo, 'PNG', 110, 1, 130, 130);
            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(companyName, 220, 60, 0, 20);
            doc.setFontSize(13);
            doc.setFont('TimesNewRoman', 'Normal');
            doc.text(companyAddress, 220, 80, 0, 20);
            doc.text(companyNtn, 220, 100, 0, 20);
            doc.setFontSize(14);

            doc.text(Dates, marginLeft, 160, 0, 0);
            //  doc.text(accountNo, marginLeft, 140, 0, 20);
            //  doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.text(title, marginLeft, 140, 0, 0);
            doc.text(`Employee: ${getData.data[0].employee_id}-${getData.data[0].acc_name}`, marginLeft, 180);
            doc.text(`Department: ${getData.data[0].department}`, marginLeft, 200);
            doc.text(`Designation: ${getData.data[0].designation}`, marginLeft, 220);

            doc.autoTable(content);
            // doc.setProperties({
            //     title: `ST Advance Ledger Report ${moment(new Date()).format(
            //         'YYYY-MM-DD'
            //     )}`,
            // });
            // doc.output('dataurlnewwindow');

            doc.save(
                `ST Advance Ledger Report ${moment(new Date()).format(
                    'YYYY-MM-DD'
                )}.pdf`
            );

            dispatch(successGetInfoSTLedger(getData));
        }).catch((error) => {
            dispatch(errorGetInfoSTLedger());
        })
    };
};
//get lt/st recivig ledger
export const requestGetInfoLTRecivingLedger = () => {
    return {
        type: "REQUEST_GET_INFO_LT_RECIVINGLEDGER",
    };
};
export const successGetInfoLTRecivingLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_LT_RECIVINGLEDGER",
        payload: data,
    };
};
export const errorGetInfoLTRecivingLedger = () => {
    return {
        type: "ERROR_GET_INFO_LT_RECIVINGLEDGER",
    };
};

export const getInfoLTRecivingLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoLTRecivingLedger());
        API.post(`/long_term_adv/get_lt_employee_ledge_report`, data).then((res) => {
            let getData = res.data.lt_ledger;
            console.log(res, 'ledg')
            dispatch(successGetInfoLTRecivingLedger(getData));
        }).catch((error) => {
            dispatch(errorGetInfoLTRecivingLedger());
        })
    };
};
//Get ST Advance
export const requestGetInfoSTRecivingLedger = () => {
    return {
        type: "REQUEST_GET_INFO_ST_RECIVINGLEDGER",
    };
};
export const successGetInfoSTRecivingLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_ST_RECIVINGLEDGER",
        payload: data,
    };
};
export const errorGetInfoSTRecivingLedger = () => {
    return {
        type: "ERROR_GET_INFO_ST_RECIVINGLEDGER",
    };
};

export const getInfoSTRecivingLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoSTRecivingLedger());
        API.post(`/short_term_adv/get_st_employee_ledger_report`, data).then((res) => {
            let getData = res.data.st_ledger;
            console.log(res, 'ledg')
            dispatch(successGetInfoSTRecivingLedger(getData));
        }).catch((error) => {
            dispatch(errorGetInfoSTRecivingLedger());
        })
    };
};
import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
import LTAdvance from "./LTAdvance"
import STAdvance from "./STAdvance";

const HRVoucher = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 69) ?
                "ltAdvance" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 70) ?
                    "stAdvance" : ""}>
                <Tab
                    eventKey="ltAdvance"
                    title="LT Advance"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 69) ? false : true}>
                    <LTAdvance />
                </Tab>
                <Tab
                    eventKey="stAdvance"
                    title="ST Advance"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 70) ? false : true}>
                    <STAdvance />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HRVoucher);
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getAllInventory,
  requestSearchIssuanceByBranch, searchIssuanceReportByBranch,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getBranch } from "./Branch/BranchDetails.actions";

const IssuanceReportByBranch = (props) => {


  const [branch, setBranch] = useState("");
  const [branchLabel, setBranchLabel] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [productLabel, setProductLabel] = useState();
  const [productId, setProductId] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true)
    setTimeout(() => {
        setAlertVisible(false)
    }, 9000);
    Swal({
        title: "Data Not Found",
        text: "Data Not Found",
        icon: "info",
        button: "Ok",
    });
}

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.productName,
    };
    return data;
  });
  const handleChangeProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductLabel(selectedOption.label);

  };
  const getBranchList = props.branchData.map((x) => {
    let data = { value: x.branch_id, label: x.branch_name };
    return data;
  });
const handleChangeBranch = (selectedOption) => {
  setBranch(selectedOption.value);
  setBranchLabel(selectedOption.label)
};
  const exportPDF = () => {
    props.searchIssuanceByBranch(
      {
         startedDate : !isEmpty(startDate) ? startDate : "",
          endDate: !isEmpty(endDate) ? endDate : "",
        branch_id: branch,
        inventoryId:productId
      },
      branchLabel,
      handleVisible
    );
  };

  useEffect(() => {
    props.GetBranch();
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
  }, []);
  return (
    <>
      {props.isFetchingBranch ||props.isFetchingInventoryAll? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Branch</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Branch..."
                                        onChange={(e) => handleChangeBranch(e)}
                                        options={getBranchList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory *</b>
                        </Form.Label>
                        <Select
                          isDisabled={isEmpty(inventory)}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          value={{ label: productLabel }}
                          onChange={(e) => handleChangeProduct(e)}
                          options={inventory}
                        />
                      </Form.Group>
                    </Col>
            </Row>
            <div className="sendDiv" style={{marginTop:'20px'}}>
              {/* {!issuanceNameValue ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Issuance Report</Button> :  <Button className="sendButton" onClick={() => exportPDF()}>Generate Issuance Report</Button>} */}

              {isUndefined(startDate) && productId=="" && branch=="" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate OGP Report
                </Button>
              ) : props.isFetchingSearchIssuanceList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate OGP Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchIssuanceByBranch: (data,branchLabel,handleVisible) =>
      dispatch(
        searchIssuanceReportByBranch(data,branchLabel,handleVisible)
      ),
      GetBranch: () => dispatch(getBranch()),
      getAllInventory: (data) => dispatch(getAllInventory(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingBranch:state.BranchReducer.isFetchingBranch,
  branchData:state.BranchReducer.branchData,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuanceReportByBranch);

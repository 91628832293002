import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  addPurchase,
  getApproveDemandNew,
  searchPurchaseReport,
  getApprovePurchase,
  purchaseById,
  purchaseFromDemandCall,
  getAllInventory,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, filter, isUndefined,isNull} from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendorData } from "./Vendor/Vendor.action";

const AddPurchase = (props) => {
  const [inputField, setInputField] = useState([
  
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [demandName, setDemandName] = useState("");
  const [lastpodate, setLastPoDate] = useState();
  
  const [total, setTotal] = useState(0);
  const [purchaseName, setPurchaseName] = useState("");
  const [purchaseDescription, setPurchaseDescription] = useState();
  const [supplier, setSupplier] = useState();
  const [demandNameValue, setDemandNameValue] = useState();
  const [demandNameLabel, setDemandNameLabel] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [expectedDate, setExpectedDate] = useState("");
  const [purchaseTax, setPurchaseTax] = useState(0);
  const [ManualPurchaseNumber, setManualPurchaseNumber] = useState(0);
  const [saveId, setSaveId] = useState([]);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "PurchaseOrder Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  // const getPurchaseList = props.approvePurchaseList.map((x) => {let data = {value: x.purchaseOrderId, label: x.purchaseName}; return data});
  // const getDemandList1 = [isEmpty(props.purchaseListById) ? [] : { value: props.purchaseListById[0].purchase_data[0].create_demand.demandId, label: props.purchaseListById[0].purchase_data[0].create_demand.demandName }];/*
  function uploadPurchase() {
    props.addPurchase(
      {
        vendorId: demandName,
        PODescription: purchaseDescription,
        supplier: supplier,
        expectedPoDate: expectedDate,
        // purchaseName: purchaseName,
        purchaseTax: purchaseTax,
        consumptionDays: ManualPurchaseNumber,
        inventoryId: inputField.map((x) => {
          return x.inventoryId;
        }),
        data: inputField.map((x) => {
          let data = {
            inventoryId: x.inventoryId,
            productQuantity: x.productQuantity,
            market_price: x.marketPrice,
            inventoryLabel:x.inventoryLabel,
            uom:x.uom,
          };
          return data;
        }),
      },
      handleVisible,
      handleDangerVisible
    );
    setDemandNameLabel("");
    setManualPurchaseNumber("");
    setPurchaseName("");
    setSupplier("");
    setPurchaseDescription("");
    setPurchaseTax(0);
    setTotal(0)
    setDemandName("-1");
    setPurchaseName("");
    setPurchaseDescription("");
    setSupplier("");
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    let dumyArry=!isEmpty(props.allInventory)&&filter(props.allInventory, x => x.showInDemand=="yes")
    setInputField(!isEmpty(dumyArry)&& dumyArry.map((x, id)=>   {
      return({

            inventoryId: x.inventoryId,
            productQuantity: 0,
            productPrice: x.productPrice,
            uom:x.uom,
            marketPrice: x.latestPrice,
            inventoryLabel: x.productName,
            productQuantityCount:x.productPrice,
            backupDays:x.backupDays,
      })
      }  ))
  }

  const getDemandList = props.approveDemandListNew.map((x) => {
    let data = { value: x.demandId, label: x.demandId + " / " + x.demandName };
    return data;
  });
  const getVendorList = props.vendorDataList.map((x) => {
    let data = { value: x.vendorId, label: x.vendorId + " / " + x.vendorName ,poDate:x.lastPODate};
    return data;
  });

  const inventory = props.porductListDemand.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.inventory.warehouse.whKey +
        "-" +
        x.inventory.category.cateKey +
        "-" +
        x.inventory.invKey +
        " " +
        x.productName,
    };
    return data;
  });

  const getDemand = (selectedOption) => {
    // props.getProductByDemand(selectedOption.value);
    setDemandName(selectedOption.value);
    setDemandNameLabel(selectedOption.label);
    setLastPoDate(selectedOption.poDate);
  };

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.allInventory, (x) => x.inventoryId == item);
    const list = [...inputField];
    console.log(z,"zzzzzzzzzzzzzzzz",list[index]["marketPrice"])
    // if (Number(e.target.value) <= Number(z.productQuantity)) {
      const { name, value } = e;
      
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname")
        list[index]["productQuantity"] = Number(z.productQuantity);
      if (type == "intquantity")
        list[index]["productQuantity"] =
          e.target.value < 0
            ? alert("cannot added less than 0")
            : e.target.value;
             if (type == "intquantity"){
             setTotal(
             list[index]["marketPrice"]==0?total+(e.target.value*z.latestPrice):total+(e.target.value*list[index]["marketPrice"]))
             }

      // if (type == 'intprice') list[index]['marketPrice'] = e.target.value;

      setInputField(list);
    // } else {
    //   alert("value out of range");
    // }
  };

  const handleChange = (e, index, type) => {
    let z = find(props.porductListDemand, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.productQuantity);
    if (type == "intname")
      list[index]["productPrice"] = Number(z.inventory.productPrice);
      if (type == "intname")
      list[index]["uom"] = z.uom;
    if (type == "intname") setMaximumQuantity(Number(z.productQuantity));
    // if (type == 'intquantity') list[index]['productQuantity'] = e.target.value;
    if (type == "intquantity")
      list[index]["productQuantity"] =
        e.target.value < 0 || e.target.value > Number(z.productQuantity)
          ? alert("value out of range")
          : e.target.value;
    if (type == "intprice") list[index]["marketPrice"] = e.target.value;

     if (type == "intprice") setTotal(total+(e.target.value*list[index]["productQuantity"]))
    
    if (type == "intname") setSaveId(e.value);
    setInputField(list);
    //list[index]['productQuantity'] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        inventoryId: "-1",
        productQuantity: 0,
        productQuantityCount: "",
        productPrice: 0,
        marketPrice: 0,
        uom:"",
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const getChecked = (data) => {
    let val =
      !isUndefined(data[0].demand_quantities) &&
      data[0].demand_quantities.map((ar) => ar.productQuantity);
    return false;
  };

  useEffect(() => {
    // props.getPurchase();
    // props.getApprovePurchase();
    props.getVendorData();
    // props.getApproveDemandNew();
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    let dumyArry=!isEmpty(props.allInventory)&&filter(props.allInventory, x => x.showInDemand=="yes")
    setInputField(!isEmpty(dumyArry)&& dumyArry.map((x, id)=>   {
        return({

              inventoryId: x.inventoryId,
              productQuantity: 0,
              productPrice: x.productPrice,
              marketPrice: x.latestPrice,
              inventoryLabel: x.productName,
              uom:x.uom,
              productQuantityCount:x.productPrice,
              backupDays:x.backupDays,
        })
        }  ))
  }, []);
  return (
    <>
      {props.isFetchningApproveDemandListNew ||
      props.isFetchingProductByDemand ||
      props.isFetchingVendor||
      props.isFetchingInventoryAll||
      isEmpty(props.allInventory) ||
      props.isAddingPurchase ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingPurchase ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: demandNameLabel }}
                    placeholder="Select Vendor..."
                    onChange={(e) => getDemand(e)}
                    options={getVendorList}
                    //isOptionDisabled={(option) => !isEmpty(props.approveDemandList) && getChecked(filter(props.approveDemandList, y => y.demandId == option.value))}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Last P/o Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last P/o Date...."
                    disabled
                    value={isNull(lastpodate)?"No Last P/o found of this vendor":lastpodate}
                    // onChange={(e) => setManualPurchaseNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Consumption Days *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Consumption Days...."
                    name="Manualpurchase#"
                    min={0}
                    value={ManualPurchaseNumber}
                    onChange={(e) => setManualPurchaseNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Expected Completion Date *</b>
                  </Form.Label>

                  <Form.Control
                    id="expextedDate"
                    type="date"
                    value={expectedDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => setExpectedDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Purchaser Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Purchase Name...."
                    name="purchaseName"
                    value={purchaseName}
                    onChange={(e) => setPurchaseName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Supplier Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add supplier...."
                    name="supplier"
                    value={supplier}
                    onChange={(e) => setSupplier(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Purchase Description..."
                    name="purchaseDescription"
                    value={purchaseDescription}
                    onChange={(e) => setPurchaseDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Tax(%):</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    placeholder="Add Purchase Tax..."
                    name="purchaseTax"
                    value={purchaseTax}
                    onChange={(e) => setPurchaseTax(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    // min={0}
                    // max={100}
                    placeholder="Total..."
                    // name="purchaseTax"
                    value={total}
                    disabled
                    // onChange={(e) => setPurchaseTax(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            {!isEmpty(inputField)&&inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory *</b>
                        </Form.Label>
                        <Select
                          //value={wareHouseId}
                          isDisabled={isEmpty(inventory)}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          value={{ label: item.inventoryLabel }}
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={inventory}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.inventoryId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="1" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Back up Days *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          value={item.backupDays}
                          min={0}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                       <Col xl="2" lg="2" md="6" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Stock Price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Product Price...."
                          name="productPrice"
                          value={item.productPrice}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="1" md="6" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>UOM *</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="UOM...."
                          name="productPrice"
                          value={item.uom}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Avaliable Qty *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled={true}
                          placeholder="Previous Quantity"
                          name="productQuantity"
                          value={item.productQuantityCount}
                        />
                      </Form.Group>
                    </Col>
                 
                    <Col xl="1" lg="1" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Qty </b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          value={item.productQuantity}
                          min={0}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intquantity",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  
                    
                    <Col xl="2" lg="2" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Market Price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Market Price...."
                          name="marketPrice"
                          min={0}
                          value={item.marketPrice==0?item.productPrice:item.marketPrice}
                          onChange={(e) => handleChange(e, i, "intprice")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    {/* <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row> */}
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {demandName=="" ||
              ManualPurchaseNumber==0||
              // purchaseName==""||
              inputField[0].inventoryId == "" 
              //|| inputField[0].productQuantity == 0 
              ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadPurchase}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    addPurchase: (data, handleVisible, handleDangerVisible) =>
      dispatch(addPurchase(data, handleVisible, handleDangerVisible)),
    getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
    getApproveDemandNew: () => dispatch(getApproveDemandNew()),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    searchPurchaseReport: (
      data,
      purchaseName,
      startDate,
      endDate,
      demandName
    ) =>
      dispatch(
        searchPurchaseReport(data, purchaseName, startDate, endDate, demandName)
      ),
    getApprovePurchase: () => dispatch(getApprovePurchase()),
    purchaseById: (data) => dispatch(purchaseById(data)),
    getVendorData: () => dispatch(getVendorData()),
    purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  demandList: state.AddDataReducer.demandList,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  porductListDemand: state.AddDataReducer.porductListDemand,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandListNew: state.AddDataReducer.approveDemandListNew,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  approvePurchaseList: state.AddDataReducer.approvePurchaseList,
  purchaseListById: state.AddDataReducer.purchaseListById,
  isFetchingSearchPurchaseList:
    state.AddDataReducer.isFetchingSearchPurchaseList,
  isFetchingPurchaseList: state.AddDataReducer.isFetchingPurchaseList,
  purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
  isFetchingProductByDemand: state.AddDataReducer.isFetchingProductByDemand,
  isFetchningApproveDemandListNew:
    state.AddDataReducer.isFetchningApproveDemandListNew,
  isFetchingPurchaseFromDemand:
    state.AddDataReducer.isFetchingPurchaseFromDemand,
    vendorDataList:state.VendorReducer.vendorDataList,
    isFetchingVendor:state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchase);

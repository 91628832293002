import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./add-details.css";
import {
    getCategoryData, addCategoryName, deleteCategory, getWarehouseData, getsubCategoryData, addInventory, getInventory
    , deleteInventory, updateInventory, getAttributeById, resetReducer, uploadFile, getAllInventory, searchInventoryReport, categoryByWareHouse, subCategoryByCategory
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import logo from "../../../../Images/logo.png";
import jsPDF from 'jspdf';
import Select from 'react-select';
import Swal from 'sweetalert';

const InventoryReport = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [wareHouseId, setwareHouseId] = useState();
    const [wareHouseId1, setwareHouseId1] = useState();
    const [subCategoryId, setSubCategoryId] = useState('');
    const [subCategoryId1, setSubCategoryId1] = useState('');
    console.log(subCategoryId1, "subbbbbbbbbbbbbb");
    const [categoryId, setcategoryId] = useState('');
    const [categoryId1, setcategoryId1] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }
    // const handleChangeWareHouse = (selectedOption) => {
    //     setwareHouseId(selectedOption.value);
    //     setwareHouseId1(selectedOption.label);
    // };

    const exportPDF = () => {
        props.searchInventoryReport({
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
            wareHouseId: wareHouseId1,
            subCategoryId: subCategoryId1 ,
            categoryId: categoryId1
        }, wareHouseId1, startDate, endDate, handleVisible);

    }

    const getWareHouseList = props.warehouseList.map((x) => { let data = { value: x.wareHouseId, label: x.wareHouseName }; return data });
    const getcategoryList = !isEmpty(props.categoryByWareHouseValue) && props.categoryByWareHouseValue.map((x) => { let data = { value: x.categoryId, label: x.categoryName }; return data });
    const getSubCategoryList = !isEmpty(props.subCategoryByCategoryValue) && props.subCategoryByCategoryValue.map((x) => { let data = { value: x.subCategoryId, label: x.subCategoryName }; return data });
    const handleChangeWareHouse = (selectedOption) => {
        setcategoryId1('');
        setcategoryId('');
        setSubCategoryId('');
        setSubCategoryId1('');
        setwareHouseId1(selectedOption.value);
        setwareHouseId(selectedOption.label);
        props.categoryByWareHouse(selectedOption.value);
    };

    const handleChangeCategory = (selectedOption) => {
        setSubCategoryId('');
        setSubCategoryId1('');
        setcategoryId1(selectedOption.value);
        setcategoryId(selectedOption.label);
        props.subCategoryByCategory(selectedOption.value);
    };

    const handleChangeSubCategory = (selectedOption) => {
        setSubCategoryId(selectedOption.label);
        setSubCategoryId1(selectedOption.value);
    };

    return (
        <>
            {props.isFetchingwareHouseList || props.isFetchingSearchInventoryList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchInventoryList ? 'Downloading PDF...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col xl="4" lg="4" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Warehouse </b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select WareHouse..."
                                        onChange={(e) => handleChangeWareHouse(e)}
                                        options={getWareHouseList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Col xl="4" lg="4" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Category </b>
                                    </Form.Label>
                                    <Select
                                     isDisabled={isEmpty(getcategoryList) || isEmpty(wareHouseId)}
                                        value = {{label: categoryId}}
                                        placeholder="Select Category..."
                                        onChange={(e) => handleChangeCategory(e)}
                                        options={getcategoryList}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xl="4" lg="4" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Sub-Category </b>
                                    </Form.Label>
                                    <Select
                                        value = {{label: subCategoryId}}
                                        placeholder="Select Sub Category..."
                                        onChange={(e) =>handleChangeSubCategory(e)}
                                        isDisabled={isEmpty(getSubCategoryList) || isEmpty(categoryId)}
                                        options={isEmpty(categoryId1) && isEmpty(wareHouseId1) ? getSubCategoryList: []}
                                      
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            {isEmpty(wareHouseId) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Inventory Report</Button> : props.isFetchingSearchInventoryList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Inventory Report</Button>}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouseData: () => dispatch(getWarehouseData()),
        categoryByWareHouse: (data) => dispatch(categoryByWareHouse(data)),
        subCategoryByCategory: (data) => dispatch(subCategoryByCategory(data)),
        resetState: () => dispatch(resetReducer()),
        searchInventoryReport: (data, name, startDate, endDate, handleVisible) => dispatch(searchInventoryReport(data, name, startDate, endDate, handleVisible))
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    searchInventoryList: state.AddDataReducer.searchInventoryList,
    isFetchingSearchInventoryList: state.AddDataReducer.isFetchingSearchInventoryList,
    categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
    subCategoryByCategoryValue: state.AddDataReducer.subCategoryByCategoryValue,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryReport);
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getPurchase,
  getApproveDemand,
  addIssuance,
  getAllInventory,
  getIssuance,
  searchIssuanceReport,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";

const IssuanceReport = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState();
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  console.log(issuanceNameLabel, "ooooooooopqqqqqddddddddddddddd");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);
    console.log(issuanceNameValue, "oooooooooooppppppppppppppp");
    setIssuanceNameLabel(selectedOption.label);
  };

  const getIssuanceList = props.IssuanceList.map((x) => {
    let data = { value: x.issuanceId, label: x.issuanceId };
    return data;
  });

  const exportPDF = () => {
    props.searchIssuanceReport(
      {
        // startedDate : !isEmpty(startDate) ? startDate : "",
        // endDate: !isEmpty(endDate) ? endDate : "",
        issuanceId: issuanceNameValue,
      },
      issuanceNameLabel,
    //   startDate,
    //   endDate
    );
  };

  useEffect(() => {
    props.getIssuance();
  }, []);
  return (
    <>
      {props.isFetchingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {/* <Row>
                                    <Col xl="6" lg="6" md="6" sm="6">
                                        <FormGroup>
                                            <Form.Label>
                                                <b>Start Date :</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            >
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" sm="6">
                                        <FormGroup>
                                            <Form.Label>
                                                <b>End Date</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            >
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                </Row> */}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select OGP</b>
                  </Form.Label>
                  <Select
                    placeholder="Select OGP..."
                    onChange={(e) => handleChangeGetIssuance(e)}
                    options={getIssuanceList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv" style={{marginTop:'20px'}}>
              {/* {!issuanceNameValue ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Issuance Report</Button> :  <Button className="sendButton" onClick={() => exportPDF()}>Generate Issuance Report</Button>} */}

              {isUndefined(issuanceNameValue) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate OGP Report
                </Button>
              ) : props.isFetchingSearchIssuanceList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate OGP Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    getApproveDemand: () => dispatch(getApproveDemand()),
    addIssuance: (data, handleVisible, handleDangerVisible) =>
      dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getIssuance: () => dispatch(getIssuance()),
    searchIssuanceReport: (data, issuanceName) =>
      dispatch(searchIssuanceReport(data, issuanceName)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  IssuanceList: state.AddDataReducer.IssuanceList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceReport);

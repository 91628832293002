import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { getLevel5Data } from "../forms/accountsLevels/accountsLevel.action";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../Images/logo.png";
import { Link } from "react-router-dom";
import { isEmpty, sortBy } from "lodash";

const TrialBalanceTable = (props) => {
  const trialData = sortBy(props.trialList, ["props.trialList", "allLevelKey"]);
  return (
    <>
      {props.isFetchingTrialBalance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/user/trialBalance">
                <Button className="sendButton"> Back to Search</Button>
              </Link>
            </div>

            <MaterialTable
              title="Trial Balance"
              columns={[
                {
                  title: "Account",
                  field: "levelFiveId",
                },
                { title: "Name", field: "levelFiveName" },
                {
                  title: "Opening Debit",
                  field: "opening_balance_sign",
                  render: (rowData) =>
                    rowData.opening_balance_sign == 2
                      ? rowData.opening_balance
                      : 0,
                },
                {
                  title: "Opening Credit",
                  field: "opening_balance_sign",
                  render: (rowData) =>
                    rowData.debitCreditId == 1 ? rowData.opening_balance : 0,
                },
                {
                  title: "During Debit",
                  field: "debit_sum",
                },
                {
                  title: "During Credit",
                  field: "credit_sum",
                },
                {
                  title: "closing Debit",
                  field: "closingDebit",
                  render: (rowData) =>
                    rowData.closing_balance_sign == 2
                      ? rowData.closing_balance
                      : 0,
                },
                {
                  title: "closing Credit",
                  field: "closingCredit",
                  render: (rowData) =>
                    rowData.closing_balance_sign == 1
                      ? rowData.closing_balance
                      : 0,
                },
              ]}
              data={trialData}
              options={{
                 exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 200,
                sorting: true, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                filtering: true,
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = [
                    {
                      title: "Account",
                      field: "levelFiveId",
                      //  customSort: (a, b) => a.levelFiveId < b.levelFiveId,
                    },
                    { title: "Name", field: "levelFiveName" },
                    // { title: "Date", field: "createdAt", render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                    {
                      title: "Opening Debit",
                      field: "levelBalance",
                      render: (rowData) =>
                        rowData.debitCreditId == 2
                          ? !isEmpty(rowData.voucher_ledgers) &&
                            rowData.voucher_ledgers[0].opening_balance
                          : 0,
                    },
                    {
                      title: "Opening Credit",
                      field: "levelBalance",
                      render: (rowData) =>
                        rowData.debitCreditId == 1
                          ? !isEmpty(rowData.voucher_ledgers) &&
                            rowData.voucher_ledgers[0].opening_balance
                          : 0,
                    },
                    {
                      title: "During Debit",
                      field: "voucherData.voucherAmount",
                      render: (rowData) =>
                        !isEmpty(rowData.voucher_data)
                          ? rowData.voucher_data
                              .map((x) =>
                                x.accountType == 2 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)
                          : 0,
                    },
                    {
                      title: "During Credit",
                      field: "voucherData.voucherAmount",
                      render: (rowData) =>
                        !isEmpty(rowData.voucher_data)
                          ? rowData.voucher_data
                              .map((x) =>
                                x.accountType == 1 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)
                          : 0,
                    },
                    // { title: "closing Debit", field: "closingDebit", render: rowData => !isEmpty(rowData.voucher_data) ? ((rowData.debitCreditId == 1 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) - (rowData.debitCreditId == 2 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) + (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 1 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)) - (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 2 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b))) < 0 ? Math.abs(((rowData.debitCreditId == 1 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) - (rowData.debitCreditId == 2 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) + (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 1 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)) - (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 2 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)))) : 0 : 0 },
                    // { title: "closing Credit", field: "closingCredit", render: rowData => !isEmpty(rowData.voucher_data) ? ((rowData.debitCreditId == 1 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) - (rowData.debitCreditId == 2 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) + (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 1 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)) - (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 2 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b))) > 0 ? Math.abs(((rowData.debitCreditId == 1 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) - (rowData.debitCreditId == 2 ? !isEmpty(rowData.account_histories) && Number(rowData.account_histories[0].accBalance) : 0) + (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 1 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)) - (!isEmpty(rowData.voucher_data) && rowData.voucher_data.map((x) => x.accountType == 2 ? Number(x.voucherAmount) : 0).reduce((a, b) => a + b)))) : 0 : 0 },
                    {
                      title: "During Debit",
                      field: "",
                      render: (rowData) =>
                        rowData.debitCreditId == 2 ? rowData.levelBalance : 0,
                    },
                    {
                      title: "During Credit",
                      field: "",
                      render: (rowData) =>
                        rowData.debitCreditId == 1 ? rowData.levelBalance : 0,
                    },
                  ].map((columnDef) => columnDef.title);

                  const pdfData = data.map((rowData) => [
                    rowData.levelFiveId,
                    rowData.levelFiveName,
                    moment(rowData.createdAt).format("YYYY-MM-DD"),
                    rowData.debitCreditId == 2
                      ? !isEmpty(rowData.account_histories) &&
                        rowData.account_histories[0].accBalance
                      : 0,
                    rowData.debitCreditId == 1
                      ? !isEmpty(rowData.account_histories) &&
                        rowData.account_histories[0].accBalance
                      : 0,
                    !isEmpty(rowData.voucherData)
                      ? rowData.voucherData
                          .map((x) =>
                            x.accountType == 2 ? Number(x.voucherAmount) : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0,
                    !isEmpty(rowData.voucherData)
                      ? rowData.voucherData
                          .map((x) =>
                            x.accountType == 1 ? Number(x.voucherAmount) : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0,
                    !isEmpty(rowData.voucherData)
                      ? (rowData.debitCreditId == 1
                          ? !isEmpty(rowData.account_histories) &&
                            Number(rowData.account_histories[0].accBalance)
                          : 0) -
                          (rowData.debitCreditId == 2
                            ? !isEmpty(rowData.account_histories) &&
                              Number(rowData.account_histories[0].accBalance)
                            : 0) +
                          (!isEmpty(rowData.voucherData) &&
                            rowData.voucherData
                              .map((x) =>
                                x.accountType == 1 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)) -
                          (!isEmpty(rowData.voucherData) &&
                            rowData.voucherData
                              .map((x) =>
                                x.accountType == 2 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)) <
                        0
                        ? Math.abs(
                            (rowData.debitCreditId == 1
                              ? !isEmpty(rowData.account_histories) &&
                                Number(rowData.account_histories[0].accBalance)
                              : 0) -
                              (rowData.debitCreditId == 2
                                ? !isEmpty(rowData.account_histories) &&
                                  Number(
                                    rowData.account_histories[0].accBalance
                                  )
                                : 0) +
                              (!isEmpty(rowData.voucherData) &&
                                rowData.voucherData
                                  .map((x) =>
                                    x.accountType == 1
                                      ? Number(x.voucherAmount)
                                      : 0
                                  )
                                  .reduce((a, b) => a + b)) -
                              (!isEmpty(rowData.voucherData) &&
                                rowData.voucherData
                                  .map((x) =>
                                    x.accountType == 2
                                      ? Number(x.voucherAmount)
                                      : 0
                                  )
                                  .reduce((a, b) => a + b))
                          )
                        : 0
                      : 0,
                    !isEmpty(rowData.voucherData)
                      ? (rowData.debitCreditId == 1
                          ? !isEmpty(rowData.account_histories) &&
                            Number(rowData.account_histories[0].accBalance)
                          : 0) -
                          (rowData.debitCreditId == 2
                            ? !isEmpty(rowData.account_histories) &&
                              Number(rowData.account_histories[0].accBalance)
                            : 0) +
                          (!isEmpty(rowData.voucherData) &&
                            rowData.voucherData
                              .map((x) =>
                                x.accountType == 1 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)) -
                          (!isEmpty(rowData.voucherData) &&
                            rowData.voucherData
                              .map((x) =>
                                x.accountType == 2 ? Number(x.voucherAmount) : 0
                              )
                              .reduce((a, b) => a + b)) >
                        0
                        ? Math.abs(
                            (rowData.debitCreditId == 1
                              ? !isEmpty(rowData.account_histories) &&
                                Number(rowData.account_histories[0].accBalance)
                              : 0) -
                              (rowData.debitCreditId == 2
                                ? !isEmpty(rowData.account_histories) &&
                                  Number(
                                    rowData.account_histories[0].accBalance
                                  )
                                : 0) +
                              (!isEmpty(rowData.voucherData) &&
                                rowData.voucherData
                                  .map((x) =>
                                    x.accountType == 1
                                      ? Number(x.voucherAmount)
                                      : 0
                                  )
                                  .reduce((a, b) => a + b)) -
                              (!isEmpty(rowData.voucherData) &&
                                rowData.voucherData
                                  .map((x) =>
                                    x.accountType == 2
                                      ? Number(x.voucherAmount)
                                      : 0
                                  )
                                  .reduce((a, b) => a + b))
                          )
                        : 0
                      : 0,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.text(
                    `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`,
                    100,
                    20,
                    0,
                    20
                  );
                  doc.text("Level2 Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                // headerStyle: {
                //   backgroundColor: "#01579b",
                //   color: "#FFF",
                // },
              }}
              // components={{
              //   Header: (props) => {
              //     return (
              //       <TableHead>
              //         <TableRow>
              //           <TableCell rowSpan={2}>Account</TableCell>
              //           <TableCell rowSpan={2}>Name</TableCell>
              //           {/* <TableCell rowSpan={2}>Date</TableCell> */}
              //           <TableCell colSpan={2} align="center">
              //             opening
              //           </TableCell>
              //           <TableCell colSpan={2} align="center">
              //             During
              //           </TableCell>
              //           <TableCell colSpan={2} align="center">
              //             Closing
              //           </TableCell>
              //         </TableRow>

              //         <TableRow>
              //           <TableCell align="center">Debit</TableCell>
              //           <TableCell align="center">Credit</TableCell>
              //           <TableCell align="center">Debit</TableCell>
              //           <TableCell align="center">Credit</TableCell>
              //           <TableCell align="center">Debit</TableCell>
              //           <TableCell align="center">Credit</TableCell>
              //         </TableRow>
              //       </TableHead>
              //     );
              //   },
              //   // Row: ({ data }) => {
              //   //   return (
              //   //     <TableRow>
              //   //       <TableCell>{data.Account}</TableCell>
              //   //       <TableCell align="center">{data.Name}</TableCell>
              //   //       <TableCell align="center">{data.Debit}</TableCell>
              //   //       <TableCell align="center">{data.Credit}</TableCell>
              //   //       <TableCell align="center">{data.DDebit}</TableCell>
              //   //       <TableCell align="center">{data.DCredit}</TableCell>
              //   //       <TableCell align="center">{data.closingDebit}</TableCell>
              //   //       <TableCell align="center">{data.Credit}</TableCell>
              //   //     </TableRow>
              //   //   );
              //   // }
              // }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  trialList: state.accountReducer.trialList,
  isFetchingTrialBalance: state.accountReducer.isFetchingTrialBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalanceTable);

import {
  Container,
} from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialTable from 'material-table';
import Spinner from "react-bootstrap/Spinner";
import { baseURL ,headers} from "../../../../../global/api";
import { deleteVendor, getVendorData, updateVendor } from "./Vendor.action";
// import { setHeading } from "../../../accounts/account.actions";
import { useEffect } from "react";
const ViewVendor = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  useEffect(() => {
     props.getVendorData();
    
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
        <MaterialTable
                  title="Vendor Details"
                  columns={[
                    // { title: 'Vendor Id', field: 'vendor_id', editable: 'never' },
                     { title: 'Vendor Name', field: 'vendorName' },
                     { title: 'Vendor Phone', field: 'vendorPhone' },
                     { title: 'Vendor Cnic', field: 'vendorCnic' },
                     { title: 'Vendor Address', field: 'vendorAddress' },
                   ]}
                  data={props.vendorDataList}
              //    data={(query) =>
              //     new Promise((resolve, reject) => {
              //         let url = `${baseURL}/vendor?`;
              //         url += "size=" + query.pageSize;
              //         url += "&page=" + (query.page);
              //         url += "&search=" + query.search;
              //         fetch(url, {
              //             method: 'GET',
              //             headers: headers,
              //         })
              //             .then((response) => response.json())
              //             .then((result) => {
              //                 resolve({
              //                     data: result.data,
              //                     page: result.page,
              //                     totalCount: result.total_results,
              //                 });
              //             });

              //     })
              // }
                  options={{
                    actionsColumnIndex: -1,
                   // filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 10,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions: [10, 20, 50],    // rows selection options
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                  editable={{
                    // isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Vendor Edit"),
                    // isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Vendor Delete"),
                     onRowUpdate: (newData, oldData) =>
                       new Promise((resolve) => {
                         setTimeout(() => {
                           resolve();
                           {
                             props.updateVendor(newData, oldData)
                           }
                         }, 600);
                       }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteVendor(oldData.vendorId);
                        }, 600);
                      }),
                  }}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorData: () => dispatch(getVendorData()),
    deleteVendor: (vendorId) => dispatch(deleteVendor(vendorId)),
      updateVendor: (newData, oldData) => dispatch(updateVendor(newData, oldData)),
      // setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorDataList:state.VendorReducer.vendorDataList,
  isFetchingVendor:state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVendor);

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  getApproveDemand,
  getApprovePurchase,
  purchaseById,
  addIGP,
  getIGP,
  searchIGPReport,
  resetPurchaseReducer,
  purchaseFromDemandCall,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";

const IGPReport = (props) => {
  const [IGPNameValue, setIGPNameValue] = useState();
  const [IGPNameLabel, setIGPNameLabel] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };
  const handleChangeGetIGP = (selectedOption) => {
    setIGPNameValue(selectedOption.value);
    setIGPNameLabel(selectedOption.label);
  };
  const getIGPList = props.IGPList.map((x) => {
    let data = { value: x.igpId, label: x.igpId };
    return data;
  });
  const exportPDF = () => {
    props.searchIGPReport(IGPNameValue, IGPNameLabel, handleVisible);
    setIGPNameLabel("");
  };
  useEffect(() => {
    props.getIGP();
    props.resetPurchaseReducer();
  }, []);
  return (
    <>
      {props.isFetchingIGP ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select IGP *</b>
                  </Form.Label>
                  <Select
                    value={{ label: IGPNameLabel }}
                    placeholder="Select IGP..."
                    onChange={(e) => handleChangeGetIGP(e)}
                    options={!isEmpty(getIGPList) ? getIGPList.reverse() : []}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv" style={{marginTop:'20px'}}>
              {IGPNameLabel == "" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate Inventory Report
                </Button>
              ) : props.isFetchingSearchIGPList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate IGP Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getIGP: () => dispatch(getIGP()),
    searchIGPReport: (id, igpName, handleVisible) =>
      dispatch(searchIGPReport(id, igpName, handleVisible)),
    resetPurchaseReducer: () => dispatch(resetPurchaseReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  IGPList: state.AddDataReducer.IGPList,
  isFetchingSearchIGPList: state.AddDataReducer.isFetchingSearchIGPList,
  isFetchingIGP: state.AddDataReducer.isFetchingIGP,
});

export default connect(mapStateToProps, mapDispatchToProps)(IGPReport);

import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
import LTAdvance from "./LTAdvance"
import STAdvance from "./STAdvance";
import LTReciving from "./LTReciving";
import STReciving from "./STReciving";

const HRRecivingVoucher = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 71) ?
                "ltReciving" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 72) ?
                    "stReciving" : ""}>
                <Tab
                    eventKey="ltReciving"
                    title="LT Receiving"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 71) ? false : true}>
                    <LTReciving />
                </Tab>
                <Tab
                    eventKey="stReciving"
                    title="ST Receiving"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 72) ? false : true}>
                    <STReciving />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HRRecivingVoucher);
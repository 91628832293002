import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
import HRSTLedger from "./HRSTLedger";
import HRLTLedger from "./HRLTLedger";
import HRSTLedgerReport from "./HRSTLedgerReport";
import HRLTLedgerReport from "./HRLTLedgerReport";

const HRLedgerTabs = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 77) ?
                "ltAdvance" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 78) ?
                    "stAdvance" :
                    !isNull(permissions) && permissions.some(p => p['sub_id'] === 79) ?
                    "ltAdvanceReport" :
                    !isNull(permissions) && permissions.some(p => p['sub_id'] === 80) ?
                    "stAdvanceReport" : ""}>
                <Tab
                    eventKey="ltAdvance"
                    title="LT Advance Employee Ledger"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 77) ? false : true}>
                    <HRLTLedger />
                </Tab>
                <Tab
                    eventKey="stAdvance"
                    title="ST Advance Employee Ledger"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 78) ? false : true}>
                    <HRSTLedger />
                </Tab>
                <Tab
                    eventKey="ltAdvanceReport"
                    title="LT Advance Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 79) ? false : true}>
                    <HRLTLedgerReport />
                </Tab>
                <Tab
                    eventKey="stAdvanceReport"
                    title="ST Advance Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 80) ? false : true}>
                    <HRSTLedgerReport />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HRLedgerTabs);
import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "../add-details.css";
import { getReceiving } from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
const RecevingDetails = (props) => {
  const wareHouseData = props.ReceivingList;

  useEffect(() => {
    // props.getReceiving();
  }, []);
  return (
    <>
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Good Received Note Details"
              //columns={state.viewColumns}
              columns={[
                { title: "GRN Id", field: "receiving_id" },
                { title: "warehouse", field: "warehouse.wareHouseName" },
                { title: "Received By", field: "receivedBy" },
                { title: "Received Date", field: "receiving_date" },
                { title: "Description", field: "reciving_description" },
              ]}
              // data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
              data={(query) =>
                new Promise((resolve, reject) => {
                  console.log(query, "qr");
                  let url = `${baseURL}receiving/get-all-receiving?`;
                  url += "size=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page - 1,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      {
                        title: "Inventory Code",
                        field: "inventory",
                        render: (rowData) =>
                          rowData.inventory.warehouse.whKey +
                          "-" +
                          rowData.inventory.category.cateKey +
                          "-" +
                          rowData.inventory.invKey,
                      },
                      {
                        title: "Inventory Name",
                        field: "inventory.productName",
                      },
                      { title: "Product Quantity", field: "productQuantity" },
                    ]}
                    data={rowData.receiving_data}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReceiving: () => dispatch(getReceiving()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  ReceivingList: state.AddDataReducer.ReceivingList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecevingDetails);

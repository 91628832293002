import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";

// export const requestGetCustomer = () => {
//     return {
//         type: "REQUEST_GET_CUSTOMER"
//     }
// }

// export const successGetCustomer = (data) => {
//     return {
//         type: "SUCCESS_GET_CUSTOMER",
//         payload: data,
//     }
// }

// export const errorGetCustomer = () => {
//     return {
//         type: "ERROR_GET_CUSTOMER"
//     }
// }
// export const getCustomer = () => {
//     return (dispatch) => {
//         dispatch(requestGetCustomer())
//         API.get('/invoice/get_invoice_customer').then((res) => {
//             dispatch(successGetCustomer(res.data))
//         }).catch((error) => {
//             dispatch(errorGetCustomer())
//         })
//     }
// }
//add Ivoice
export const requestAddBranch = () => {
    return {
      type: "REQUEST_ADD_BRANCH",
    };
  };
  export const successAddBranch = (data) => {
    return {
      type: "SUCCESS_ADD_BRANCH",
      payload: data,
    };
  };
  export const errorAddBranch = () => {
    return {
      type: "ERROR_ADD_BRANCH",
    };
  };
  export const addBranch = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
      dispatch(requestAddBranch());
      API.post(`/branch/`, data)
        .then((res) => {
           dispatch(getBranch());
          dispatch(successAddBranch(data));
          handleVisible();
        })
        .catch((error) => {
          dispatch(errorAddBranch());
          handleDangerVisible();
        });
    };
  };
  ///get Ivoice
  export const requestGetBranch = () => {
    return {
        type: "REQUEST_GET_BRANCH"
    }
}

export const successGetBranch = (data) => {
    return {
        type: "SUCCESS_GET_BRANCH",
        payload: data,
    }
}

export const errorGetBranch = () => {
    return {
        type: "ERROR_GET_BRANCH"
    }
}
export const getBranch = () => {
    return (dispatch) => {
        dispatch(requestGetBranch())
        API.get('/branch/').then((res) => {
            dispatch(successGetBranch(res.data))
        }).catch((error) => {
            dispatch(errorGetBranch())
        })
    }
}
//
export const requestGetBranchAdmin = () => {
  return {
      type: "REQUEST_GET_BRANCH_ADMIN"
  }
}

export const successGetBranchAdmin = (data) => {
  return {
      type: "SUCCESS_GET_BRANCH_ADMIN",
      payload: data,
  }
}

export const errorGetBranchAdmin = () => {
  return {
      type: "ERROR_GET_BRANCH_ADMIN"
  }
}
export const getBranchAdmin = () => {
  return (dispatch) => {
      dispatch(requestGetBranchAdmin())
      API.get('/branch/get_admin_branch').then((res) => {
          dispatch(successGetBranchAdmin(res.data))
      }).catch((error) => {
          dispatch(errorGetBranchAdmin())
      })
  }
}
//Branch-Delete
export const requestDeleteBranch = () => {
  return {
      type: "REQUEST_DELETE_BRANCH",
  };
};
export const successDeleteBranch = (data) => {
  return {
      type: "SUCCESS_DELETE_BRANCH",
      payload: data,
  };
};
export const errorDeleteBranch = () => {
  return {
      type: "ERROR_DELETE_BRANCH",
  };
};
export const deleteBranch = (branch_id) => {
  return (dispatch) => {
      dispatch(requestDeleteBranch());
      API.delete(`/branch/${branch_id}`).then((res) => {
          let getData = res.data;
          dispatch(successDeleteBranch());
          dispatch(getBranch());
      }).catch((error) => {
          dispatch(errorDeleteBranch());

      })
  };
};
//Branch-Update
export const requestUpdateBranch = () => {
  return {
      type: "REQUEST_UPDATE_BRANCH",
  };
};
export const successUpdateBranch = (data) => {
  return {
      type: "SUCCESS_UPDATE_BRANCH",
      payload: data,
  };
};
export const errorUpdateBranch = () => {
  return {
      type: "ERROR_UPDATE_BRANCH",
  };
};

export const UpdateBranch = (newData, oldData) => {
  return (dispatch) => {
      dispatch(requestUpdateBranch());
      API.put(`/branch/${oldData.branch_id}`, newData).then((res) => {
          let getData = res.data;
          dispatch(successUpdateBranch([newData]));
          dispatch(getBranch());
      }).catch((error) => {
          dispatch(errorUpdateBranch());
      })
  };
};
// //pdf
// export const getBranchPdf = (data) => {
//   console.log(data,"PDFdATA")
//     const unit = "pt";
//     const size = "A4";
//     const orientation = "potrait";

//     const marginLeft = 40;
//     const doc = new jsPDF(orientation, unit, size);

//     doc.setFontSize(11);
//     const title = "Branch Report";
//     const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
//     const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
//     const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
//     // const demandName = "Receiving Name: " + issuanceName;
//     // const IGPNumber = "Receiving #: " + resData.map((x) => x.receiving_id);
//     // const Dates =
//     //   !isEmpty(startDate) || !isEmpty(endDate)
//     //     ? "Receiving Date " + " " + startDate + " to " + endDate
//     //     : "Issuance Date - ";
//     // const headers = [
//     //   ["CODE", "MATERIAL NAME", "UOM", "PRICE", "QUANTITY", "VALUE"],
//     // ];
//     // const data = resData[0].receiving_data.map((elt) => [
//     //   elt.inventory.warehouse.whKey +
//     //     "-" +
//     //     elt.inventory.category.cateKey +
//     //     "-" +
//     //     elt.inventory.invKey,
//     //   elt.inventory.productName,
//     //   elt.inventory.uom,
//     //   elt.inventory.productPrice,
//     //   elt.productQuantity,
//     //   Number(elt.inventory.productPrice) * Number(elt.productQuantity),
//     // ]);
//     // let content = {
//     //   startY: 220,
//     //   startX: 10,
//     //   head: headers,
//     //   body: data,
//     // };
//     doc.addImage(logo, "PNG", 40, 15, 100, 100);
//     doc.text(companyName, 150, 40, 0, 20);
//     doc.setFontSize(13);
//     doc.setFont("TimesNewRoman", "Normal");
//     doc.text(companyAddress, 150, 60, 0, 20);
//     doc.text(companyNtn, 150, 80, 0, 20);
//     doc.setFontSize(14);
//     doc.setFont(undefined, "bold");
//     doc.text(title, 240, 120);
//     // doc.text(demandName, 200, 80, 0, 20);
//     // doc.text(IGPNumber, 200, 120, 0, 20);
//     // doc.text(Dates, 380, 120, 0, 20);
//     // doc.autoTable(content);
//     doc.save(
//       `Branch Report ${moment(new Date()).format(
//         "YYYY-MM-DD"
//       )}.pdf`
//     );
// }
// ////post Branch
// export const requestPostBranch = () => {
//     return {
//       type: "REQUEST_POST_BRANCH",
//     };
//   };
//   export const successPostBranch = (data) => {
//     return {
//       type: "SUCCESS_POST_BRANCH",
//       payload: data,
//     };
//   };
//   export const errorPostBranch = () => {
//     return {
//       type: "ERROR_POST_BRANCH",
//     };
//   };
//   export const PostBranch = (data, handleVisible, handleDangerVisible) => {
//     return (dispatch) => {
//       dispatch(requestPostBranch());
//       API.post(`/invoice/invoice_post`,data)
//         .then((res) => {
//            dispatch(getBranch());
//           dispatch(successPostBranch(res.data));
//         //   handleVisible();
//         })
//         .catch((error) => {
//           dispatch(errorPostBranch());
//         //   handleDangerVisible();
//         });
//     };
//   };
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Swal from "sweetalert";
import moment from "moment";
import { addBranch } from "./BranchDetails.actions";

const AddBranch = (props) => {
  const [BName, setBName] = useState("");
  const [brDesc, setBDesc] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);



  function uploadDepartment() {
    props.addBranch(
      {
       branch_name:BName,
       branch_desc:brDesc
      },
      handleVisible,
      handleDangerVisible
    );
    setBDesc("")
    setBName("")
  }

 

  const handleVisible = () => {
    setAlertVisible(true);
    // clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  return (
    <>
      {props.isFetchingBranch ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
            Saving
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
              <Form>
                <Row>
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Branch Name</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="6" lg="6" md="6" xs="6">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Branch Description</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBDesc(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
               
                
                <div className="sendDiv">
                  {brDesc==""||BName==""?
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
                    disabled
                    style={{backgroundColor:'black'}}
                  >
                    Save
                  </Button>:
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
              
                  >
                    Save
                  </Button>}
                </div>
              </Form>
            </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
     addBranch: (data,handleVisible, handleDangerVisible) => dispatch(addBranch(data,handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingBranch: state.BranchReducer.isFetchingBranch,
  // customerInfo: state.BranchReducer.customerInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBranch);

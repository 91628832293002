import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, upperCase } from "lodash";
import { addUom } from './UOM.actions';



const AddUom = (props) => {
    const [uomName, setUOMName] = useState();
    const [uomDescription, setUomDescription] = useState();

    const [submit, setSubmit] = useState(false);

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    function uploadDivision() {
        props.addUom({
            uomName: uomName,
            uomDescription: uomDescription,
        }, handleVisible, handleDangerVisible);
    }
    // const divisionData = isEmpty(props.divisionList) ? [] : props.divisionList.reverse();
    // console.log(divisionData);

   /* const [state, setState] = React.useState({
        columns: [
            { title: 'Division Name', field: 'division_name' },
            { title: 'Division Description', field: 'division_desc' },
        ],
    });*/
    const clearStates = () => {
        setUOMName();
        uomDescription();
        setSubmit(false);

    }
 

    useEffect(() => {
        // props.getHRDivision();

    }, []);
    return (
        <>
            {(props.isFetchingDivisionData || props.isAddingDivisionData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (

                <Container fluid>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter UOM Name :</b></Form.Label>
                                    <Form.Control type="text" placeholder="UOM Name...." onChange={(e) => setUOMName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Enter UOM Description:</b>
                                    </Form.Label>
                                    <Form.Control type='text'
                                        placeholder="UOM Description....." onChange={(e) => setUomDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                        {isEmpty(uomName)?
                            <Button className="sendButton" style={{backgroundColor:"Black"}} disabled>Save</Button>:
                            <Button className="sendButton" onClick={() => { uploadDivision(); setSubmit(true); }} >Save</Button>}
                        </div>
                    </Form>

                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addUom: (data, handleVisible, handleDangerVisible) => dispatch(addUom(data, handleVisible, handleDangerVisible)),
        // getHRDivision: () => dispatch(getHRDivision()),
        // UpdateHRDivision: (newData, oldData) => dispatch(UpdateHRDivision(newData, oldData)),
        // deleteHRDivision: (division_id) => dispatch(deleteHRDivision(division_id))

    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
    isAddingDivisionData: state.HrReducer.isAddingDivisionData,
    divisionList: state.HrReducer.divisionList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUom);


import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Card,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../forms/AddDetails/add-details.css";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { setValue } from "../forms/Vouchers/Voucher.action";
import { setModuleKey } from "./account.actions";
import marqueImage from "../../../Images/marque.jpg";
import storeImage from "../../../Images/Store.jpg";
import hrImage from "../../../Images/HR.jpg";
import accImage from "../../../Images/accounts.jpg";
import backUpImage from "../../../Images/Backup.jpg";
const UserMainPage = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [userClick, setUserClick] = useState();
  useEffect(() => {}, []);
  function Logout() {
    window.localStorage.clear();
    window.location.replace("/login");
  }
  return (
    <>
      <Navbar  expand="lg" style={{backgroundColor:'#10719b'}}>
        <Nav className="ms-auto">
          <Nav.Link
            className="m-0"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <span className="no-icon" style={{color:'white'}} onClick={Logout}>
              Log out
            </span>
          </Nav.Link>
        </Nav>
      </Navbar>
      <Container fluid>
        <div className="main" >
          <div>
            <Row >
              {/*  <Col>
                                <Link to="/user">
                                    <Card className="bg-light" style={{ width: '14rem' }}>
                                        <Card.Img variant="top" src="https://i.pinimg.com/originals/f1/d1/eb/f1d1ebce94ec4469419df237662a084f.jpg" />
                                        <Card.Body>
                                            <Button onClick={() => {
                                                props.setModuleKey('UserInventory');
                                            }}>Go to Inventory</Button>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>  */}
              {!isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 1) && (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("UserInventory");
                          window.localStorage.setItem(
                            "module_key",
                            "user_inventory"
                          );
                        }}
                      >
                        <Card.Img 
                        
                          variant="top"
                          src={storeImage}
                        />
                        {/* <Card.Body>
                          <Button>Inventory</Button>
                        </Card.Body> */}
                      </Card>
                    </Link>
                  </Col>
                )}

              {!isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 2) && (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("UserAccounts");
                          window.localStorage.setItem(
                            "module_key",
                            "user_accounts"
                          );
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src={accImage}
                        />
                        {/* <Card.Body>
                          <Button>Account</Button>
                        </Card.Body> */}
                      </Card>
                    </Link>
                  </Col>
                )}
              {!isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 3) && (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("UserHRModules");
                          window.localStorage.setItem("module_key", "user_hr");
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src={hrImage}
                        />
                        {/* <Card.Body>
                          <Button>HR Modules</Button>
                        </Card.Body> */}
                      </Card>
                    </Link>
                  </Col>
                )}

              {/* {
              !isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 5) && 
                (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("Production");
                          window.localStorage.setItem(
                            "module_key",
                            "user_production"
                          );
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src="https://thumbs.dreamstime.com/b/enterprise-resource-planning-erp-concept-businessman-offer-business-management-software-collect-store-manage-interpret-86590078.jpg"
                          height="255"
                        />
                        <Card.Body>
                          <Button>Production</Button>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )} */}
            </Row>

            <Row>
              {/* {
              !isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 6) && 
                (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("BackUpModule");
                          window.localStorage.setItem(
                            "module_key",
                            "user_backUpModule"
                          );
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src={backUpImage}
                        />
                        <Card.Body>
                          <Button>BackUp</Button>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )} */}
              {/* {!isNull(permissions) &&
                permissions.some((p) => p["main_id"] === 4) && (
                  <Col xl="3">
                    <Link to="/user">
                      <Card
                        className="bg-light"
                        style={{ width: "16rem" }}
                        onClick={() => {
                          props.setModuleKey("MarqueeModule");
                          window.localStorage.setItem(
                            "module_key",
                            "user_marqueeModule"
                          );
                        }}
                      >
                        <Card.Img variant="top" src={marqueImage} />
                        {/* <Card.Body>
                          <Button>Marquee</Button>
                        </Card.Body> 
                      </Card>
                    </Link>
                  </Col>
                )} */}
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setModuleKey: (data) => dispatch(setModuleKey(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserMainPage);

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { addHRDepartment, GetHRDepartment, getHRDivision, updateHRDepartment, deleteHRDepartment } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';


const DepartmentList = (props) => {
    const departmentData = isEmpty(props.departmentList) ? [] : props.departmentList.reverse();

  
    useEffect(() => {
        props.GetHRDepartment();
    }, []);
    return (
        <>
            {(props.isFetchingDepartmentData ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <MaterialTable
                                    title="Departments"
                                    columns={[
                                        { title: 'Department Name', field: 'departmentName' },
                                        { title: 'Department Description', field: 'departmentDescription' },
                                        { title: 'Division Name', field: 'division_hr.division_name', editable: 'never' },
                                    ]}
                                    data={departmentData}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        exportButton: true,
                                        exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },

                                    }}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {

                                                        props.updateHRDepartment(newData, oldData)
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteHRDepartment(oldData.departmentId);
                                                }, 600);
                                            }),
                                    }}
                                />
                            </Form>

                        </div>

                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetHRDepartment: () => dispatch(GetHRDepartment()),
        updateHRDepartment: (newData, oldData) => dispatch(updateHRDepartment(newData, oldData)),
        deleteHRDepartment: (departmentId) => dispatch(deleteHRDepartment(departmentId)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    divisionList: state.HrReducer.divisionList,
    isFetchingDepartmentData: state.HrReducer.isFetchingDepartmentData,
    isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
    isAddingDepartmentData: state.HrReducer.isAddingDepartmentData,
    isDeletingDepartmentData: state.HrReducer.isDeletingDepartmentData,
    
    

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentList);
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import Select from 'react-select';
import Swal from 'sweetalert';
import { baseURL, headers } from "../../../../../global/api";
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import { deleteFine, getFine, updateFine } from "./HRDetails.actions";

const FineList = (props) => {

    
  
    useEffect(() => {
       
    }, []);
    return (
        <>
            {(props.isFetchingFineData ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                            <MaterialTable
                                title="Fine & Penalties"
                                columns={[
                                    { title: 'Employee Id', field: 'employee_id', render: rowData => appendNonSignificantZeros(rowData.employee_id) },
                                    { title: 'Employee', field: 'employee.name' },
                                    { title: 'Reason', field: 'reason_fine' },
                                    { title: 'Amount Deducted', field: 'amount_deduct' },
                                    { title: 'Date', field: 'date' },
                                ]}
                                data={
                                    // fineData
                                    (query) =>
                                        new Promise((resolve, reject) => {
                                            let url = `${baseURL}fine_plenties/get_fine_and_plenties?`;
                                            url += "size=" + query.pageSize;
                                            url += "&page=" + (query.page + 1);
                                            url += "&search=" + query.search;
                                            fetch(url, {
                                                method: 'GET',
                                                headers: headers,
                                            })
                                                .then((response) => response.json())
                                                .then((result) => {
                                                    resolve({
                                                        data: result.data,
                                                        page: result.page - 1,
                                                        totalCount: result.total_results,
                                                    });
                                                });
                                        })
                                }
                                options={{
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    exportButton: true,
                                    exportAllData: true,
                                    headerStyle: {
                                        position: 'sticky', top: 0,
                                        color: '#00BBBB',
                                        fontWeight: '550',
                                        onRowAdd: 'none',
                                    },

                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                {
                                                    props.updateFine(newData, oldData)
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                props.deleteFine(oldData.fine_and_plenties_id);
                                                console.log(oldData, 'old')
                                            }, 600);
                                        }),
                                }}
                            />
                            </Form>

                        </div>

                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFine: () => dispatch(getFine()),
        deleteFine: (fine_and_plenties_id) => dispatch(deleteFine(fine_and_plenties_id)),
        updateFine: (newData, oldData) => dispatch(updateFine(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    fine: state.HrReducer.fine,
    isFetchingFineData: state.HrReducer.isFetchingFineData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FineList);
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
//import { getWarehouseData, getCategoryData, getsubCategoryData, categoryByWareHouse, addsubCategoryName, deletesubCategory, updatesubCategory, resetReducer } from "./AddDetails.actions";
import {
  getWarehouseData,
  getCategoryData,
  getsubCategoryData,
  addsubCategoryName,
  deletesubCategory,
  updatesubCategory,
  resetReducer,
  subCategoryByCategory,
  categoryByWareHouse,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";

const AddSubCategory = (props) => {
  const [subCategory, setsubCategoryName] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [wareHouseId, setwareHouseId] = useState();
  const [wareHouseId1, setwareHouseId1] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Sub-Category Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadSubCategory() {
    props.addsubCategoryName(
      {
        subCategoryName: subCategory,
        categoryId: String(categoryId),
        warehouseId: !isEmpty(props.warehouseList)?props.warehouseList[0].wareHouseId:"",
        userId: window.localStorage.getItem("userId"),
      },
      handleVisible,
      handleDangerVisible
    );
    setsubCategoryName("");
    handleVisible();
  }

  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId1(selectedOption.value);
    setwareHouseId(selectedOption.label);
    props.categoryByWareHouse(selectedOption.value);
  };

  const handleChangeCategory = (selectedOption) => {
    setcategoryId(selectedOption.value);
    //setwareHouseId(find(props.warehouseList, x => x.wareHouseId == find(props.categoryList, x => x.categoryId == selectedOption.value).warehouseWareHouseId).wareHouseName)
    //setwareHouseId1(find(props.warehouseList, x => x.wareHouseId == find(props.categoryList, x => x.categoryId == selectedOption.value).warehouseWareHouseId).wareHouseId)
  };
  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const getcategoryList =
    !isEmpty(props.categoryByWareHouseValue) &&
    props.categoryByWareHouseValue.map((x) => {
      let data = { value: x.categoryId, label: x.categoryName };
      return data;
    });

  useEffect(() => {
    props.warehouseDataGetCall();
    props.categoryByWareHouse(1);
      // props.getCategoryData();
    props.resetState();
  }, []);
  return (
    <>
      {props.isFetchingcategoryList ||
      props.isFetchingwareHouseList ||
      props.isAddingSubCategory ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingSubCategory ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Warehouse *</b>
                  </Form.Label>
                  {/* <Select
                    placeholder="Select WareHouse"
                    value={{ label: wareHouseId }}
                    onChange={(e) => handleChangeWareHouse(e)}
                    options={getWareHouseList}
                  /> */}
                  <Form.Control type="text" placeholder="Select WareHouse...." value={!isEmpty(props.warehouseList)?props.warehouseList[0].wareHouseName:""} disabled  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Category * </b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(getcategoryList)}
                    placeholder="Select Category"
                    onChange={(e) => handleChangeCategory(e)}
                    options={getcategoryList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter SubCategory Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter SubCategory Name "
                    onMouseLeave={(e) => {
                      setsubCategoryName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {isEmpty(subCategory) ||
               categoryId==""  
              ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadSubCategory}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    warehouseDataGetCall: () => dispatch(getWarehouseData()),
    getCategoryData: () => dispatch(getCategoryData()),
    addsubCategoryName: (data, handleVisible, handleDangerVisible) =>
      dispatch(addsubCategoryName(data, handleVisible, handleDangerVisible)),
    resetState: () => dispatch(resetReducer()),
    categoryByWareHouse: (data) => dispatch(categoryByWareHouse(data)),
    subCategoryByCategory: (data) => dispatch(subCategoryByCategory(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
  dataAddedSubCategory: state.AddDataReducer.dataAddedSubCategory,
  dataNotAddedSubCategory: state.AddDataReducer.dataNotAddedSubCategory,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
  categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
  isAddingSubCategory: state.AddDataReducer.isAddingSubCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubCategory);

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  getApproveDemand,
  getApprovePurchase,
  purchaseById,
  addIGP,
  getIGP,
  searchIGPReport,
  resetPurchaseReducer,
  purchaseFromDemandCall,
  searchIGPReportByDate,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";

const IGPReportByDate = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };
  const exportPDF = () => {
    props.searchIGPReportByDate(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
      },
      startDate,
      endDate,
      handleVisible
    );
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    props.resetPurchaseReducer();
  }, []);
  return (
    <>
      <Container fluid>
        <div className="main">
          <Row>
            <Col xl="6" lg="6" md="6" sm="6">
              <FormGroup>
                <Form.Label>
                  <b>Start Date *</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
            <Col xl="6" lg="6" md="6" sm="6">
              <FormGroup>
                <Form.Label>
                  <b>End Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
          </Row>
          <div className="sendDiv" style={{marginTop:'20px'}}>
            {startDate == "" ? (
              <Button
                disabled
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Generate Inventory Report
              </Button>
            ) : props.isFetchingSearchIGPListByDate ? (
              <Button className="sendButton">Downloading PDF</Button>
            ) : (
              <Button className="sendButton" onClick={() => exportPDF()}>
                Generate IGP Report
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchIGPReportByDate: (data, startDate, endDate, handleVisible) =>
      dispatch(searchIGPReportByDate(data, startDate, endDate, handleVisible)),
    resetPurchaseReducer: () => dispatch(resetPurchaseReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingSearchIGPListByDate:
    state.AddDataReducer.isFetchingSearchIGPListByDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(IGPReportByDate);

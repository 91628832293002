import API from "../../../../../global/api";
import { get, forEach } from "lodash";
import jsPDF from "jspdf";
import logo from '../../../../../Images/logo.png'

export const requestPostSalaryData = () => {
    return {
        type: "REQUEST_POST_SALARY_DATA",
    };
};
export const successPostSalaryData = (data) => {
    return {
        type: "SUCCESS_POST_SALARY_DATA",
        payload: data,
    };
};
export const errorPostSalaryData = () => {
    return {
        type: "ERROR_POST_SALARY_DATA",
    };
};

export const postSalaryData = (data) => {
    return (dispatch) => {
        dispatch(requestPostSalaryData());
        API.post(`/salary/genrate_salary`, data).then((res) => {
            let getData = res.data;
            console.log('get Company Salary Data', getData);
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 100;
            const marginLeft1 = 500;
            const marginLeft2 = 530;
            const doc = new jsPDF(orientation, unit, size);
            console.log(process.env, 'env')
            doc.setFontSize(8);
            const title = "Salary Report";
            const month = res.data.salary_month;
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const address = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const columns = [['Sr No.\n_________\n\nCode',
                'Employees Name',
                'Designation\n________\n\nD-O-J / D-O-B',
                'Salary & Allowance Detail',
                'Attendance Detail',
                'Earned Salary',
                'O/T Hours\n_________\n\nOverTime Amount',
                'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance',
                'Gross Salary',
                'Deductions Detail\n_________\n\nLate Deduction\n_________\n\nLTA Deduction\n_________\n\nSTA Deduction',
                'Net Payable Salary',
                'Photo',
                'Signature/Thumb'
            ]];
            
            const data = getData.salary_details.map((x) => [
                // 'Sr No.\n_________\n\nCode'
                x.employeeId,
                // 'Employees Name'
                `${x.employee_name} S/O ${x.father_name}`,
                // 'Designation\n________\n\nD-O-J / D-O-B'
                `${x.designation}\n_________\n\n${x.date_of_join} / ${x.date_of_birth}`,
                // 'Salary & Allowance Detail'
                `B/P : ${x.basic_salary}\nM/A : ${x.medicial_allowance}\nO/A : ${x.other_allowance}\nP/A : ${x.petrol_allowance}\n___________\n\n${x.total_allowance}`,
                // 'Attendance Detail'
                `${x.total_days_in_month}-------${x.present_days}`,
                // 'Earned Salary'
                x.earned_salary,
                // 'O/T Hours\n_________\n\nOverTime Amount'
                `${x.overtime_hours}\n\n__________\n\n${x.overtime_amount}`,
                // 'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance'
                x.total_allowance,
                // 'Gross Salary'
                x.gross_salary,
                // 'Deductions Detail\n_________\n\nLTA Deduction\n\nSTA Deduction'
                `_______\n${x.late_hours_amount}\n_______\n${x.long_term_advance}\n_______\n${x.short_term_advance}`,
                // 'Net Payable Salary'
                x.net_payable_salary,])

            // if (cell.raw === 'DESCRIPTION') {//paint.Name header red
            //     cell.styles.fontSize= 105;
            //    cell.styles.textColor = [255,0,0];
            // } else {
            //     cell.styles.textColor = 255;
            //     cell.styles.fontSize = 10;

            // }
            let content = {
                startY: 125,
                startX: 5,
                margin: { left: 5, right: 5 },
                theme: 'grid',
                cellWidth: 'wrap',
                head: columns,
                body: data,
                headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
                bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },
                didDrawCell: (data) => {
                    console.log(data)
                    if (data.section === 'body' && data.column.dataKey === 'photo') {
                        var base64Img = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                        doc.addImage(base64Img, 'JPEG', data.cell.x + 2, data.cell.y + 2, 30, 50)
                    }
                }
            };
            //doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            // doc.text(companyName, 200, 60, 0, 20);
            // doc.text(Dates, 200, 100, 0, 20);
            // doc.text(accountNo, marginLeft, 140, 0, 20);
            // doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.addImage(logo, 'png', 30, 23, 45, 45)
            doc.setFont(undefined, 'bold');
            doc.setFontSize(20);
            doc.text(title, marginLeft1, 40);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(17);
            doc.text(month, marginLeft2, 70);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(15);
            doc.text(companyName, marginLeft, 40)
            doc.setLineWidth(1);
            doc.line(100, 45, 328, 45);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(10);
            doc.text(address, marginLeft, 60)
            // doc.text(TotalCredit, marginLeft, 190);
            // doc.text(TotalDebit, marginLeft, 208);
            // doc.text(TotalBalance, marginLeft, 225);
            doc.autoTable(content);
            
            const headersSigned = [["Total Basic Salary", "Total Allowances", "Total Earned Salary", "Total Gross Salary", "Total net-Payable Salary"]];

            const tableData =[[getData.total_basic_salary, getData.total_allowances, getData.total_earned_salary, getData.total_gross_salary, getData.total_net_payable_salary]]

            // startY: 125,
            // startX: 5,
            // margin: { left: 5, right: 5 },
            // theme: 'grid',
            // cellWidth: 'wrap',
            // head: columns,
            // body: data,
            // headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
            // bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },

            let contentSign = {
                startY: doc.lastAutoTable.finalY + 10,
                startX: 5,
                head: headersSigned,
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
                2: { cellWidth: 130 },
                3: { cellWidth: 130 },
                4: { cellWidth: 130 },
                body: [[{ content: `${getData.total_basic_salary}`, rowSpan: 5 }, { content: `${getData.total_allowances}`, rowSpan: 5 }, { content: `${getData.total_earned_salary}`, rowSpan: 5 }, { content: `${getData.total_gross_salary}`, rowSpan: 5 }, { content: `${getData.total_net_payable_salary}`, rowSpan: 5 }]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 300
                }
            };
            doc.autoTable(contentSign);
        
            doc.save('Company Salary.pdf');
            dispatch(successPostSalaryData(getData));
        }).catch((error) => {
            dispatch(errorPostSalaryData());
        })
    };
};
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Tabs, Tab } from "react-bootstrap";
import { ModalBody, Modal, } from "reactstrap";
import "./add-details.css";
import { addDemand, getAllDemand, getAllInventory, searchDemandReport, getApproveDemand, getWarehouseData, getDemandByBranchId, searchDemandReportByDate } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import DemandReport from "./DemandReport";
import Select from 'react-select';
import Swal from 'sweetalert';
import { getBranch } from "./Branch/BranchDetails.actions";

const DemandPDFByDate = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [productLabel, setProductLabel] = useState();
    const [productId, setProductId] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [branchId, setBranchId] = useState("");
    const [branchLabel, setBranchLabel] = useState();

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }
    console.log(props.allInventory,"ttttttttttttttttt")
    const inventory = props.allInventory.map((x) => {
        let data = {
          value: x.inventoryId,
          label:
            x.productName,
        };
        return data;
      });
    const handleChangeBranch = (selectedOption) => {
        setBranchId(selectedOption.value);
        setBranchLabel(selectedOption.label);
    
      };
      const handleChangeProduct = (selectedOption) => {
        setProductId(selectedOption.value);
        setProductLabel(selectedOption.label);

      };
    
      const getBranchList = props.branchData.map((x) => {
        let data = { value: x.branch_id, label: x.branch_name };
        return data;
      });
    const exportPDF = () => {
        props.searchDemandReport({
            inventoryId:productId,
            branchId:branchId,
            startedDate:isUndefined(startDate)? "":startDate,
            endDate: isUndefined(endDate)?"":endDate
        }, startDate, endDate, handleVisible);
        setStartDate("");
        setEndDate("");
    }

    useEffect(() => {
        props.GetBranch()
        props.getAllInventory({
            startedDate: "",
            endDate: "",
            wareHouseId: "",
            subCategoryId: "",
            categoryId: "",
          });
    }, []);
    return (
        <>
            {props.isFetchingBranch ||props.isFetchingInventoryAll? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchDemandList ? 'Downloading PDF...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                         
                        </Row>
                        <Row>
                        <Col xl="6" lg="6" md="6" sm="6">
                  <Form.Group>
                    <Form.Label>
                      <b> Select Branch * </b>
                    </Form.Label>
                    <Select
                      value={{ label: branchLabel }}
                      placeholder="Select Branch..."
                      onChange={(e) => handleChangeBranch(e)}
                      options={getBranchList}
                    />
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory *</b>
                        </Form.Label>
                        <Select
                          isDisabled={isEmpty(inventory)}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          value={{ label: productLabel }}
                          onChange={(e) => handleChangeProduct(e)}
                          options={inventory}
                        />
                      </Form.Group>
                    </Col>
                        </Row>
                        <div className="sendDiv" style={{marginTop:'20px'}}>
                            {isUndefined(startDate)&&branchId==""&&productId=="" ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Demand Report</Button> : props.isFetchingSearchDemandList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Demand Report</Button>}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllDemand: () => dispatch(getAllDemand()),
        GetBranch: () => dispatch(getBranch()),
        getAllInventory: (data) => dispatch(getAllInventory(data)),
        searchDemandReport: (data, startDate, endDate, handleVisible) => dispatch(searchDemandReportByDate(data, startDate, endDate, handleVisible)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        getWarehouseData: () => dispatch(getWarehouseData()),
        getDemandByBranchId:(id)=>dispatch(getDemandByBranchId(id))
    };
};
const mapStateToProps = (state, ownProps) => ({

    allInventory: state.AddDataReducer.allInventory,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    isFetchingBranch:state.BranchReducer.isFetchingBranch,
    branchData:state.BranchReducer.branchData,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandPDFByDate);
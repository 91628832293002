const initialState = {

    // increment: 0,
    // decrement: 0,
    // activeStatus: true,
    // isFetchingIncDec:false,

    isAddingIncDec: false,

    isFetchingIncDecById: false,
    isFetchingIncDec: false,
    IncDecDataGet: [],
    isDeletingIncDec: false,

    // showAttendanceAdd: false,

    isAddingAttendance: false,
    attendanceData: [],

    isFetchingAttendanceData: false,

    isAddingAttendanceFile: false,
    adjustmentData: [],
    isAddingAdjustment: false,
    isGettingAdjustment: false,


};

console.log(initialState.attendanceData);

const SalaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_ATTENDANCE_FILE":
            return {
                ...state, isAddingAttendance: true,
                // showAttendanceAdd: false 
            };

        case "SUCCESS_ADD_ATTENDANCE_FILE":
            return {
                ...state, isAddingAttendance: false,
                // showAttendanceAdd: false,
                //  attendanceData: action.payload
            };

        case "ERROR_ADD_ATTENDANCE_FILE":
            return {
                ...state, isAddingAttendance: false,
                // showAttendanceAdd: false 
            };

        case "REQUEST_GET_ATTENDANCE":
            return {
                ...state, isFetchingAttendanceData: true,
                // showAttendanceAdd: false 
            };

        case "SUCCESS_GET_ATTENDANCE":
            console.log("Yo! getAttendance", action.payload.result)
            return {
                ...state, isFetchingAttendanceData: false,
                // showAttendanceAdd: false,
                attendanceData: action.payload.result
            };

        case "ERROR_GET_ATTENDANCE":
            return {
                ...state, isFetchingAttendanceData: false,
                // showAttendanceAdd: false 
            };
        case "REQUEST_ADD_ATTENDANCE_FILE":
            return {
                ...state, isAddingAttendanceFile: true,
                // showAttendanceAdd: false 
            };

        case "SUCCESS_ADD_ATTENDANCE":
            return {
                ...state, isAddingAttendanceFile: false,
                // showAttendanceAdd: false,
                //  attendanceData: action.payload
            };

        case "ERROR_ADD_ATTENDANCE":
            return {
                ...state, isAddingAttendanceFile: false,
                // showAttendanceAdd: false 
            };
        //adjusmet
        case "REQUEST_ADD_ADJUSTMENT":
            return {
                ...state, isAddingAdjustment: true,
                // showAttendanceAdd: false 
            };

        case "SUCCESS_ADD_ADJUSTMENT":
            return {
                ...state, isAddingAdjustment: false,
                // showAttendanceAdd: false,
                // adjustmentData: action.payload
            };

        case "ERROR_ADD_ADJUSTMENT":
            return {
                ...state, isAddingAdjustment: false,
                // showAttendanceAdd: false 
            };

        case "REQUEST_GET_ADJUSTMENT":
            return {
                ...state, isGettingAdjustment: true,
                // showAttendanceAdd: false 
            };

        case "SUCCESS_GET_ADJUSTMENT":
            console.log(action.payload.att_data, "adjustmentData")
            return {
                ...state, isGettingAdjustment: false,
                // showAttendanceAdd: false,
                adjustmentData: action.payload.att_data
            };

        case "ERROR_GET_ADJUSTMENT":
            return {
                ...state, isGettingAdjustment: false,
                // showAttendanceAdd: false 
            };


        case "REQUEST_ADD_SALARY_INCREMENT":
            return {
                ...state, isAddingIncDec: true
            }
        case "SUCCESS_ADD_SALARY_INCREMENT":
            return {
                ...state, isAddingIncDec: false
            }
        case "ERROR_ADD_SALARY_INCREMENT":
            return {
                ...state, isAddingIncDec: false
            }

        case "REQUEST_GET_SALARY_INCREMENT":
            return {
                ...state, isFetchingIncDec: true
            }
        case "SUCCESS_GET_SALARY_INCREMENT":
            return {
                ...state, isFetchingIncDec: false, IncDecDataGet: action.payload.result
            }
        case "ERROR_GET_SALARY_INCREMENT":
            return {
                ...state, isFetchingIncDec: false
            }
        case "REQUEST_GET_SALARY_INCREMENT_BY_ID":
            return {
                ...state, isFetchingIncDecById: true
            }
        case "SUCCESS_GET_SALARY_INCREMENT_BY_ID":
            return {
                ...state, isFetchingIncDecById: false, IncDecDataGet: action.payload.result
            }
        case "ERROR_GET_SALARY_INCREMENT_BY_ID":
            return {
                ...state, isFetchingIncDecById: false
            }

        case "REQUEST_DELETE_SALARY_INCREMENT":
            return {
                ...state, isDeletingIncDec: true
            }
        case "SUCCESS_DELETE_SALARY_INCREMENT":
            return {
                ...state, isDeletingIncDec: false
            }
        case "ERROR_DELETE_SALARY_INCREMENT":
            return {
                ...state, isDeletingIncDec: false
            }
        default:
            return state;
    }



};
export default SalaryReducer;

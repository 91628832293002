import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter } from "lodash";
import { getLTAccount, getEmployeeData, addLTAccount, getEmployeeDataWithStatus } from "./HRDetails.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from 'sweetalert';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const LTAdvance = (props) => {

  const [accountNo, setaccountNo] = useState();
  const [selectedAccount, setSelectedAccount] = useState('');
  const [cashAccountTitle, setCashAccountTitle] = useState();
  const [cashAccountTitleKey, setCashAccountTitleKey] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [employeeId, setEmployeeId] = useState('');
  const [descriptionLT, setLTDescription] = useState();
  const [amount, setAmount] = useState('');
  const [deduction, setDeduction] = useState('');
  const [accType, setAccType] = useState(1);
  const [date, setDate] = useState('');
  const [voucher, setVoucher] = useState();
  const [datestate, setDatestate] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Operation Failed: Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  }
  console.log(datestate, "sssssssssssssssss");
  // const [values, setValues] = useState({
  //   voucherDescription: "",
  //   voucherAmount: "",
  //   PONo: "",
  //   billNo: "",
  //   accountTittle: "",
  //   // payTo: "",
  //   levelFiveId: "",
  //   voucherDate: "",
  //   accountType: "2",
  //   account: "",
  // });

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];

  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })

  // const inputEvent = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   setDatestate(event.target.value);
  // };


  // const cashAccountNo =
  //   !isEmpty(props.level5List) &&
  //   props.level5List.map((x) => {
  //     let data = {
  //       value: x.levelfiveData.levelFiveId,
  //       label:
  //         x.levelfiveData.allLevelKey + " /" + x.levelfiveData.levelFiveName,
  //     };
  //     return data;
  //   });

  // const cashAccountNoTop =
  //   !isEmpty(props.level5List) &&
  //   props.level5List
  //     .filter((x) => x.levelfiveData.levelFourId == level4Id)
  //     .map((y) => {
  //       let data = {
  //         value: y.levelfiveData.levelFiveId,
  //         label:
  //           y.levelfiveData.allLevelKey + " /" + y.levelfiveData.levelFiveName,
  //       };
  //       return data;
  //     });

  // const handleChangeAccountNo = (selectedOption) => {
  //   setaccountNo(selectedOption.value);
  //   setCashAccountTitle(
  //     find(
  //       props.ltAccount,
  //       (x) => x.levelFiveId == selectedOption.label,
  //     ).levelFiveName,
  //     console.log(cashAccountTitle,'accccc')
  //   );

  //   setCashAccountTitleKey(
  //     find(
  //       props.ltAccount,
  //       (x) => x.levelFiveId == selectedOption.value
  //     ).levelFiveId
  //   );
  //   // setSelectedAccount(selectedOption)
  // };
  const clearStates = () => {
    setEmployeeId('');
    setAmount('');
    setDeduction('');
    setDate('');
  }

  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label: x.levelFourData.allLevlKey + "/ " + x.levelFourData.levelFourName,
      };
      return data;
    });


  const cash = props.ltAccount;
  console.log(cash, "cashhhhhhhhhhhhhhhhhhh");

  // const addPayment = () => {
  //   let newVal = values;
  //   if (!isEmpty(datestate)) newVal.voucherDate = datestate;
  //   props.submitCashPayment(newVal);
  //   setValues({
  //     voucherDescription: "",
  //     voucherAmount: "",
  //     PONo: "",
  //     billNo: "",
  //     accountTittle: "",
  //     // payTo: "",
  //     levelFiveId: "",
  //     voucherDate: "",
  //     accountType: "2",
  //     account: "",
  //   });
  //   setCashAccountTitle("");
  //   setLevel4Lablel("")
  // };
  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    console.log(selectedOption.value, selectedOption.label, 'dddd')
  };
  useEffect(() => {
    //props.getLTAccount();
    // props.getEmployeeData();
    props.getEmployeeDataWithStatus(1);
  }, []);
  const SaveLTAccount = () => {
    props.addLTAccount({
      employee_id: employeeId,
      long_term_data_acc_type: accType,
      long_term_data_description: descriptionLT,
      long_term_data_amount: amount,
      long_term_data_deduction_value: deduction,
      long_term_data_date: date,
    }, handleVisible, handleDangerVisible);
    //props.resetFunction();

  };
  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Employee</Form.Label>
                      <Select
                        //value={name}
                        placeholder="Select Employee..."
                        onChange={handleChangeEmployee}
                        options={getEmployeeList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Method</Form.Label>
                      <Select
                        placeholder="Select Method..."
                        onChange={(selectedOption) => { setAccType(selectedOption.label)}}
                        options={[{value: "Cash", label: "Cash"}, {value: "Bank", label: "Bank"}]}
                      />
                      </Form.Group>
                  </Col>

                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        id="descriptionID"
                        name="voucherDescription"
                        //value={values.voucherDescription}
                        onBlur={(e) => setLTDescription((e.target.value).toUpperCase())}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        //value={values.voucherAmount}
                        onBlur={(e) => setAmount(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Deduction Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Deduction..."
                        id="billno"
                        name="billNo"
                        // min="0"
                        // max="99"
                        // isInvalid={deduction < 2 || deduction > 99}
                        //value={values.billNo}
                        onBlur={(e) => setDeduction(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12" sm="12" className="text-center">
                  {((employeeId === '') || (amount === '') || (deduction === '') || (date === '')) ? <Button className="sendButtonVoucher" disabled style={{backgroundColor:'black'}} size="large" onClick={SaveLTAccount}>Save</Button> : <Button className="sendButtonVoucher" onClick={SaveLTAccount}>Save</Button>}
                </Col>
              </Row>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLTAccount: () => dispatch(getLTAccount()),
    getEmployeeData: () => dispatch(getEmployeeData()),
    addLTAccount: (data, handleVisible, handleDangerVisible) => dispatch(addLTAccount(data, handleVisible, handleDangerVisible)),
    // addCashPayment: (data, handleVisible, handleDangerVisible) => dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    // submitCashPayment: (data) => dispatch(submitCashPayment(data)),
    // deleteCashPayment: (id) => dispatch(deleteCashPayment(id)),
    // resetFunction: () => dispatch(resetFunction()),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  ltAccount: state.HrReducer.ltAccount,
  isFetchingLTAccount: state.accountsLevelReducer.isFetchingLTAccount,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LTAdvance);

import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";

  export const requestGetUOM = () => {
    return {
        type: "REQUEST_GET_UOM"
    }
}

export const successGetUOM = (data) => {
    return {
        type: "SUCCESS_GET_UOM",
        payload: data,
    }
}

export const errorGetUOM = () => {
    return {
        type: "ERROR_GET_UOM"
    }
}
export const getUom = () => {
    return (dispatch) => {
        dispatch(requestGetUOM())
        API.get('/uom/').then((res) => {
            dispatch(successGetUOM(res.data))
        }).catch((error) => {
            dispatch(errorGetUOM())
        })
    }
}
//add uom
export const requestAddUom = () => {
    return {
        type: "REQUEST_ADD_UOM",
    };
};
export const successAddUom = (data) => {
    return {
        type: "SUCCESS_ADD_UOM",
        payload: data,
    };
};
export const errorAddUom = () => {
    return {
        type: "ERROR_ADD_UOM",
    };
};
export const addUom= (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddUom());
        API.post(`/uom/add_uom`, data).then((res) => {
            
            let getData = res.data;
             dispatch(getUom());
            dispatch(successAddUom(getData));
            handleVisible();
        }).catch((error) => {
            
            dispatch(errorAddUom());
            handleDangerVisible();
        })
    };
};

import React, { useEffect, useState, forwardRef } from "react";
import { Row, Col, Form, Container, Button, Modal, Alert } from "react-bootstrap";
import "./add-details.css";
import { getWarehouseData, deleteWareHouse, updateWareHouse } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';

const WareHouseTableList = (props) => {
    const wareHouseData = props.warehouseList;

    /*const [state, setState] = React.useState({
        columns: [
            { title: 'WareHouse Name', field: 'warehouse.wareHouseName', editable: 'never' },
            { title: 'Category Code', field: 'key', editable: 'never' },
            { title: 'Category Name', field: 'categoryName' },
        ],
    });*/
    useEffect(() => {
        props.getWarehouseData();
    }, []);

    return (
        <>

            {props.isFetchingwareHouseList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                    <MaterialTable
                  title="Ware House Details"
                  columns={[
                    { title: 'WareHouse Key', field: 'whKey', editable: 'never' },
                    { title: 'WareHouse Name', field: 'wareHouseName' },
                  ]}
                  data={wareHouseData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 10,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions: [10, 20, 50],    // rows selection options
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            props.updateWareHouse(newData, oldData)
                          }
                        }, 600);
                      }),
                    // onRowDelete: (oldData) =>
                    //   new Promise((resolve) => {
                    //     setTimeout(() => {
                    //       resolve();
                    //       props.deleteWareHouse(oldData.wareHouseId);
                    //     }, 600);
                    //   }),
                  }}
                />

                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouseData: () => dispatch(getWarehouseData()),
        deleteWareHouse: (wareHouseId) => dispatch(deleteWareHouse(wareHouseId)),
        updateWareHouse: (newData, oldData) => dispatch(updateWareHouse(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    dataAddedWarehouse: state.AddDataReducer.dataAddedWarehouse,
    dataNotAddedWarehouse: state.AddDataReducer.dataNotAddedWarehouse,
    isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WareHouseTableList);
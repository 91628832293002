import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import profileImage from "../../../../../Images/profileImage.jpg";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { addShiftControl, getShiftControl, deleteShiftControl, updateShiftControl, getEmployeeData } from "./HRDetails.actions";
import Swal from 'sweetalert';
import MaterialTable from "material-table";

const ShiftControl = (props) => {

    const [shiftId, setShiftId] = useState();
    const [shiftName, setShiftName] = useState();
    const [arrivalIn, setArrivalIn] = useState();
    const [breakIn, setBreakIn] = useState();
    const [breakOut, setBreakOut] = useState();
    const [departure, setDeparture] = useState();
    const [lateDeparture, setlateDeparture] = useState();
    const [jummahIn, setJummahIn] = useState();
    const [jummahBreakOut, setJummahBreakOut] = useState();
    const [jummahBreakIn, setJummahBreakIn] = useState();
    const [departureJummah, setDepartureJummah] = useState();
    const [workHourNormalDays, setWorkHourNormalDays] = useState();
    const [workHourJummah, setWorkHourJummah] = useState();
    const [lateTimeIn, setLateTimeIn] = useState();
    const [lateTimeAllowJummah, setLateTimeInJummah] = useState();
    const [earlyLeaveOutBreak, setEarlyLeaveOutBreak] = useState();
    const [earlyLeaveInBreak, setEarlyLeaveInBreak] = useState();
    const [earlyBreakOutJummah, setEarlyLeaveOutJummah] = useState();
    const [earlyBreakInJummah, setEarlyBreakInJummah] = useState();
    const [lateDepartureJummah, setLateDepartureJummah] = useState();
    const [shiftMng, setShiftMng] = useState(1);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    /*const [state, setState] = React.useState({
        columns: [
            { title: 'Shift Name', field: 'shift_name' },
            { title: 'Arrival In', field: 'arrival_in' },
            { title: 'Break Out', field: 'break_out' },
            { title: 'Break In', field: 'break_in' },
            { title: 'Departure', field: 'departure' },
            { title: 'Allowed Late Arrival In', field: 'allow_late_arrival_in' },
            { title: 'Allowed Early Break Out', field: 'allow_early_break_out' },
            { title: 'Allowed Early Break In', field: 'allow_early_break_in' },
            { title: 'Allowed Late Departure', field: 'allow_late_departure' },
            { title: 'Jummah Arrival In', field: 'jummah_arrivel_in' },
            { title: 'Jummah Break Out', field: 'break_jummah_out' },
            { title: 'Jummah Break In', field: 'break_jummah_in' },
            { title: 'Jummah Departure', field: 'jummah_departure' },
            { title: 'Jummah Allowed Late Arrival In', field: 'jummah_allow_late_arrival_in' },
            { title: 'Jummah Allowed Early Break Out', field: 'jummah_allow_early_break_out' },
            { title: 'Jummah Allowed Early Break In', field: 'jummah_allow_early_break_in' },
            { title: 'Jummah Allowed Late Departure', field: 'jummah_allow_late_departure' },
        ],
    });*/
    const getShiftList = [

        { value: 1, label: "Two Times" },
        { value: 2, label: "Four Times" },
    ];
    const handleChangeShift = (selectedOption) => {
        setShiftMng(selectedOption.value);
    };
    const shiftData = props.shiftControl;
    console.log(shiftData, 'shift')
    const SaveShiftControl = () => {
        props.addShiftControl({
            shift_name: shiftName,
            arrival_in: arrivalIn,
            break_out: breakOut,
            break_in: breakIn,
            departure: departure,
            allow_late_arrival_in: lateTimeIn,
            allow_early_break_out: earlyLeaveOutBreak,
            allow_early_break_in: earlyLeaveInBreak,
            allow_late_departure: lateDeparture,
            jummah_arrivel_in: jummahIn,
            break_jummah_out: jummahBreakOut,
            break_jummah_in: jummahBreakIn,
            working_hour_normal_days: workHourNormalDays,
            working_hour_jumah: workHourJummah,
            jummah_departure: departureJummah,
            jummah_allow_late_arrival_in: lateTimeAllowJummah,
            jummah_allow_early_break_out: earlyBreakOutJummah,
            jummah_allow_early_break_in: earlyBreakInJummah,
            jummah_allow_late_departure: lateDepartureJummah,
            shift_mangement: shiftMng,
        }, handleVisible, handleDangerVisible,);
    };

    const employeeDataList = (!isEmpty(props.employeeList)) ? props.employeeList : [];
    const shiftIsAllocated = (shift_control_id) => {
        if ((!isEmpty(employeeDataList))) {
            for (let index = 0; index < employeeDataList.length; index++) {
                if (!isEmpty(employeeDataList[index].salaries)) {
                    if (employeeDataList[index].salaries[0].salary_creation_based === "Shift") {
                        if (employeeDataList[index].salaries[0].shift_id === shift_control_id) {
                            console.log("employeeWithThisShift", employeeDataList[index] )
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    // const getDepartmentNameList = !isEmpty(props.departmentList) && props.departmentList.map((x) => { let data = { value: x.departmentId, label: x.departmentName }; return data })
    // const handleChangeDepartment = (selectedOption) => {
    //     setDepartment(selectedOption.value);
    // };
    // const getDesignationNameList = !isEmpty(props.designationList) && props.designationList.map((x) => { let data = { value: x.designationId, label: x.designationName }; return data })
    // const handleChangeDesignation = (selectedOption) => {
    //     setDesignation(selectedOption.value);
    // };
    const clearStates = () => {

        setShiftName();
        setArrivalIn();
        setBreakIn();
        setBreakOut();
        setDeparture();
        setlateDeparture();
        setJummahIn();
        setJummahBreakOut();
        setJummahBreakIn();
        setDepartureJummah();
        // setWorkHourNormalDays
        // setWorkHourJummah
        setLateTimeIn();
        setLateTimeInJummah();
        setEarlyLeaveOutBreak();
        setEarlyLeaveInBreak();
        setEarlyLeaveOutJummah();
        setEarlyBreakInJummah();
        setLateDepartureJummah();
        setShiftMng();
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        SaveShiftControl();
        event.target.reset();
    };
    const handleVisible = () => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.getEmployeeData();
        
    }, []);

    return (
        <>
            <Container fluid>
                {props.isFetchingShiftControlData || props.isAddingShiftControl ? <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingShiftControlData ? 'Fetching Shift...' : 'Adding Shift...'}

                    </Button>
                </div> :
                    <div className="mainHr">
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Shift Name :</b></Form.Label>
                                    <Form.Control type="text" id="shiftName" name="ShiftName" placeholder="Shift Name...." onBlur={(e) => setShiftName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Shift Management:</b></Form.Label>
                                    <Select
                                        placeholder="Shift Management..."
                                        defaultValue={{label: (shiftMng===1) && "Two Times"}}
                                        onChange={handleChangeShift}
                                        options={getShiftList}
                                    //        value={shiftMng}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {shiftMng == 2 ?
                            <div className="fourTimes">
                                <Row>
                                    <h4>Normal Day Report : </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In :</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setArrivalIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break Out :</b></Form.Label>
                                            <Form.Control type="time" id="breakOut" name="BreakOut" placeholder="Break Out...." onBlur={(e) => setBreakOut(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break In :</b></Form.Label>
                                            <Form.Control type="time" id="breakIn" name="BreakIn" placeholder="Break In...." onBlur={(e) => setBreakIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure:</b></Form.Label>
                                            <Form.Control type="time" id="departure" name="LateTimeInMinutes" placeholder="Departure..." onBlur={(e) => setDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Break Out:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyLeaveOutBreak(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Break In:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyLeaveInBreak(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setlateDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4>Jummah Day Report : </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In :</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setJummahIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break Out For Jummah:</b></Form.Label>
                                            <Form.Control type="time" id="breakOut" name="BreakOut" placeholder="Break Out...." onBlur={(e) => setJummahBreakOut(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break In After Jummah :</b></Form.Label>
                                            <Form.Control type="time" id="breakIn" name="BreakIn" placeholder="Break In...." onBlur={(e) => setJummahBreakIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Late Time In..." onBlur={(e) => setDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Break Out For Jummah:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyLeaveOutJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Break In After Jummah:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyBreakInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setLateDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div> :
                            <div className="twoTimes">
                                <h4>Normal Day Report : </h4>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In :</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setArrivalIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure:</b></Form.Label>
                                            <Form.Control type="time" id="departure" name="LateTimeInMinutes" placeholder="Departure..." onBlur={(e) => setDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setlateDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4>Jummah Day Report : </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In :</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setJummahIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Late Time In..." onBlur={(e) => setDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure:</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setLateDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <div className="sendDiv">
                            {console.log("arrivalIn",                                     (
                                        (shiftMng === 1) & (
                                            (arrivalIn === undefined) ||
                                            (departure === undefined) ||

                                            (lateTimeIn === undefined) ||
                                            (lateDeparture === undefined) ||

                                            (jummahIn === undefined) ||
                                            (departureJummah === undefined) ||
                                            
                                            (lateTimeAllowJummah === undefined) ||
                                            (lateDepartureJummah === undefined)
                                        ))
                                    || (
                                        (shiftMng === 2) & (
                                            (arrivalIn === undefined) ||
                                            (breakOut === undefined) ||
                                            (breakIn === undefined) ||
                                            (departure === undefined) ||

                                            (lateTimeIn === undefined) ||
                                            (earlyLeaveOutBreak === undefined) ||
                                            (earlyLeaveInBreak === undefined) ||
                                            (lateDeparture === undefined) ||

                                            (jummahIn === undefined) ||
                                            (jummahBreakOut === undefined) ||
                                            (jummahBreakIn === undefined) ||
                                            (departureJummah === undefined) ||

                                            (lateTimeAllowJummah === undefined) ||
                                            (earlyBreakOutJummah === undefined) ||
                                            (earlyBreakInJummah === undefined) ||
                                            (lateDepartureJummah === undefined)

                                        ))
)}
{      
                                    (
                                        (shiftMng === 1) & (
                                            (arrivalIn === undefined) ||
                                            (departure === undefined) ||

                                            (lateTimeIn === undefined) ||
                                            (lateDeparture === undefined) ||

                                            (jummahIn === undefined) ||
                                            (departureJummah === undefined) ||

                                            (lateTimeAllowJummah === undefined) ||
                                            (lateDepartureJummah === undefined)
                                        ))
                                    || (
                                        (shiftMng === 2) & (
                                            (arrivalIn === undefined) ||
                                            (breakOut === undefined) ||
                                            (breakIn === undefined) ||
                                            (departure === undefined) ||

                                            (lateTimeIn === undefined) ||
                                            (earlyLeaveOutBreak === undefined) ||
                                            (earlyLeaveInBreak === undefined) ||
                                            (lateDeparture === undefined) ||

                                            (jummahIn === undefined) ||
                                            (jummahBreakOut === undefined) ||
                                            (jummahBreakIn === undefined) ||
                                            (departureJummah === undefined) ||

                                            (lateTimeAllowJummah === undefined) ||
                                            (earlyBreakOutJummah === undefined) ||
                                            (earlyBreakInJummah === undefined) ||
                                            (lateDepartureJummah === undefined)

                                        ))
                                    ?
                            <Button className="sendButton" style={{backgroundColor:"black"}} onClick={SaveShiftControl}
                                disabled
                            >Save</Button>:
                             <Button className="sendButton" onClick={SaveShiftControl}
                            >Save</Button>}
                        </div>
                    </div>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addShiftControl: (data, handleVisible, handleDangerVisible) => dispatch(addShiftControl(data, handleVisible, handleDangerVisible)),
        getEmployeeData: () => dispatch(getEmployeeData()),

    };
};
const mapStateToProps = (state, ownProps) => ({
    shiftControl: state.HrReducer.shiftControl,
    isAddingShiftControl: state.HrReducer.isAddingShiftControl,

    employeeList: state.HrReducer.employeeList,
    isFetchingShiftControlData: state.HrReducer.isFetchingShiftControlData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftControl);
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import 'jspdf-autotable';
import { filter } from "lodash";
import Modal from "react-bootstrap/Modal";
import SendIcon from '@mui/icons-material/Send';
import Select from 'react-select';
import Swal from 'sweetalert';
import { UpdateBranch, deleteBranch, getBranch } from "./BranchDetails.actions";


const BranchList = (props) => {
    useEffect(() => {
        props.GetBranch();
    }, []);
    return (
        <>
            {(props.isFetchingBranch ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <MaterialTable
                                    title="Branch Detail"
                                    columns={[
                                        // { title: 'Branch Date', field: 'invoice_date' },    
                                        { title: 'Branch Name', field: 'branch_name' },
                                        { title: 'Branch Description', field: 'branch_desc' },
                                        // { title: 'Branch Status', field: 'invoice_status' },
                                    ]}
                                    data={props.branchData}
                                   
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        // exportButton: true,
                                        // exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },
                                    }}
                                    editable={{
                                      onRowUpdate: (newData, oldData) =>
                                          new Promise((resolve) => {
                                              setTimeout(() => {
                                                  resolve();
                                                  {
                                                      // console.log('newData', newData),
                                                      props.UpdateBranch(newData, oldData)
                                                  }
                                              }, 600);
                                          }),
                                      onRowDelete: (oldData) =>
                                          new Promise((resolve) => {
                                              setTimeout(() => {
                                                  resolve();
                                                  props.deleteBranch(oldData.branch_id);
                                                  console.log(oldData, 'old')
                                              }, 600);
                                          }),
                                  }}

                                />
                            </Form>

                        </div>
                    
                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetBranch: () => dispatch(getBranch()),
        UpdateBranch: (newData, oldData) => dispatch(UpdateBranch(newData, oldData)),
        deleteBranch: (Branch_id) => dispatch(deleteBranch(Branch_id))
    };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingBranch:state.BranchReducer.isFetchingBranch,
  branchData:state.BranchReducer.branchData

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
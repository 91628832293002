import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import {
  getApproveDemand,
  purchaseById,
  addIGP,
  resetPurchaseReducer,
  purchaseFromDemandCallNew,
  getApprovePurchase,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getCompletePOByVendorId, getVendorData } from "./Vendor/Vendor.action";

const AddIGP = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: "",
      inventoryLabel: "",
      uom:""
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [poId, setPoId] = useState();
  const [purchaseName, setPurchaseName] = useState();
  const [gatePass, setGatePass] = useState();
  const [orders, setOrders] = useState();
  const [ordersLabel, setOrdersLabel] = useState();
  const [receiverName, setReceiverName] = useState();
  const [stockUpdate, setStockUpdate] = useState();
  const [igpDescription, setIGPDescription] = useState();
  // const [demandNameValue, setDemandNameValue] = useState();
  const [PoName, setPoName] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [IGPNameValue, setIGPNameValue] = useState();
  const [IGPNameLabel, setIGPNameLabel] = useState();
  const [data, setData] = useState([]);
  const [IGPManualNameValue, setIGPManualNameValue] = useState();
  const [IGPManualNameLabel, setIGPManualNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "IGP Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };


  function uploadPurchase() {
    props.addIGP(
      {
        gatePass: gatePass,
        receivername: receiverName,
        orderType: orders,
        stockUpdate: stockUpdate,
        // demandId: poId,
        purchaseOrderId: purchaseNameValue,
        igpDescription: igpDescription,
        data:
          orders === "Full"
            ? props.purchaseListById[0].purchase_data.map((x) => {
                let data = {
                  inventoryId: x.inventoryId,
                  productQuantity: x.remainingIgp,
                };
                return data;
              })
            : inputField,
      },
      handleVisible,
      handleDangerVisible
    );
    setPoName("");
    setPurchaseNameLabel("");
    setGatePass("");
    setReceiverName("");
    setIGPDescription("");
    setOrdersLabel("");
    setStockUpdate("");
    setPoId();
    setPurchaseName();
    setOrders();
    setStockUpdate();
    setInputField([
      {
        inventoryId: "",
        productQuantityCount: "",
        productQuantity: "",
        inventoryLabel: "",
        uom:"",
      },
    ]);
  }


  const inventory = isEmpty(props.purchaseListById)
    ? []
    : props.purchaseListById[0].purchase_data.map((x) => {
        let data = {
          value: x.inventory.inventoryId,
          label:
            x.inventory.warehouse.whKey +
            "-" +
            x.inventory.category.cateKey +
            "-" +
            "-" +
            x.inventory.invKey +
            " " +
            x.inventory.productName,
        };
        return data;
      });

  const getOrderType = [
    { value: "Full", label: "Full" },
    { value: "Partial", label: "Partial" },
  ];
  const getVendorList = props.vendorDataList.map((x) => {
    let data = { value: x.vendorId, label: x.vendorId + " / " + x.vendorName ,poDate:x.lastPODate};
    return data;
  });
  const handleChangeVendor = (selectedOption) => {
    // props.getProductByDemand(selectedOption.value);
    setPoId(selectedOption.value);
    setPoName(selectedOption.label);
    props.getCompletePOByVendorId(selectedOption.value)
  };
  const getPurchaseListFilter =
  !isEmpty(props.poByVendorIdList) &&
  props.poByVendorIdList.data.map((x) => {
    let data = {
      value: x.purchaseOrderId,
      label: x.purchaseOrderId + " / " + moment(x.createdAt).format("DD-MM-YYYY"),
    };
    return data;
  });
  const handleChangeGetPurchase = (selectedOption) => {
    setPurchaseNameValue(selectedOption.value);
    setPurchaseNameLabel(selectedOption.label);
    props.purchaseById({ purchaseOrderId: selectedOption.value });
  };


  const handleChangeOrderType = (selectedOption) => {
    setOrders(selectedOption.value);
    setOrdersLabel(selectedOption.label);
    // setData( selectedOption.value=="Full"
    // ? props.purchaseListById[0].purchase_data.map((x) => {
    //     let data = {
    //       inventoryId: x.inventoryId,
    //       productQuantity: x.remainingIgp,
    //     };
    //     return data;
    //   }):inputField)
  };

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.purchaseListById, (x) =>
      x.purchase_data.map((a) => {
        return a.inventory.inventoryId == e.value;
      })
    );
    let y = z.purchase_data.find((x) => x.inventoryId == item);
    if (Number(e.target.value) <= Number(y.remainingIgp)) {
      const { name, value } = e;
      const list = [...inputField];
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname")
        list[index]["productQuantity"] = Number(y.remainingIgp);
      if (type == "intname") setMaximumQuantity(Number(y.remainingIgp));
      if (type == "intquantity")
        list[index]["productQuantity"] =
          e.target.value < 0
            ? alert("cannot be added less than 0")
            : e.target.value;

      // var holder = {};
      // list.forEach(function (d) {
      //     if (holder.hasOwnProperty(d.inventoryId)) {
      //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
      //     } else {
      //         holder[d.inventoryId] = Number(d.productQuantity);
      //     }
      // });
      // var obj2 = [];
      // for (var prop in holder) {
      //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
      // }
      setInputField(list);
    } else {
      alert("value out of range");
    }
  };
  const handleChange = (e, index, type) => {
    
    let z = find(props.purchaseListById, (x) =>
      x.purchase_data.map((a) => {
        return a.inventory.inventoryId == e.value;
      })
    );
    console.log(e,z,"zzzzzzzzzzzz")
    let y = z.purchase_data.find((x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(y.remainingIgp);
      if (type == "intname")
      list[index]["uom"] = z.uom;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    // map((x) => { return Number(x.productQuantity) });
    if (type == "intname") setMaximumQuantity(Number(y.remainingIgp));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    // var holder = {};
    // list.forEach(function (d) {
    //     if (holder.hasOwnProperty(d.inventoryId)) {
    //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
    //     } else {
    //         holder[d.inventoryId] = Number(d.productQuantity);
    //     }
    // });
    // var obj2 = [];
    // for (var prop in holder) {
    //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
    // }
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "", productQuantity: 0,uom:"" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    // props.getApprovePurchase();
    props.getVendorData();
    // props.getApproveDemand();
    props.resetPurchaseReducer();
  }, []);
  return (
    <>
      {props.isFetchingPoByVendorId ||
      // props.isFetchingPurchaseFromDemandNew ||
      // props.isFetchingPurchaseById ||
      props.isFetchingVendor||
      props.isAddingIGP ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingIGP ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
          <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: PoName }}
                    placeholder="Select Vendor..."
                    onChange={(e) => handleChangeVendor(e)}
                    options={getVendorList}
                    //isOptionDisabled={(option) => !isEmpty(props.approveDemandList) && getChecked(filter(props.approveDemandList, y => y.demandId == option.value))}
                  />
                </Form.Group>
              </Col>
              </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Purchase Order *</b>
                  </Form.Label>
                  <Select
                    value={{ label: purchaseNameLabel }}
                    isDisabled={isEmpty(getPurchaseListFilter)}
                    placeholder="Select Purchase..."
                    onChange={handleChangeGetPurchase}
                    options={
                      isEmpty(getPurchaseListFilter)
                        ? []
                        : getPurchaseListFilter.reverse()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Manual GatePass Number:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Add gatepass...."
                    name="gatePass"
                    min={0}
                    value={gatePass}
                    onChange={(e) => setGatePass(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Receiver Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Receiver Name...."
                    name="receiverName"
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add IGP Description...."
                    name="igpDescription"
                    value={igpDescription}
                    onChange={(e) => setIGPDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
                                    <Col xl="12" lg="12" md="12" md="12" sm="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Add IGP Tax:</b></Form.Label>
                                            <Form.Control type="text" placeholder="Add IGP Tax...." name="igpTax" onBlur={e => setIgpTax(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row> */}

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select IGP Type *</b>
                  </Form.Label>
                  <Select
                    value={{ label: ordersLabel }}
                    placeholder="Select IGP Type..."
                    onChange={(e) => handleChangeOrderType(e)}
                    options={getOrderType}
                  />
                </Form.Group>
              </Col>
            </Row>
            {orders === "Partial" && (
              <Row>
                <Col xl="12" lg="12" md="12" sm="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Add Stock Update:</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Add Stock Update...."
                      name="stockUpdate"
                      value={stockUpdate}
                      onChange={(e) => setStockUpdate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {orders === "Partial" &&
              inputField.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <Row>
                      <Col xl="3" lg="3" md="6" sm="12">
                        <Form.Group>
                          <Form.Label>
                            <b> Select Inventory*</b>
                          </Form.Label>
                          <Select
                            value={{ label: item.inventoryLabel }}
                            isDisabled={isEmpty(inventory)}
                            placeholder="Select Inventory..."
                            name="inventoryId"
                            onChange={(e) => handleChange(e, i, "intname")}
                            options={inventory}
                            isOptionDisabled={(option) =>
                              !isEmpty(
                                find(
                                  inputField,
                                  (x) => x.inventoryId == option.value
                                )
                              )
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col xl="3" lg="3" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Quantity:</b>
                          </Form.Label>
                          <Form.Control
                            placeholder="Quantity...."
                            name="productQuantityCount"
                            value={item.productQuantityCount}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col xl="3" lg="3" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>UOM:</b>
                          </Form.Label>
                          <Form.Control
                            placeholder="UOM...."
                            name="UOM"
                            value={item.uom}
                            readOnly
                          />
                        </Form.Group>
                      </Col> */}
                      <Col xl="3" lg="3" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Add Quantity*</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Quantity...."
                            min={0}
                            name="productQuantity"
                            value={item.productQuantity}
                            onChange={(e) =>
                              handleChangeDemand(
                                e,
                                i,
                                "intquantity",
                                item.inventoryId
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Row>
                        <Col lg="12">
                          {inputField.length - 1 == i && (
                            <IconButton
                              title="Click to Add Demand"
                              onClick={handleAddInput}
                              style={{
                                float: "right",
                                color: "black",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )}

                          {inputField.length !== 1 && (
                            <IconButton
                              title="Click to Remove Demand"
                              onClick={() => handleRemoveInput(i)}
                              style={{ float: "right", color: "black" }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </React.Fragment>
                );
              })}
            <div className="sendDiv" style={{marginTop:'20px'}}>
              {
              isUndefined(purchaseNameValue) ||
              isUndefined(orders) ||
              (orders == "Partial" && inputField[0].inventoryId == "") ||
              (orders == "Partial" && inputField[0].productQuantity == "") ? (
                <Button
                  disabled
                  style={{ color: "black" }}
                  className="sendButton"
                  onClick={uploadPurchase}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadPurchase}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getApprovePurchase: () => dispatch(getApprovePurchase()),
    getVendorData: () => dispatch(getVendorData()),
    getCompletePOByVendorId:(id)=>dispatch(getCompletePOByVendorId(id)),
    getApproveDemand: () => dispatch(getApproveDemand()),
    purchaseById: (data) => dispatch(purchaseById(data)),
    addIGP: (data, handleVisible, handleDangerVisible) =>
      dispatch(addIGP(data, handleVisible, handleDangerVisible)),
    resetPurchaseReducer: () => dispatch(resetPurchaseReducer()),
    purchaseFromDemandCallNew: (data) =>
      dispatch(purchaseFromDemandCallNew(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  purchaseListById: state.AddDataReducer.purchaseListById,
  approveDemandList: state.AddDataReducer.approveDemandList,
  purchaseFromDemandDataNew: state.AddDataReducer.purchaseFromDemandDataNew,
  isFetchningApproveDemandList:
    state.AddDataReducer.isFetchningApproveDemandList,
  isFetchingPurchaseFromDemandNew:
    state.AddDataReducer.isFetchingPurchaseFromDemandNew,
  isFetchingPurchaseById: state.AddDataReducer.isFetchingPurchaseById,
  isAddingIGP: state.AddDataReducer.isAddingIGP,
  approvePurchaseList:state.AddDataReducer.approvePurchaseList,
  isApprovePurchaseListFetching:state.AddDataReducer.isApprovePurchaseListFetching,
  vendorDataList:state.VendorReducer.vendorDataList,
  isFetchingVendor:state.VendorReducer.isFetchingVendor,

  poByVendorIdList:state.VendorReducer.poByVendorIdList,
  isFetchingPoByVendorId:state.VendorReducer.isFetchingPoByVendorId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIGP);

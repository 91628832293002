import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./hr-styles.css";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { getEmployeeData, HRUpdateEmployee, deleteHR } from "./HRDetails.actions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Redirect, Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const ViewEmployee = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    console.log(anchorEl, "eeeeeeeeee");
    const [employeeDataList, setEmployeeDataList] = React.useState('');
    console.log(employeeDataList, "emidddd")

    const handleClick = (event, rowData) => {
        setAnchorEl(event.currentTarget);
        setEmployeeDataList(rowData);
        console.log(rowData, "rowwwwwwww");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const employeeData = props.employeeList.reverse();
    console.log(employeeData, "employeeeee");
    const [state, setState] = React.useState({
        columns: [
            // { title: 'CNIC', field: 'cnic' },employeeId
            { title: 'Employee Id', field: 'employeeId', render: rowData => appendNonSignificantZeros(rowData.employeeId)  },
            { title: 'Name', field: 'name' },
            { title: 'Father Name', field: 'father_name' },
            { title: 'Department', field: 'department.departmentName' },
            { title: 'Designation', field: 'designation.designationName' },

            { title: 'Total Allowances', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance} ` : '-' },
            { title: 'Total Salary', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance + rowData.salaries[0].basic_salary} ` : '-' },
            { title: 'Joining Date', field: 'joining_date' },
            { title: 'Employee Type', field: 'employee_type' },
            { title: 'Active Status', field: 'active_status', render: rowData=> (rowData.active_status===true) ? 'Active' : 'InActive'},

            // { title: 'Gender', field: 'gender' },
            // { title: 'Mother Name', field: 'mother_name' },
            // { title: 'Marital Status', field: 'marital_status' },
            // { title: 'Religion', field: 'religion' },
            // { title: 'Date of birth', field: 'dob' },
            // { title: 'Blood Group', field: 'blood_group' },
            // { title: 'Phone #', field: 'phone' },
            // { title: 'Emergency Contact', field: 'emergency_contact' },
            // { title: 'Address', field: 'Address' },
            // { title: 'Salary', field: 'Salary' },

            // { title: 'Appointment Type', field: 'appointment_type' },
            // // { title: 'createdAt', field: 'createdAt' },
            // // { title: 'Department ID', field: 'department_id' },

            

            // { title: 'Employee ID', field: 'employeeId' },
            // { title: 'Machine ID', field: 'machine_id' },
            // { title: 'Marital Status', field: 'marital_status' },
            // { title: 'Report To', field: 'report_to' },

            // { title: 'Ntn', field: 'ntn' },
            // { title: 'EOBI', field: 'eobi' },
            // { title: 'Social Security', field: 'social_security' },
            // { title: 'Probation Period', field: 'probation_period' },
            // { title: 'Probation End Date', field: 'probation_period_end_date' },
            // { title: 'Allocation', field: 'allocation' },

            // { title: 'updatedAt', field: 'updatedAt' },

        ],
    });
    useEffect(() => {
        props.getEmployeeData();
    }, []);


    const [callEdit, setcallEdit] = React.useState(false);
    if (callEdit) {
        return <Redirect push to="/user/view-user" />;
    }

    return (
        <>
            {props.isFetchingEmployeeData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Employees Details"
                            columns={uniqBy([
                                // { title: 'CNIC', field: 'cnic' },employeeId
                                { title: 'Employee Id', field: 'employeeId', render: rowData => appendNonSignificantZeros(rowData.employeeId)  },
                                { title: 'Name', field: 'name' },
                                { title: 'Father Name', field: 'father_name' },
                                { title: 'Department', field: 'department.departmentName' },
                                { title: 'Designation', field: 'designation.designationName' },
                    
                                { title: 'Total Allowances', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance} ` : '-' },
                                { title: 'Total Salary', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance + rowData.salaries[0].basic_salary} ` : '-' },
                                { title: 'Joining Date', field: 'joining_date' },
                                { title: 'Employee Type', field: 'employee_type' },
                                { title: 'Active Status', field: 'active_status', render: rowData=> (rowData.active_status===true) ? 'Active' : 'InActive'},
                    
                                // { title: 'Gender'
                            ] , 'title')}
                            data={employeeData}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            actions={[
                                (rowData) => {
                                    return { icon: EditIcon, disabled: false, onClick: (event, rowData) => { handleClick(event, rowData) } }
                                },
                            ]}
                            editable={{

                                // onRowUpdate: (newData, oldData) =>
                                //     new Promise((resolve) => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             if (oldData) {
                                //                 props.HRUpdateEmployee(newData, oldData)

                                //             }
                                //         }, 600);
                                //     }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteHR(oldData.employeeId);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Link
                            to={{
                                pathname: "/user/edit-employee",
                                state: employeeDataList
                            }}>
                            <MenuItem>
                                Edit Employee
                            </MenuItem>
                        </Link>

                        <Link
                            to={{
                                pathname: "/user/edit-salary",
                                state: employeeDataList
                            }}>
                            {console.log('employeeDataList', employeeDataList)}
                            <MenuItem>
                                Edit Salary
                            </MenuItem>
                        </Link>
                    </Menu>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeeData: () => dispatch(getEmployeeData()),
        updateHR: (data, oldData) => dispatch(HRUpdateEmployee(data, oldData)),
        deleteHR: (data) => dispatch(deleteHR(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewEmployee);
import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {

    // isFetchingGetCustomer: false,
    // customerInfo: [],
    isSavingBranch:false,
    addBranch:[],
    isFetchingBranch:false,
    branchData:[],
    isFetchingBranchAdmin:false,
    branchDataAdmin:[]
};
console.log('initialState.employeeTypeList', initialState.employeeTypeList);


const BranchReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {
        // //get customer
        // case "REQUEST_GET_CUSTOMER":
        //     return { ...state, isFetchingGetCustomer: true };

        // case "SUCCESS_GET_CUSTOMER":
        //     return {
        //         ...state, isFetchingGetCustomer: false,
        //         customerInfo: action.payload.result
        //     };

        // case "ERROR_GET_CUSTOMER":
        //     return { ...state, isFetchingGetCustomer: false };
            //add Branch
            case "REQUEST_ADD_BRANCH":
                return { ...state, isSavingBranch: true };
    
            case "SUCCESS_ADD_BRANCH":
                return {
                    ...state, isSavingBranch: false,
                    addBranch: action.payload.data
                };
    
            case "ERROR_ADD_BRANCH":
                return { ...state, isSavingBranch: false };
                  //get brach
        case "REQUEST_GET_BRANCH":
            return { ...state, isFetchingBranch: true };

        case "SUCCESS_GET_BRANCH":
            return {
                ...state, isFetchingBranch: false,
                branchData: action.payload.data
            };

        case "ERROR_GET_BRANCH":
            return { ...state, isFetchingBranch: false };

            case "REQUEST_GET_BRANCH_ADMIN":
                return { ...state, isFetchingBranchAdmin: true };
    
            case "SUCCESS_GET_BRANCH_ADMIN":
                return {
                    ...state, isFetchingBranchAdmin: false,
                    branchDataAdmin: action.payload.data
                };
    
            case "ERROR_GET_BRANCH_ADMIN":
                return { ...state, isFetchingBranchAdmin: false };
        default:
            return state;
    }
};
export default BranchReducer;

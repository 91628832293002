import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {

    isFetchinguom:false,
    uomData:[],
};
console.log('initialState.employeeTypeList', initialState.employeeTypeList);


const UOMReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {

                  //get brach
        case "REQUEST_GET_UOM":
            return { ...state, isFetchinguom: true };

        case "SUCCESS_GET_UOM":
            return {
                ...state, isFetchinguom: false,
                uomData: action.payload.data
            };

        case "ERROR_GET_UOM":
            return { ...state, isFetchinguom: false };
        default:
            return state;
    }
};
export default UOMReducer;

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Tabs, Tab } from "react-bootstrap";
import { ModalBody, Modal, } from "reactstrap";
import "./add-details.css";
import { addDemand, getAllDemand, getAllInventory, searchDemandReport, getApproveDemand, getWarehouseData, getDemandByBranchId } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import DemandReport from "./DemandReport";
import Select from 'react-select';
import Swal from 'sweetalert';
import { getBranch } from "./Branch/BranchDetails.actions";

const DemandPDF = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [demandNameValue, setDemandNameValue] = useState("");
    const [demandNameLabel, setDemandNameLabel] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [branchId, setBranchId] = useState("");
    const [branchLabel, setBranchLabel] = useState("");

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }

     const getDemandList = props.demandByBranchIdList.map((x) => { let data = { value: x.demandId, label: x.demandId + " / " + x.demandName }; return data })

    const handleChangeGetDemand = (selectedOption) => {
        setDemandNameValue(selectedOption.value);
        setDemandNameLabel(selectedOption.label);
    };
    const handleChangeBranch = (selectedOption) => {
        setBranchId(selectedOption.value);
        setBranchLabel(selectedOption.label);
        props.getDemandByBranchId(selectedOption.value)
        setDemandNameValue("")
        setDemandNameLabel("")
      };
    
      const getBranchList = props.branchData.map((x) => {
        let data = { value: x.branch_id, label: x.branch_name };
        return data;
      });
    const exportPDF = () => {
        props.searchDemandReport({
            demandId: demandNameValue,
            // startedDate: "",
            // endDate: ""
        }, demandNameLabel, startDate, endDate, handleVisible);
        setStartDate("");
        setEndDate("");
        setDemandNameValue("")
        setDemandNameLabel("")
        setBranchId("")
        setBranchLabel("")
    }

    useEffect(() => {
        props.GetBranch()
    }, []);
    return (
        <>
            {props.isFetchningApproveDemandList || props.isFetchingSearchDemandList||props.isFetchingBranch ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchDemandList ? 'Downloading PDF...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        {/* <Row>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                         
                        </Row> */}
                        <Row>
                        <Col xl="6" lg="6" md="6" sm="6">
                  <Form.Group>
                    <Form.Label>
                      <b> Select Branch * </b>
                    </Form.Label>
                    <Select
                      value={{ label: branchLabel }}
                      placeholder="Select Branch..."
                      onChange={(e) => handleChangeBranch(e)}
                      options={getBranchList}
                    />
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Demand#</b>
                                    </Form.Label>
                                    <Select
                                        value={{ label: demandNameLabel }}
                                        isDisabled={branchId==""?true:false}
                                        placeholder="Select Demand..."
                                        onChange={handleChangeGetDemand}
                                        options={isEmpty(getDemandList) ? [] : getDemandList.reverse()}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv" style={{marginTop:'20px'}}>
                            {demandNameLabel=="" ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Demand Report</Button> : props.isFetchingSearchDemandList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Demand Report</Button>}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllDemand: () => dispatch(getAllDemand()),
        GetBranch: () => dispatch(getBranch()),
        getAllInventory: (data) => dispatch(getAllInventory(data)),
        searchDemandReport: (data, name, startDate, endDate, handleVisible) => dispatch(searchDemandReport(data, name, startDate, endDate, handleVisible)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        getWarehouseData: () => dispatch(getWarehouseData()),
        getDemandByBranchId:(id)=>dispatch(getDemandByBranchId(id))
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
    allInventory: state.AddDataReducer.allInventory,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    isAddingDemanddata: state.AddDataReducer.isAddingDemanddata,
    notAddDemandData: state.AddDataReducer.notAddDemandData,
    approveDemandList: state.AddDataReducer.approveDemandList,
    isFetchningApproveDemandList: state.AddDataReducer.isFetchningApproveDemandList,
    isFetchingSearchDemandList: state.AddDataReducer.isFetchingSearchDemandList,
    isFetchingBranch:state.BranchReducer.isFetchingBranch,
    branchData:state.BranchReducer.branchData,
    demandByBranchIdList:state.AddDataReducer.demandByBranchIdList,
    isFetchingDemandByBranchIdList:state.AddDataReducer.isFetchingDemandByBranchIdList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandPDF);
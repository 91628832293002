import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { getUom } from "./UOM.actions";


const UOMList = (props) => {
    useEffect(() => {
        props.GetUOM();
    }, []);
    return (
        <>
            {(props.isFetchinguom ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <MaterialTable
                                    title="UOM Detail"
                                    columns={[
                                        // { title: 'Branch Date', field: 'invoice_date' },    
                                        { title: 'UOM Name', field: 'uomName' },
                                        { title: 'UOM Description', field: 'uomDescription' },
                                        // { title: 'Branch Status', field: 'invoice_status' },
                                    ]}
                                    data={props.uomData}
                                   
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        // exportButton: true,
                                        // exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },
                                    }}
                                  

                                />
                            </Form>

                        </div>
                    
                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetUOM: () => dispatch(getUom()),
        // UpdateBranch: (newData, oldData) => dispatch(UpdateBranch(newData, oldData)),
        // deleteBranch: (Branch_id) => dispatch(deleteBranch(Branch_id))
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchinguom:state.UOMReducer.isFetchinguom,
  uomData:state.UOMReducer.uomData

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UOMList);
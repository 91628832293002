import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  addPurchase,
  getApproveDemand,
  searchPurchaseReport,
  getApprovePurchase,
  purchaseById,
  purchaseFromDemandCall,
  getPOByVendorId,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendorData } from "./Vendor/Vendor.action";

const PurchaseReport = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "-1",
      productQuantity: 0,
      productQuantityCount: "",
      productPrice: 0,
      marketPrice: 0,
      inventoryLabel: "",
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [demandName, setDemandName] = useState("");
  const [purchaseName, setPurchaseName] = useState();
  const [purchaseDescription, setPurchaseDescription] = useState();
  const [supplier, setSupplier] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [demandNameValue, setDemandNameValue] = useState();
  const [demandNameLabel, setDemandNameLabel] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState("");
  const [purchaseNameLabel, setPurchaseNameLabel] = useState("");
  const [purchaseTax, setPurchaseTax] = useState();
  const [ManualPurchaseNumber, setManualPurchaseNumber] = useState();
  const [saveId, setSaveId] = useState([]);
  const [visibleAlert, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };
  const getPurchaseList =
    !isEmpty(props.poByVendorIdList) &&
    props.poByVendorIdList.map((x) => {
      let data = {
        value: x.purchaseOrderId,
        label: x.purchaseOrderId,
      };
      return data;
    });

  const handleChangeGetPurchase = (selectedOption) => {
    setPurchaseNameValue(selectedOption.value);
    setPurchaseNameLabel(selectedOption.label);
    // props.purchaseById({ purchaseOrderId: selectedOption.value });
  };

  const exportPDF = () => {
    props.searchPurchaseReport(
      {
        demandId: demandNameValue,
        // startedDate: !isEmpty(startDate) ? startDate : "",
        // endDate: !isEmpty(endDate) ? endDate : "",
        purchaseOrderId: purchaseNameValue,
      },
      demandNameLabel,
      purchaseNameLabel,
      // startDate,
      // endDate,
      handleVisible
    );
    setStartDate("");
    setEndDate("");
    setDemandNameLabel("");
    setPurchaseNameLabel("");
  };

  const getVendorList = props.vendorDataList.map((x) => {
    let data = {
      value: x.vendorId,
      label: x.vendorId + " / " + x.vendorName,
      poDate: x.lastPODate,
    };
    return data;
  });
  const getVendor = (selectedOption) => {
    setDemandName(selectedOption.value);
    setDemandNameLabel(selectedOption.label);
    props.getpoByVendorId(selectedOption.value);
  };
  useEffect(() => {
    // props.getPurchase();
    // props.getApprovePurchase();
    props.getVendorData();
    // props.getApproveDemand();
  }, []);
  return (
    <>
      {props.isFetchningApproveDemandList ||
      props.isFetchingPurchaseFromDemand ||
      props.isFetchingpoByVendorIdList ||
      props.isFetchingVendor ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {/* <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row> */}
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: demandNameLabel }}
                    placeholder="Select Vendor..."
                    onChange={(e) => getVendor(e)}
                    options={getVendorList}
                    //isOptionDisabled={(option) => !isEmpty(props.approveDemandList) && getChecked(filter(props.approveDemandList, y => y.demandId == option.value))}
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Purchase Order</b>
                  </Form.Label>
                  <Select
                    value={{ label: purchaseNameLabel }}
                    isDisabled={demandName==""?true:false}
                    placeholder="Select Purchase..."
                    onChange={(e) => handleChangeGetPurchase(e)}
                    options={
                      !isEmpty(getPurchaseList) ? getPurchaseList.reverse() : []
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv" style={{marginTop:'20px'}}>
              {purchaseNameLabel=="" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate Purchase Report
                </Button>
              ) : props.isFetchingSearchPurchaseList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate Purchase Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
    getApproveDemand: () => dispatch(getApproveDemand()),
    searchPurchaseReport: (
      data,
      purchaseName,
      demandName,
      handleVisible
    ) =>
      dispatch(
        searchPurchaseReport(
          data,
          purchaseName,
          demandName,
          handleVisible
        )
      ),
    getApprovePurchase: () => dispatch(getApprovePurchase()),
    purchaseById: (data) => dispatch(purchaseById(data)),
    getVendorData: () => dispatch(getVendorData()),
    purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
    getpoByVendorId: (id) => dispatch(getPOByVendorId(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  demandList: state.AddDataReducer.demandList,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  porductListDemand: state.AddDataReducer.porductListDemand,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  approvePurchaseList: state.AddDataReducer.approvePurchaseList,
  purchaseListById: state.AddDataReducer.purchaseListById,
  isFetchingSearchPurchaseList:
    state.AddDataReducer.isFetchingSearchPurchaseList,
  isFetchingPurchaseList: state.AddDataReducer.isFetchingPurchaseList,
  purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
  isFetchingProductByDemand: state.AddDataReducer.isFetchingProductByDemand,
  isFetchningApproveDemandList:
    state.AddDataReducer.isFetchningApproveDemandList,
  isFetchingPurchaseFromDemand:
    state.AddDataReducer.isFetchingPurchaseFromDemand,
  poByVendorIdList: state.AddDataReducer.poByVendorIdList,
  isFetchingpoByVendorIdList: state.AddDataReducer.isFetchingpoByVendorIdList,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseReport);

import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./add-details.css";
import { connect } from "react-redux";
import IGPDetail from "./IGPDetail";
import AddIGP from "./AddIGP";
import IGPReport from "./IGPReport";
import { isNull } from "lodash";
import IGPReportByDate from "./IGPReportByDate";

const IGP = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 14) ?
                    "IGPList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 15) ?
                        "AddIGP" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 16) ?
                            "IGPReport" :!isNull(permissions) && permissions.some(p => p['sub_id'] === 16) ?
                            "IGPReportByDate" : ""}
                id="IGPList"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="IGPList"
                    title="IGP Detail"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 14) ? false : true}>
                    <IGPDetail />
                </Tab>
                <Tab
                    eventKey="AddIGP"
                    title="Add IGP"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 15) ? false : true}>
                    <AddIGP />
                </Tab>

                <Tab
                    eventKey="IGPReport"
                    title="IGP Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 16) ? false : true}>
                    <IGPReport />
                </Tab>
                <Tab
                    eventKey="IGPReportByDate"
                    title="IGP Report By Date"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 16) ? false : true}>
                    <IGPReportByDate />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IGP);

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty, get } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus, getInfoLTLedger } from "./HRDetails.actions";
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import { baseURL, headers } from "../../../../../global/api";

import Select from 'react-select';
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";

const HRLTLedger = (props) => {

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState();
  console.log(item, 'item')
  /* const [state, setState] = React.useState({
     columns: [
       { title: 'Account Name', field: 'acc_name' },
       { title: 'Account Type', field: 'acc_type' },
       { title: 'Opening Balance', field: 'opening_balance' },
       { title: 'Credit Value', field: 'credit_value' },
       { title: 'Debit Value', field: 'debit_value' },
       { title: 'Closing Balance', field: 'closing_balance' },
       { title: 'Date', field: 'long_term_ledger_date' }
     ],
   });*/

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
  !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const handleChangeEmployee = (selectedOption) => {
    setItem(selectedOption.value);
  };
  const LTLedger = props.ltLedger;
  console.log(LTLedger, 'ledger')
  // const exportPDF = () => {
  //   props.searchAccountLedgerReport({
  //     startedDate: !isEmpty(startDate) ? startDate : "",
  //     endDate: !isEmpty(endDate) ? endDate : "",
  //     item: item,
  //   }, startDate, endDate);
  // }
  useEffect(() => {
    props.getEmployeeData();
    props.getEmployeeDataWithStatus(1);

  }, []);

  function searchLedger() {
    props.getInfoLTLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      employee_id: item,
    });
  }

  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingEmployeeData ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {/* <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>

                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Employee </b>
                  </Form.Label>
                  <Select
                    placeholder="Select Employee..."
                    onChange={handleChangeEmployee}
                    options={getEmployeeList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>

              {/* <Col lg="12" sm="12" className="text-center">
                {item == "" ?
                  <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Search</Button> :
                  <Link to= {{pathname : "/user/Ledger-List", 
                  state: {start: startDate, end: endDate}}}>
                    <Button className="sendButton">Search</Button>
                  </Link>
                }
              </Col> 
              
              </Row>

            <div className="sendDiv">
              {item == "" ?
                <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Generate Ledger Report</Button> :
                <Button className="sendButton" onClick={() => searchLedger()}>Generate Ledger Report</Button>}
            </div> */}
            <MaterialTable
              title="LT Ledger"
              columns={[
                { title: 'Emloyee Id', field: 'employee_id' },
                { title: 'Account Name', field: 'acc_name' },
                { title: 'Account Type', field: 'acc_type' },
                { title: 'Deduction Amount', field: 'long_term_deduction_value' },
                { title: 'Description', field: 'long_term_description' },
                { title: 'Advance Given', field: 'credit_value' },
                { title: 'Advance Deduction', field: 'debit_value' },
                { title: 'Closing Balance', field: 'closing_balance' },
                { title: 'Date', field: 'long_term_ledger_date' }
              ]}
              data={
                // LTLedger
                (query) =>
                  new Promise((resolve, reject) => {
                    let url = `${baseURL}long_term_adv/get_lt_employee_ledger?`;
                    url += "size=" + query.pageSize;
                    url += "&page=" + (query.page + 1);
                    url += "&search=" + query.search;
                    fetch(url, {
                      method: 'GET',
                      headers: headers,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data,
                          page: result.page - 1,
                          totalCount: result.total_results,
                        });
                      });
                  })
              }
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: 'sticky', top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },

              }}
            // editable={{
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         {
            //           props.updateFine(newData, oldData)
            //         }
            //       }, 600);
            //     }),
            //   onRowDelete: (oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         props.deleteFine(oldData.fine_and_plenties_id);
            //         console.log(oldData, 'old')
            //       }, 600);
            //     }),
            // }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getInfoLTLedger: (data) => dispatch(getInfoLTLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    // searchAccountLedgerReport: (data, startDate, endDate) => dispatch(searchAccountLedgerReport(data, startDate, endDate)),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  ltLedger: state.HrReducer.ltLedger,
  isFetchingLTLedger: state.HrReducer.isFetchingLTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger: state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HRLTLedger);
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "../add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getPurchase,
  getApproveDemand,
  addIssuance,
  getAllInventory,
  getReceiving,
  searchReceivingReport,
} from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";

const ReceivingReport = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
      inventoryLabel: "",
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);
    setIssuanceNameLabel(selectedOption.label);
  };

  const getReceivingList = props.ReceivingList.map((x) => {
    let data = {
      value: x.receiving_id,
      label: `${x.receiving_id}/${x.receivedBy}/${x.receiving_date}`,
    };
    return data;
  });

  const exportPDF = () => {
    props.searchReceivingReport(
      {
       
        receiving_id: issuanceNameValue,
      },
      issuanceNameLabel,
      handleVisible
    );
    setStartDate("");
    setEndDate("");
    setIssuanceNameLabel("");
  };

  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
    };
    return data;
  });

  const handleChangeDemand = (e, index, maxVal, type) => {
    if (Number(e.target.value) <= maxVal) {
      let z = find(props.allInventory, (x) => x.inventoryId == e.value);
      const { name, value } = e;
      const list = [...inputField];
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname")
        list[index]["productQuantity"] = Number(z.currentBlnc);
      if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
      if (type == "intquantity")
        list[index]["productQuantity"] = e.target.value;
      var holder = {};
      list.forEach(function (d) {
        if (holder.hasOwnProperty(d.inventoryId)) {
          holder[d.inventoryId] =
            Number(d.productQuantity) + Number(d.productQuantity);
        } else {
          holder[d.inventoryId] = Number(d.productQuantity);
        }
      });
      var obj2 = [];
      for (var prop in holder) {
        obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
      }
      setInputField(obj2);
    } else {
      alert("value out of range");
    }
  };

  const handleChange = (e, index, type) => {
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
    list[index]["productQuantity"] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "", productQuantity: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];

  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
    setRequistionLabel(selectedOption.label);
  };

  const wareHouseData = props.IssuanceList;
  const [state, setState] = React.useState({
    viewColumns: [
      { title: "Issuance Number", field: "issuanceId" },
      { title: "Requistion", field: "requsition" },
      { title: "Department", field: "department" },
      { title: "Issued By", field: "issuedBy" },
      { title: "Received By", field: "receivedBy" },
      { title: "Requistion Type", field: "requsitionType" },
    ],
    columnsData: [
      {
        title: "Inventory Code",
        field: "inventory",
        render: (rowData) =>
          rowData.inventory.warehouse.whKey +
          "-" +
          rowData.inventory.category.cateKey +
          "-" +
          rowData.inventory.invKey,
      },
      { title: "Inventory Name", field: "inventory.productName" },
      { title: "Product Quantity", field: "productQuantity" },
    ],
  });
  useEffect(() => {
    // props.getPurchase();
    // props.getApproveDemand();
    // props.getAllInventory({
    //   startedDate: "",
    //   endDate: "",
    //   wareHouseId: "",
    //   subCategoryId: "",
    //   categoryId: "",
    // });
    props.getReceiving();
  }, []);
  return (
    <>
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select GRN</b>
                  </Form.Label>
                  <Select
                    value={{ label: issuanceNameLabel }}
                    placeholder="Select GRN..."
                    onChange={(e) => handleChangeGetIssuance(e)}
                    options={!isEmpty(getReceivingList) ? getReceivingList : []}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {/* {!issuanceNameValue ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Issuance Report</Button> :  <Button className="sendButton" onClick={() => exportPDF()}>Generate Issuance Report</Button>} */}

              {isUndefined(issuanceNameValue) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate Receiving Report
                </Button>
              ) : props.isFetchingSearchReceivingList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate Receiving Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    getApproveDemand: () => dispatch(getApproveDemand()),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getReceiving: () => dispatch(getReceiving()),
    searchReceivingReport: (data, issuanceName, handleVisible) =>
      dispatch(searchReceivingReport(data, issuanceName, handleVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  ReceivingList: state.AddDataReducer.ReceivingList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingReport);

import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import UOMList from "./UOMList";
import AddUom from "./AddUom";

const TotalUOMData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey="ADDUOM" 
                      
                                id="ADDUOM" className="mb-3">
                                    <Tab eventKey="ADDUOM" title="Add Uom">  
                    <AddUom />
                </Tab>
                <Tab eventKey="UOMLIST" title="Uom List">  
                    <UOMList />
                </Tab>
            </Tabs>
            {/* <Tabs 
            defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 115) ?
            "AddInvoice" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 116) ?
                "InvoiceList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 119)?
            "RecivableAccounts":!isNull(permissions) && permissions.some(p => p['sub_id'] === 120)?
        "selectLevel3Accounts":""} id="Invoice"
            >
                <Tab eventKey="AddInvoice" title="Add Invoice"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 115) ? false : true}>
                    <AddInvoice/>
                </Tab>
                <Tab eventKey="InvoiceList" title="Invoice List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 116) ? false : true}>
                    <InvoiceList/>
                </Tab>
                <Tab eventKey="RecivableAccounts" title="Recivable Account"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 119) ? false : true}>
                    <RecivableAccounts/>
                </Tab>
                <Tab eventKey="selectLevel3Accounts" title="Level 3 Account"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 120) ? false : true}>
                    <SelectLevel3Account/>
                </Tab>
            </Tabs> */}
            
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalUOMData);
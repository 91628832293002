import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  addPurchase,
  getApproveDemand,
  searchPurchaseReport,
  getApprovePurchase,
  purchaseById,
  purchaseFromDemandCall,
  getPOByVendorId,
  searchPurchaseReportByDate,
  getAllInventory,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendorData } from "./Vendor/Vendor.action";

const PurchaseReportByDate = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [productLabel, setProductLabel] = useState();

  const [productId, setProductId] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };


  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.productName,
    };
    return data;
  });
  const handleChangeProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductLabel(selectedOption.label);

  };
  const exportPDF = () => {
    props.searchPurchaseReport(
      {
        inventoryId: productId,
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
      },
      handleVisible
    );
    setStartDate("");
    setEndDate("");
  };

  // const getVendorList = props.vendorDataList.map((x) => {
  //   let data = {
  //     value: x.vendorId,
  //     label: x.vendorId + " / " + x.vendorName,
  //     poDate: x.lastPODate,
  //   };
  //   return data;
  // });
  // const getVendor = (selectedOption) => {
  //   setDemandName(selectedOption.value);
  //   setDemandNameLabel(selectedOption.label);
  //   props.getpoByVendorId(selectedOption.value);
  // };
  useEffect(() => {
    // props.getVendorData();
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
  }, []);
  return (
    <>
      {
      props.isFetchingInventoryAll ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: demandNameLabel }}
                    placeholder="Select Vendor..."
                    onChange={(e) => getVendor(e)}
                    options={getVendorList}
                    //isOptionDisabled={(option) => !isEmpty(props.approveDemandList) && getChecked(filter(props.approveDemandList, y => y.demandId == option.value))}
                  />
                </Form.Group>
              </Col> */}

              <Col xl="6" lg="6" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory *</b>
                        </Form.Label>
                        <Select
                          isDisabled={isEmpty(inventory)}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          value={{ label: productLabel }}
                          onChange={(e) => handleChangeProduct(e)}
                          options={inventory}
                        />
                      </Form.Group>
                    </Col>
            </Row>

            <div className="sendDiv" style={{marginTop:'20px'}}>
              {isEmpty(startDate)&&productId=="" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate Purchase Report
                </Button>
              ) : props.isFetchingSearchPurchaseList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate Purchase Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    
    searchPurchaseReport: (
      data,
      handleVisible
    ) =>
      dispatch(
        searchPurchaseReportByDate(
          data,
          handleVisible
        )
      ),
 
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    // getVendorData: () => dispatch(getVendorData()),

  };
};
const mapStateToProps = (state, ownProps) => ({
 
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,

  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseReportByDate);

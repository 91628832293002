import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Tabs, Tab } from "react-bootstrap";
import { ModalBody, Modal, } from "reactstrap";
import "./add-details.css";
import { addDemand, getAllDemand, getAllInventory, searchDemandReport, getApproveDemand, getWarehouseData } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import DemandReport from "./DemandReport";
import AddDemand from "./AddDemand";
import DemandPDF from "./DemandPDF";
import Select from 'react-select';
import Swal from 'sweetalert';
import {isNull } from "lodash";
import DemandPDFByDate from "./DemandPDFByDate";

const DemandDetails = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const [inputField, setInputField] = useState([{
        inventoryId: "",
        inventoryCount: "",
        productQuantity: "",
        inventoryLabel: ""
    }]);
    const [demandName, setDemandName] = useState();
    const [wareHouseId, setwareHouseId] = useState();
    const [warehouseLabel, setWareHouseLabel] = useState();
    const [orderNumber, setOrderNumber] = useState();
    const [demandDescription, setDemandDescription] = useState();
    const [demandType, setDemandType] = useState();
    const [customerOrder, setCustomerOrder] = useState();
    const [expectedDate, setExpectedDate] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [demandNameValue, setDemandNameValue] = useState();
    const [demandNameLabel, setDemandNameLabel] = useState();
    console.log(demandNameLabel, "ssssssssssss")
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Demand Added Successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    }

    const handleChangeWareHouse = (selectedOption) => {
        setwareHouseId(selectedOption.value);
        setWareHouseLabel(selectedOption.label);
    };

    const getWareHouseList = props.warehouseList.map((x) => { let data = { value: x.wareHouseId, label: x.wareHouseName }; return data });
    const getDemandType = [
        { value: "Local", label: "Local" },
        { value: "Imported", label: "Imported" },
    ];

    const inventory = props.allInventory.map((x) => { let data = { value: x.inventoryId, label: x.warehouse.whKey + "-" + x.category.cateKey + "-" + x.sub_category.subcateKey + "-" + x.invKey + " " + x.productName }; return data })

    const handleChangeDemandType = (selectedOption) => {
        setDemandType(selectedOption.value);
    };

    const getDemandList = props.approveDemandList.map((x) => { let data = { value: x.demandId, label: x.demandId + " / " + x.demandName }; return data })

    const handleChangeGetDemand = (selectedOption) => {
        setDemandNameValue(selectedOption.value);
        setDemandNameLabel(selectedOption.label);
    };

    const exportPDF = () => {
        props.searchDemandReport({
            demandId: demandNameValue,
            startedDate: "",
            endDate: ""
        }, demandNameLabel, startDate, endDate);
    }

    function uploadDemand() {
        props.addDemand({
            demandName: demandName,
            demandDescription: demandDescription,
            orderNumber: orderNumber,
            demandType: demandType,
            customerOrder: customerOrder,
            accDate: expectedDate,
            wareHouseId: wareHouseId.value,
            quantity: inputField
        }, handleVisible, handleDangerVisible);
        setwareHouseId("");
        setWareHouseLabel("");
        setInputField([{
            inventoryId: "",
            inventoryCount: "",
            productQuantity: "",
            inventoryLabel: ""
        }]);
        setDemandType("");
        setDemandDescription("");
        document.getElementById("demandName").value = "";
        document.getElementById("orderNumber").value = "";
        document.getElementById("customerOrder").value = "";
        document.getElementById("demandDescription").value = "";
        document.getElementById("expextedDate").value = "";
        document.getElementById("inventoryCount").value = "";
        document.getElementById("productQuantity").value = "";
    }
    const handleChange = (e, index, type) => {
        console.log(e, index, type, "handle")
        let z = find(props.allInventory, x => x.inventoryId == e.value);
        const { name, value } = e;
        const list = [...inputField];
        if (type == 'intname') {
            list[index]['inventoryId'] = e.value;
            list[index]['inventoryLabel'] = e.label;
        }
        if (type == 'intname') list[index]['inventoryCount'] = Number(z.currentBlnc);
        if (type == 'intquantity') list[index]['productQuantity'] = e.target.value;
        setInputField(list);
    };

    const handleAddInput = () => {
        setInputField([...inputField, { inventoryId: "", inventoryCount: "", productQuantity: "" }])
    };

    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    console.log(inputField, "qqllss")
    useEffect(() => {
        props.getAllInventory({
            startedDate: "",
            endDate: "",
            wareHouseId: "",
            subCategoryId: "",
            categoryId: "",
        })
        // props.getAllDemand();
        // props.getApproveDemand();
        // props.getWarehouseData();
    }, []);
    return (
        <>
          {/* <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 5) ?
                    "DemandDetails" :
                    !isNull(permissions) && permissions.some(p => p['sub_id'] === 6) ?
                        "AddDemand" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 7) ? 
                        "DemandReport" : ""}
                id="Demand"
                unmountOnExit={true}>
                <Tab
                    eventKey="DemandDetails"
                    title="Demand Details"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 5) ? false : true}>
                    <DemandReport />
                </Tab>

                <Tab
                    eventKey="AddDemand"
                    title="Create Demand"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 6) ? false : true}>
                    <AddDemand />
                </Tab>

                <Tab
                    eventKey="DemandReport"
                    title="Demand Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 7) ? false : true}>
                    <DemandPDF />
                </Tab>
            </Tabs> */}

            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 8) ?
                    "AddDemand" :
                    !isNull(permissions) && permissions.some(p => p['sub_id'] === 9) ?
                        "DemandDetail" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 7) ? 
                        "DemandReport" :!isNull(permissions) && permissions.some(p => p['sub_id'] === 7) ? 
                        "DemandReportByProduct" : ""} id="Demand" unmountOnExit={true}>
                <Tab eventKey="AddDemand" title="Demand Details" unmountOnExit={true}
                 disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 10) ? false : true}>
                    <DemandReport />
                </Tab>

                <Tab eventKey="DemandDetail" title="Create Demand" unmountOnExit={true}
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 9) ? false : true}
                >
                   <AddDemand />
                </Tab>

                <Tab eventKey="DemandReport" title="Demand Report" unmountOnExit={true}
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 10) ? false : true}
                >
                  <DemandPDF />
                </Tab>
                <Tab eventKey="DemandReportByProduct" title="Demand Report By Product" unmountOnExit={true}
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 10) ? false : true}
                >
                  <DemandPDFByDate />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        addDemand: (data, handleVisible, handleDangerVisible) => dispatch(addDemand(data, handleVisible, handleDangerVisible)),
        getAllDemand: () => dispatch(getAllDemand()),
        getAllInventory: (data) => dispatch(getAllInventory(data)),
        searchDemandReport: (data, name, startDate, endDate) => dispatch(searchDemandReport(data, name, startDate, endDate)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        getWarehouseData: () => dispatch(getWarehouseData())
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
    allInventory: state.AddDataReducer.allInventory,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    isAddingDemanddata: state.AddDataReducer.isAddingDemanddata,
    notAddDemandData: state.AddDataReducer.notAddDemandData,
    approveDemandList: state.AddDataReducer.approveDemandList,
    isFetchningApproveDemandList: state.AddDataReducer.isFetchningApproveDemandList,
    isFetchingSearchDemandList: state.AddDataReducer.isFetchingSearchDemandList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandDetails);
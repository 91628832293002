import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddEmployee from "./AddEmployee";
import ViewEmployees from "./ViewEmployees";
const TotalEmployeeData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 54) ?
                    "AddEmployee" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 55) ?
                        "EmployeeList" : ""} id="AddEmployee" className="mb-3">
                <Tab eventKey="AddEmployee" title="Add Employee"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 54) ? false : true}>  
                    <AddEmployee />
                </Tab>
                <Tab eventKey="EmployeeList" title="Employee List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 55) ? false : true}> 
                  
                  <ViewEmployees />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalEmployeeData);
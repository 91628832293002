import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  getCategoryData,
  addCategoryName,
  deleteCategory,
  getWarehouseData,
  updateCategory,
  resetReducer,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";

const AddCategory = (props) => {
  const [category, setCategoryName] = useState();
  const [wareHouseId, setwareHouseId] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Category Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadCategory() {
    props.addCategoryName(
      {
        categoryName: category,
        warehouseWareHouseId: !isEmpty(props.warehouseList)?props.warehouseList[0].wareHouseId:"",
        userId: window.localStorage.getItem("userId"),
      },
      handleVisible,
      handleDangerVisible
    );
    setCategoryName("");
  }
  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });

  useEffect(() => {
    props.getWarehouseData();
    props.resetState();
  }, []);
  return (
    <>
      {props.isFetchingwareHouseList || props.isAddingCategory ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingCategory ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Label>
                  <b> Select Warehouse *</b>
                </Form.Label>
                {/* <Select
                  placeholder="Select WareHouse..."
                  value={{label:props.warehouseList[0].wareHouseName}}
                  disabled
                  onChange={setwareHouseId}
                  options={getWareHouseList}
                /> */}
                <Form.Control type="text" placeholder="Select WareHouse...." value={!isEmpty(props.warehouseList)?props.warehouseList[0].wareHouseName:""} disabled  />
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Category Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"

                    placeholder="Category Name...."
                    onMouseLeave={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {isEmpty(category) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadCategory}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCategoryName: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCategoryName(data, handleVisible, handleDangerVisible)),
    getWarehouseData: () => dispatch(getWarehouseData()),
    resetState: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  dataAddedCategory: state.AddDataReducer.dataAddedCategory,
  dataNotAddedCategory: state.AddDataReducer.dataNotAddedCategory,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isAddingCategory: state.AddDataReducer.isAddingCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import moment from "moment";
import jsPDF from "jspdf";
import Swal from 'sweetalert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { postSalaryData } from "../SalaryManagment/generateSalary.action";

const GenerateSalary = (props) => {
const getOverTimeList = [
    { value: 1, label: "Permanent" },
    { value: 0, label: "Temporary" },
  ];

  const [ste, setSte] = useState("");
  const handleChangeType = (selectedOption) => {
    setSte(selectedOption.label);
  };
  const [date, setDate] = useState();
console.log(date)
  const postSalary = () => {
    props.postSalaryData({
      date: moment(date).format('YYYY-MM'),
      type: ste
    });
  };


  return (
    <div>
      <Container fluid>
        <div className="main">
          <Row>
            {/* <Col lg="6" md="12" sm="12">
              <Form.Group>
                <Form.Label>
                  <b> Select Month </b>

                </Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => (console.dir(e), setDate(e.target.value))}
                >
                </Form.Control>
              </Form.Group>
            </Col> */}
            <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group>
                <Form.Label>
                  <b> Select Month </b>

                </Form.Label>
               <br/>
                <LocalizationProvider dateAdapter={AdapterMoment}>

                  <DatePicker
                    // disableFuture
                    // label="Select Month"
                    openTo="month"
                    inputFormat="YYYY-MM"

                    views={['year', 'month']}
                    value={date}
                    onChange={(newVal) => (console.dir(newVal),setDate(moment(newVal).format("YYYY-MM")))}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* </Form.Control> */}
              </Form.Group>
            </Col>

            <Col xl="6" lg="6" md="6" xs="6">
              <Form.Group className="mb-3" controlId="garana" >
                <Form.Label><b>Type:</b></Form.Label>
                <Select
                  // isDisabled={isEmpty(getDepartmentNameList)}
                  placeholder="Select Type..."
                  onChange={handleChangeType}
                  options={getOverTimeList}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="sendDiv">
          {ste==""?
        <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={() => postSalary()}>Generate Salary Report</Button>:
          <Button className="sendButton" onClick={() => postSalary()}>Generate Salary Report</Button>}
        </div>
      </Container>
    </div>
  );
        }






const mapDispatchToProps = (dispatch) => {
    return {

        postSalaryData: (data) => dispatch(postSalaryData(data)),

    };
};
const mapStateToProps = (state, ownProps) => ({

    isFetchingSalaryData: state.GenerateSalaryReducer.isFetchingSalaryData,
    salaryData: state.GenerateSalaryReducer.salaryData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerateSalary);